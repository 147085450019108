/* eslint-disable */
import { combineReducers } from 'redux';
import UserReducer from './user';
import AppointmentsReducer from './dashboard';
import CalendarReducer from './calendar';
import EkgReducer from './ekg';
import SideBarReducer from './sidebar';
import ChatReducer from './chat';
import appReducer from './app';
import { reducer as formReducer } from 'redux-form';
import settingsReducer from '../../CoachScreens/Settings/module';
import teamMemberFileReducer from '../../Components/TeamMemberFile/module';
import {memberListReducer,memberProfileReducer} from './member';
import TeamSettingsReducer from '../../Components/TeamSettings/TeamSettingsReducers';
import teamMemberInvitesReducer from '../../Components/TeamMemberInvite/module';
import resetPasswordReducer from '../../CoachScreens/ResetPassword/module';
import requestNewPasswordReducer from '../../CoachScreens/RequestNewPassword/module';
import sessionReducer from './session';

const rootReducer = combineReducers({
  events: CalendarReducer,
  user: UserReducer,
  appointments: AppointmentsReducer,
  ekg: EkgReducer,
  sidebar: SideBarReducer,
  chat: ChatReducer,
  app: appReducer,
  settings: settingsReducer,
  teamMemberFile: teamMemberFileReducer,
  memberList: memberListReducer,
  memberProfile:memberProfileReducer,
  form: formReducer,
  teamSettings: TeamSettingsReducer,
  teamMemberInvite: teamMemberInvitesReducer,
  resetPassword: resetPasswordReducer,
  requestNewPassword: requestNewPasswordReducer,
  session: sessionReducer,
});
export default (state, action) => rootReducer(state, action);
