import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography, Button, FormControl, Select, MenuItem } from '@material-ui/core';
import ChipComponent from './ChipComponent';
import InputFieldComponent from './InputFieldComponent';
import { pfData as mockData } from './data/mockData';
import { useDispatch, useSelector } from 'react-redux';
import {
  editSessionHeartHabitDetails,
  editSessionProgramFocus,
  sessionProgramFocus,
} from '../../../Store/actions/session';
import { fonts, colors, fontSizes, spacing } from '../../../style/styleSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-root': {
      fontFamily: `${fonts.SESSION} !important`,
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        '& .MuiTypography-root': {
          fontWeight: 700,
          color: `${colors.SESSION_BLACK}`,
        },
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root': {
        '& .MuiTypography-root': {
          fontWeight: 400,
          marginBottom: 'auto',
        },
      },
    },
    '& .MuiSelect-outlined': {
      border: '1px solid rgba(20, 42, 57, 0.16)',
      padding: `${spacing.SMALL}`,
    },
    '& .MuiChip-outlinedPrimary': {
      color: '#000 !important',
      border: `1px solid ${colors.SPRUCE} !important`,
    },
    '& .MuiChip-outlinedSecondary': {
      color: '#000 !important',
      border: `1px solid ${colors.SESSION_CHIP_OUTLINE_RED} !important`
    },
  },
  tableHeader: {
    fontSize: `${fontSizes.MEDIUM} !important`
  },
  tableCell_Member: {
    textDecoration: 'underline',
    color: '#000',
    fontSize: `${fontSizes.MEDIUM}`,
    fontWeight: 400,
    '&:hover': {
      color: '#000',
    },
  },
  noData: {
    width: '100%',
    textAlign: 'center',
    fontSize: `${fontSizes.MEDIUM}`,
    fontColor: 'grey',
    fontFamily: `${fonts.SESSION}`,
    marginTop: `${spacing.SMALL}`,
    marginBottom: `${spacing.SESSION_MARGIN_LARGE}`,
  },
  tableRowStyle: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F0F8F5 !important',
    },
    '&:hover': {
      backgroundColor: '#F5F5F5 !important',
    },
    fontSize: `${fontSizes.BASE}`
  },
  editRow: {
    margin: '24px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      border: `1px solid ${colors.SPRUCE}`,
      borderRadius: '4px',
      fontFamily: `${fonts.SESSION}`,
      color: '#FFF',
      backgroundColor: `${colors.SPRUCE}`,
      fontWeight: 600,
    },
  },
  pf: {
    fontWeight: '600 !important',
  },
  formControl: {
    minWidth: 120,
  },
}));

const ProgramFocusComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const FIELDS = {
    STAGEOFCHANGE: 'stageOfChange',
    ACHIEVEMENT: 'achievement',
    PROGRAMFOCUS_CURRENT: 'current',
    PROGRAMFOCUS_TARGET: 'target',
  };
  //   handlers
  const tableEditingHandler = (id, value, typeOfHandler) => {
    let dataToEditIndex = data.findIndex((d) => id === d.id);
    console.log(dataToEditIndex);
    let tempData = [...data];
    tempData[dataToEditIndex][typeOfHandler] = value;
    setData(tempData);
  };

  const { patientDetails } = props;
  const { patient } = patientDetails;
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  // useEffect(() => {
  //   if (patient?.patientId !== undefined) {
  //     dispatch(sessionProgramFocus(teamId, clinicianId, patient?.patientId, props.appointmentId));
  //   }
  // }, [patient]);

  const programFocusData = useSelector((state) => state.session.programFocus);
  console.log('PROGRAM FOCUS DATA', programFocusData);

  useEffect(() => {
    setData(programFocusData);
    programFocusData.map((p) => console.log(p));
    console.log(typeof programFocusData);
  }, [programFocusData]);

  const editHandler = () => {
    if (editing) {
      //save to api
      console.log(data);
      data.map((d) => {
        //make data
        const reqBody = {
          current: d[FIELDS.PROGRAMFOCUS_CURRENT],
          target: d[FIELDS.PROGRAMFOCUS_TARGET],
          stageOfChange: d[FIELDS.STAGEOFCHANGE],
          achievement: d[FIELDS.ACHIEVEMENT],
        };

        dispatch(
          editSessionProgramFocus(
            teamId,
            clinicianId,
            patient?.patientId,
            props.appointmentId,
            d.id,
            reqBody,
          ),
        );
      });
    }
    setEditing((p) => !p);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.root} aria-label="simple table">
        <TableHead className={classes.tableHeader}>
          <TableRow hover onClick={(event) => console.log(event)}>
            <TableCell>
              <Typography style={{fontSize: 'inherit'}} variant="h6">Program Focus</Typography>
            </TableCell>
            <TableCell>
              <Typography style={{fontSize: 'inherit'}} variant="h6">State of Change</Typography>
            </TableCell>
            <TableCell>
              <Typography style={{fontSize: 'inherit'}} variant="h6">Achievement</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id} className={classes.tableRowStyle}>
              <TableCell component="th" scope="row">
                <Typography variant="body1" className={classes.pf}>
                  {row.focus || '--'}
                </Typography>
                <Typography variant="body2" style={{ marginBottom: '0', fontSize: `${fontSizes.BASE}` }}>
                  Current:
                  {!editing && row[FIELDS.PROGRAMFOCUS_CURRENT] || ' --'}
                </Typography>
                {editing && (
                  <InputFieldComponent
                    id={`input-1-${row.id}`}
                    value={row[FIELDS.PROGRAMFOCUS_CURRENT]}
                    onChange={(e) =>
                      tableEditingHandler(row.id, e.target.value, FIELDS.PROGRAMFOCUS_CURRENT)
                    }
                  />
                )}
                <Typography style={{fontSize: `${fontSizes.BASE}`}} variant="body2">Target: {!editing && row.target || ' --'}</Typography>
                {editing && (
                  <InputFieldComponent
                    id={`input-2-${row.id}`}
                    value={row[FIELDS.PROGRAMFOCUS_TARGET]}
                    onChange={(e) =>
                      tableEditingHandler(row.id, e.target.value, FIELDS.PROGRAMFOCUS_TARGET)
                    }
                  />
                )}
              </TableCell>
              <TableCell>
                {!editing ? (
                  row.stageOfChange ? <ChipComponent 
                    label={row.stageOfChange} 
                    color="primary"  
                    variant="outlined" 
                    style={{fontSize: `${fontSizes.SMALL}`}}
                  /> : '--'
                ) : (
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={row[FIELDS.STAGEOFCHANGE] || ''}
                      onChange={(e) =>
                        tableEditingHandler(row.id, e.target.value, FIELDS.STAGEOFCHANGE)
                      }
                      placeholder="Select"
                    >
                      <MenuItem value={'Precontemplation'}>Precontemplation</MenuItem>
                      <MenuItem value={'Contemplation'}>Contemplation</MenuItem>
                      <MenuItem value={'Preparation'}>Preparation</MenuItem>
                      <MenuItem value={'Action'}>Action</MenuItem>
                      <MenuItem value={'Maintenence'}>Maintenence</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell>
                {!editing ? (
                  row.achievement ? <ChipComponent 
                    label={row.achievement} 
                    color="secondary" 
                    variant="outlined"
                    style={{fontSize: `${fontSizes.SMALL}`}}
                  /> : '--'
                ) : (
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label2"
                      id="demo-simple-select2"
                      value={row[FIELDS.ACHIEVEMENT]}
                      onChange={(e) =>
                        tableEditingHandler(row.id, e.target.value, FIELDS.ACHIEVEMENT)
                      }
                      placeholder="Select"
                    >
                      <MenuItem value={'Not Met'}>Not Met</MenuItem>
                      <MenuItem value={'In Progress'}>In Progress</MenuItem>
                      <MenuItem value={'Met'}>Met</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.editRow}>
        <Button variant="contained" onClick={editHandler}>
          {editing ? 'SAVE' : 'EDIT'}
        </Button>
      </div>
    </TableContainer>
  );
};

export default ProgramFocusComponent;
