import React from 'react';
import classes from './Chat.module.css';
import moment from 'moment';

/**
 * Display message on chat screen
 * @param {Object} message - contain message data like text, time.
 * @param {string} position -  msg position Left or Right
 * @returns {ReactElement}
 */
const MessageBox = ({ message, position = 'Right' }) => (
  <div className={classes[`message${position}`]}>
    {false ? (
      <div className={classes.imgBox}>
        <img src="https://miro.medium.com/max/640/1*Mom9A9c2MVqI-V-KPz14ag.png" alt="message" />
      </div>
    ) : (
      <p className={classes.messageContent}>{message.text}</p>
    )}
    <div className={classes[`messageTimestamp${position}`]}>
      {moment(new Date(message.time)).format('LT')}
    </div>
  </div>
);

export default MessageBox;
