import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { commonStyles } from '../../styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import classnames from 'classnames';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& .MuiDivider-root': {
        marginTop: '16px',
        color: '#142A39',
      },
    },
    '& .MuiTypography-root': {
      fontFamily: 'Work Sans',
    },
  },
  divWrapperOverride: {
    paddingTop: '32px',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiBox-root': {
      width: '25%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  scheduleRow: {
    marginTop: '24px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      border: '1px solid #142A39',
      borderRadius: '4px',
      fontFamily: 'Work Sans',
      color: '#142A39',
      backgroundColor: 'white',
      fontWeight: 600,
    },
  },
}));

const HealthDetails = () => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  return (
    <div className={classes.root}>
      {/* All the components are the same as below, will be making this modular and re use it with data */}
      <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
        <Box className={classes.headerWrapper}>
          <Typography variant="h6">Lifestyle Details</Typography>
          <Box>
            <Typography variant="subtitle1">Last updated: 9/11/2021</Typography>
            <AddCircleIcon />
          </Box>
        </Box>
        <Box style={{ margin: '16px' }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1">Exercise</Typography>
              <Typography variant="body2">Moderate 60 mins</Typography>
              <Typography variant="body2">Moderate 60 mins</Typography>
              <Typography variant="body2">Moderate 60 mins</Typography>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">Sleep </Typography>
              <Typography variant="body2">Moderate 60 mins</Typography>
              <Typography variant="body2">Moderate 60 mins</Typography>
              <Typography variant="body2">Moderate 60 mins</Typography>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography variant="body1">Overall wellbeing</Typography>
                <Typography variant="body2">Moderate 60 mins</Typography>
              </Box>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <div className={classes.scheduleRow}>
            <Button variant="contained">Previous data</Button>
          </div>
        </Box>
        <div className={commonClasses.overlay}>Coming Soon!</div>
      </Box>
      
    </div>
  );
};

export default HealthDetails;
