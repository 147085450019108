import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CHAT_USERS_START:
      return {
        loading: true,
        data: [],
      };
    case actionTypes.GET_CHAT_USERS_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
      };
    case actionTypes.GET_CHAT_USERS_FAIL:
      return {
        data: [],
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.SET_CHAT_LOADER:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case actionTypes.UPLOAD_FILE_START:
      return {
        ...state,
        fileLoading: true,
      };
    case actionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        fileData: action.payload,
      };
    case actionTypes.UPLOAD_FILE_FAIL:
      return {
        ...state,
        fileData: null,
      };
    default:
      return state;
  }
};

export default reducer;
