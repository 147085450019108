import React from 'react';
import { Box, IconButton, Input } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
// import AttachmentIcon from '@material-ui/icons/Attachment';
import { Flex } from '../Shared/Flex';
import FlexItem from '../Shared/FlexItem';
import Loader from '../Shared/Loader';
import classes from './Chat.module.css';
import MessageBox from './MessageBox';
import withChat from './withChat';
import { colors } from '../../style/styleSettings';
import moment from 'moment';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// const DialogTitle = withStyles(styles)((props) => {
//   const { patientName, status, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{patientName}&nbsp;</Typography>
//       <p>{status}&nbsp;</p>
//       {onClose ? (
//         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: 'calc(100vh - 300px) !important',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: 0,
    // border: '1px solid',
    // borderRadius: '2em',
  },
}))(MuiDialogActions);

const AsyncChat = (props, ref) => {
  const {
    // loading,
    // recStatus,
    messages,
    typing,
    message,
    setMessage,
    sendMessageHandler,
    userChatCreds,
    fetchingHistory,
    // getS3BucketData,
    // profileId,
  } = props;

  // const handleClose = () => props.onClose(false);

  const enterPressed = (e) => {
    let code = e.keyCode || e.which;
    if (code === 13) {
      sendMessageHandler(message);
    }
  };

  return (
    // <Dialog
    //   fullWidth={true}
    //   maxWidth={'md'}
    //   onClose={handleClose}
    //   aria-labelledby="customized-dialog-title"
    //   open={true}
    // >
    //   <DialogTitle
    //     id="customized-dialog-title"
    //     onClose={handleClose}
    //     patientName={props.patientName || 'Anonymous'}
    //     status={recStatus}
    //   />
    <Box>
      <DialogContent ref={ref} className={classes.chatBody}>
        {fetchingHistory && <Loader />}
        {Object.keys(messages).map((day, index) => (
          <div key={index}>
            <div className={classes.messageHeader}>{moment(new Date(day)).format('ll')}</div>
            {messages[day].map((m, index) => (
              <Flex
                key={index}
                justify={
                  m.source.split('@')[0] === userChatCreds.kccChatUserID ? 'flex-end' : 'flex-start'
                }
              >
                <MessageBox
                  message={m}
                  position={
                    m.source.split('@')[0] === userChatCreds.kccChatUserID ? 'Right' : 'Left'
                  }
                />
              </Flex>
            ))}
          </div>
        ))}
        {typing && (
          <Flex justify={'flex-start'}>
            <div className={classes[`messageLeft`]}>
              <i style={{ fontSize: '10px', color: 'grey' }}>Typing...</i>
            </div>
          </Flex>
        )}
      </DialogContent>
      {/* <Divider /> */}
      <DialogActions>
        <FlexItem colspan={10} className={classes.inputFieldContainer}>
          <Input
            placeholder={'Write Something'}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ padding: '5px 16px', width: '100%' }}
            disableUnderline
            onKeyPress={enterPressed}
          />
          {/* <input
            accept="image/*"
            id="icon-button-photo"
            onChange={(e) => {
              if (e.target.files?.length) {
                getS3BucketData && getS3BucketData(profileId, e.target.files[0]);
              }
            }}
            type="file"
            style={{ display: 'none' }}
          />
          <label htmlFor="icon-button-photo">
            <IconButton className={classes.sendButton} component="span">
              <AttachmentIcon htmlColor={colors.ALIVECORE_TEAL} />
            </IconButton>
          </label> */}
        </FlexItem>
        <FlexItem>
          <IconButton
            className={classes.sendButton}
            onClick={() => sendMessageHandler(message)}
            aria-label="send message"
          >
            <SendIcon htmlColor={colors.ALIVECORE_TEAL} />
          </IconButton>
        </FlexItem>
      </DialogActions>
    </Box>
    // </Dialog>
  );
};

export default withChat(React.forwardRef(AsyncChat));
