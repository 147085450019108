import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import EcgLoader from '../Components/Shared/EcgLoader';
import { Chat } from '../providers';
import { getUser, getUserStart } from '../Store/actions/user';
import { checkAuthentication, clearResetIdleTime } from './AuthUtilities';

const withAuth = (parentProps) =>
  function (Comp) {
    return function HasAuth(props) {
      const { profile, team, isAuthenticated, login, loading } = useSelector((state) => state.user);
      const dispatch = useDispatch();

      useEffect(() => {
        dispatch(checkAuthentication());
      }, [dispatch]);

      useEffect(() => {
        if (!login && !profile && !loading) {
          dispatch(getUserStart());
          dispatch(getUser());
        }
      }, [login]);

      if (isAuthenticated) {
        if (profile) {
          parentProps.userstate.data.profile = profile;
          parentProps.userstate.data.teams = [team];
          return (
            <Chat.Provider>
              <Comp {...props} {...parentProps} />
            </Chat.Provider>
          );
        }
        return <EcgLoader />;
      }
      clearResetIdleTime();
      return <Redirect to={{ pathname: '/login' }} />;
    };
  };

export default withAuth;
