import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Tabs, Tab, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router';
import { ROUTES } from '../../constants/app';
import { useTranslation } from 'react-i18next';
import HealthDetails from './components/HealthDetails';
import Program from './components/Program';
import SessionLogs from './components/SessionLog/SessionLogs';
import queryString from 'query-string';
import Header from './Header';
import Metrics from './components/Metrics';
import { useDispatch } from 'react-redux';
import { getMemberProfileDetails } from '../../Store/actions/memberProfile';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    margin: '26px',
    '& .MuiTypography-root': {
      fontFamily: 'Work Sans',
    },
  },
  paperroot: {
    flexGrow: 1,
  },
  customTabRoot: {
    color: '#142A39',
    '& .Mui-selected': {
      color: '#2D9F86',
    },
  },
  customTabIndicator: {
    backgroundColor: '#2d9f86',
    height: '4px',
  },
  tabRoot: {
    fontSize: '14px !important',
    minWidth: 166,
    fontWeight: '700',
    fontFamily: ['Work Sans'].join(','),
  },
  divWrapper: {
    background: '#FFFFFF',
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '24px',
    marginBottom: '24px',
    padding: '16px',
    paddingBottom: '32px',
    '& .MuiGrid-item': {
      marginTop: '32px',
      marginBottom: '16px',
    },
    '& .MuiTypography-body1': {
      fontWeight: 600,
    },
  },
  divHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '20px',
      '& .MuiTypography-body2': {
        fontWeight: 600,
        marginLeft: '20px',
      },
    },
  },
  chipHolder: {
    marginTop: '16px',
    display: 'flex',
  },
  holder: {
    marginTop: '8px',
  },
  holder_single: {
    margin: '0 !important',
    display: 'flex',
    '& .MuiTypography-root:nth-of-type(1)': {
      fontWeight: 600,
    },
  },
  lastTabWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export default function MemberProfile(props) {
  const { t } = useTranslation();
  const { memberId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  let location = useLocation();
  let history = useHistory();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    //history.push(`${ROUTES.MEMBER_PROFILE}/${memberId}?tab=${newValue}`);
    setValue(newValue);
  };
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const { tab } = queryParams;
    if (tab) {
      setValue(parseInt(tab));
    }
  }, []);
  useEffect(() => {
    dispatch(getMemberProfileDetails(memberId));
  }, []);
  return (
    <div className={classes.root}>
      <Header {...props} />
      <Paper className={classes.paperroot}>
        <Tabs
          classes={{
            root: classes.customTabRoot,
            indicator: classes.customTabIndicator,
          }}
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          centered
          variant="fullWidth"
        >
          <Tab label={t('PROGRAM')} classes={{ root: classes.tabRoot }} {...a11yProps(0)} />
          <Tab label={t('HEALTH DETAILS')} classes={{ root: classes.tabRoot }} {...a11yProps(1)} />
          <Tab label={t('METRICS')} classes={{ root: classes.tabRoot }} {...a11yProps(2)} />
          <Tab label={t('SESSION LOGS')} classes={{ root: classes.tabRoot }} {...a11yProps(3)} />
          <Tab label={t('MESSAGES')} classes={{ root: classes.tabRoot }} {...a11yProps(4)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Program />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <HealthDetails />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Metrics />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SessionLogs {...props} />
      </TabPanel>
      <TabPanel value={value} index={4}>
      </TabPanel>
    </div>
  );
}
