import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SortIcon from '@material-ui/icons/Sort';
import { Grid } from '@material-ui/core';
import { fontSizes, colors, fonts } from '../../../../style/styleSettings';
import { EnhancedTableHead } from '../../../Common/EnhancedTableHead';
import ViewNotes from './ViewNotes';
import ReportModal from '../../../../Components/AllAppointments/ReportModal';
import { useSelector } from 'react-redux';
import { important } from 'polished';
import { commonStyles } from '../../styles';

const useRowStyles = makeStyles({
  root: {
    // '& > *': {
    //   borderBottom: 'unset',
    // },
    fontFamily: fonts.TABLE_HEADER,
    marginTop: '20px',
    '& .MuiTableCell-head': {
      fontWeight: 'bold !important',
      fontSize: fontSizes.BASE,
    },
    '& .MuiTypography-body1': {
      fontSize: fontSizes.BASE,
    },
  },
  tableBody: {
    fontFamily: `${fonts.SESSION} !important`,
    '&:nth-of-type(4n+1)': {
      background: 'rgb(250, 250, 250) !important',
    },
    '& .MuiTableCell-body': {
      fontSize: fontSizes.BASE,
    },
    '& .MuiTypography-h6': {
      fontSize: `${fontSizes.MEDIUM} !important`,
    },
    '& .MuiTypography-body2': {
      fontSize: `${fontSizes.BASE} !important`,
    },
    '& .MuiChip-outlinedPrimary': {
      color: 'black',
      border: `1px solid ${colors.SPRUCE}`,
    },
    '& .MuiChip-outlinedSecondary': {
      color: 'black',
      border: `1px solid ${colors.SESSION_CHIP_OUTLINE_RED}`,
    },
  },
  topMargins: {
    marginTop: '20px',
    position:'relative'
  },
  hearderMargin: {
    padding: '25px 0 0 20px',
    font: fonts.SESSION,
    fontSize: fontSizes.BASE,
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: fontSizes.MEDIUM,
  },
  viewNotesTableColumn: {
    color: colors.SPRUCE,
    fontWeight: 'bold',
    fontSize: `${fontSizes.CAPTION} !important`,
    cursor: 'pointer',
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function Row(props) {
  const { row, sessionLogsViewNotes } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const appointmentId = useSelector((state) => state.appointments.patientDetails.appointmentId);
  const [openReport, setopenReport] = useState(false);
  const viewNotes = () => {
    if (props.reportCompleted) {
      setopenReport(true);
    } else {
      setOpen(!open);
    }
  };
  const reportmodalCloseHandler = () => {
    setopenReport(false);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.tableBody}>
        <TableCell component="th" scope="row">
          {row.sessionLogDate}
        </TableCell>
        <TableCell>{row.time}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.duration}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{row.method}</TableCell>
        <TableCell className={classes.viewNotesTableColumn} onClick={viewNotes}>
          {open ? 'HIDE NOTES' : 'VIEW NOTES'}
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <KeyboardArrowUpIcon style={{ color: colors.SPRUCE }} />
            ) : (
              <KeyboardArrowRightIcon style={{ color: colors.SPRUCE }} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableBody}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ padding: '10px', width: '100%' }}>
              <Typography variant="h6" gutterBottom component="div">
                <b>Previous Session</b>
              </Typography>
              <Typography variant="body2">
                This member is struggling to commit to the program. She is unable to find happiness
                in the now as she focuses soley on how happy she will be if she meets her goal.
                However, she continues to self-sabatoge.{' '}
              </Typography>
            </Box>
            <ViewNotes sessionLogsViewNotes={sessionLogsViewNotes} />
          </Collapse>
        </TableCell>
      </TableRow>
      {openReport && (
        <ReportModal
          key="report-modal"
          appointmentId="4fe1e6f0-7a5b-400f-a948-45af30c32a39"
          patient="bawh5fz5289z5vx8r9wksd995"
          memberId="FDw9ErE6o6U0s2FeNdEwcg31sq2o9idv"
          show={openReport}
          handleClose={reportmodalCloseHandler}
        ></ReportModal>
      )}
    </React.Fragment>
  );
}

function SessionLogs(props) {
  const commonClasses = commonStyles();
  const { sessionLogsTableData, sessionLogsViewNotes, tableHead } = props;
  const classes = useRowStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('sessionLogDate');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  return (
    <Paper className={classes.root}>
      <Grid container className={classes.hearderMargin}>
        <Grid item lg={3} md={3}>
          <Typography variant="body1">Coaching Session</Typography>
          <Typography variant="body1">
            <b>10 of 20 available</b>
          </Typography>
        </Grid>
        <Grid item lg={3} md={3}>
          <Typography variant="body1">Cardiology Session</Typography>
          <Typography variant="body1">
            <b>3 of 4 available</b>
          </Typography>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className={classes.topMargins}>
        <Table aria-label="collapsible table">
          <EnhancedTableHead
            from="sessionLogs"
            columnData={tableHead}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(sessionLogsTableData, getComparator(order, orderBy)).map((row, index) => (
              <Row key={index} row={row} sessionLogsViewNotes={sessionLogsViewNotes} />
            ))}
            <div className={commonClasses.overlay}>Coming Soon!</div>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default SessionLogs;
