import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { getTimezoneName, streamToPromise } from '../../Utilities/Utilities';
import { useSelector } from 'react-redux';
import apiservice from '../../api/services/apiservice.service';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Button, Typography } from '@material-ui/core';
import { ROUTES } from '../../constants/app';
const useStyles = makeStyles({
  root: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      fontFamily: 'Work Sans !important',
      fontSize: '16px',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        fontWeight: 700,
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root': {
        fontWeight: 400,
      },
    },
  },
  tableCell_Member: {
    textDecoration: 'underline',
    color: '#000',
    fontSize: '16px',
    fontWeight: 400,
    '&:hover': {
      color: '#000',
    },
  },
  noData: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 600,
    fontColor: 'grey',
    fontFamily: 'Work Sans',
    marginTop: '40px',
    marginBottom: '20px',
    marginLeft: '24px',
  },
  tableRowStyle: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F0F8F5 !important',
    },
    '&:hover': {
      backgroundColor: '#F5F5F5 !important',
    },
  },
  scheduleRow: {
    margin: '24px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      border: '1px solid #067F6F',
      borderRadius: '4px',
      fontFamily: 'Work Sans',
      color: '#067F6F',
      backgroundColor: 'white',
      fontWeight: 600,
    },
  },
  tableHeader: {},
});

const DashboardTable = () => {
  const history=useHistory()
  const [sessionData, setSessionData] = useState([]);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  let offsetInMinutes = new Date().getTimezoneOffset();
  let skip = '0';
  let take = '10';
  const localfilterstatusfinal = '';
  const sortordertypenew = 'asc';
  const searchInput = '';
  const sortpropsnamenew = 'Date';
  const timezoneValue = getTimezoneName();
  const now = moment();
  let develop = true;
  const startDateformatted = !develop ? now.startOf('day').toISOString() : '';
  const endDateformatted = !develop ? now.endOf('day').toISOString() : '';
  const getDataForTodaySession = async () => {
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${localfilterstatusfinal}&sortOrder=${sortordertypenew}&searchString=${searchInput}&sortParam=${sortpropsnamenew}&timeZone=${timezoneValue}&startDateTime=${startDateformatted}&endDateTime=${endDateformatted}`,
      }),
    );
    const appointments = await appointmentRequest;
    if (appointments) {
      // console.log(appointments);
      return appointments.data.result.appointments;
    }
  };
  useEffect(() => {
    getDataForTodaySession().then((data) => {
      setSessionData(data);
    });
  }, []);
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.root} aria-label="simple table">
        {sessionData.length > 0 && (
          <TableHead className={classes.tableHeader}>
            <TableRow hover onClick={(event) => console.log(event)}>
              <TableCell>Member</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {sessionData.map((row,index) => (
            <TableRow key={index} className={classes.tableRowStyle}>
              <TableCell component="th" scope="row">
                <Link className={classes.tableCell_Member} to={`${ROUTES.MEMBER_PROFILE}/${row.patient.patientId}`}>
                  {row.patient.lastName} {row.patient.firstName}
                </Link>
              </TableCell>
              <TableCell>{moment(new Date(row.scheduledStartTime)).format('lll')}</TableCell>
              <TableCell>{row.appointmentType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {sessionData.length === 0 && (
        <Typography className={classes.noData}>0 sessions scheduled for today</Typography>
      )}
      <div className={classes.scheduleRow}>
        <Button variant="contained" onClick={()=>history.push(ROUTES.COACH_SCHEDULE)}>Schedule</Button>
      </div>
    </TableContainer>
  );
};

export default DashboardTable;
