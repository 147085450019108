/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Tabs, Tab, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Appointments from '../../Components/Appointments/Appointments';
import MemberList from '../MemberList';
import { useLocation,useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants/app';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FFFFFF',
    marginTop: theme.spacing(2),
  },
  paperroot: {
    flexGrow: 1,
    // maxWidth: 500,
  },
  customTabRoot: {
    color: '#142A39',
    '& .Mui-selected': {
      color: '#2D9F86',
    },
  },
  customTabIndicator: {
    backgroundColor: '#2d9f86',
    height: '4px',
  },
  tabRoot: {
    fontSize: '14px !important',
    // textTransform: 'initial',
    minWidth: 166,
    fontWeight: '700',
    fontFamily: ['Work Sans'].join(','),
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function CoachPortal(props) {
  console.log(props.location.pathname)
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory()
  const classes = useStyles();
  const [value, setValue] = useState(0);
  
  const handleChange = (event, newValue) => {
    let endpoint=''
    endpoint = newValue==0?ROUTES.COACH_SCHEDULE:ROUTES.COACH_MEMBER_LIST
    history.push(endpoint)
  };

  useEffect(() => {
    const tabToNav = location.pathname.split('/')[2];
    tabToNav === 'schedule' ? setValue(0) : setValue(1);
  }, [location]);

  return (
    <Container maxWidth="xl">
      <div className={classes.root}>
        <Paper className={classes.paperroot}>
          <Tabs
            classes={{
              root: classes.customTabRoot,
              indicator: classes.customTabIndicator,
            }}
            value={value}
            onChange={handleChange}
            scrollButtons="auto"
            centered
            variant="fullWidth"
          >
            <Tab label={t('SCHEDULE')} classes={{ root: classes.tabRoot }} {...a11yProps(0)} />
            <Tab label={t('MEMBER LIST')} classes={{ root: classes.tabRoot }} {...a11yProps(1)} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <Appointments {...props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MemberList {...props} />
        </TabPanel>
      </div>
    </Container>
  );
}

export default CoachPortal;
