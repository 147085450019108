
export const tableHead = [
    {
      id: 'sessionLogDate',
      label: 'Date',
    },
    {
      id: 'sessionLogTime',
      label: 'Time',
    },
    {
      id: 'sessionLogStatus',
      label: 'Status',
    },
    {
      id: 'sessionLogDuration',
      label: 'Duration',
    },
    {
      id: 'sessionLogType',
      label: 'Type of Session',
    },
    {
      id: 'sessionLogMethods',
      label: 'Methods',
    },
    {
      id: 'sessionLogNotes',
      label: 'Notes',
    },
  ];

  export const sessionLogsViewNotes = [
    {
      program: 'Get Active',
      current: 'I exercise 20 per week',
      target: 'I will do 30 mins of exercise per week',
      habit: 'I will play more tennis',
      frequency: 'Weekly (M, W, F, Sa)',
      duration: '10/31/2020 - 12/31/2020'
    },{
      program: 'Sleep improvememnt',
      current: 'I exercise 10 per week',
      target: 'I will do 45 mins of exercise per week',
      habit: 'I will play more tennis',
      frequency: 'Weekly (M, W, F, Sa)',
      duration: '10/31/2020 - 12/20/2020'
    },{
      program: 'Nutrition',
      current: 'I exercise 20 per week',
      target: 'I will do 30 mins of exercise per week',
      habit: 'I will play more tennis',
      frequency: 'Weekly (M, W, F, Sa)',
      duration: '10/31/2020 - 12/31/2020'
    }
  ]

  export const sessionLogsTableData = [
    {
      sessionLogDate: '02-10-2020',
      time: '10:00 AM - 10:15 AM',
      status: 'Scheduled',
      duration: '30 mins',
      type: 'Ongoing',
      method: 'video'
    },
    {
      sessionLogDate: '02-10-2019',
      time: '10:00 AM - 10:15 AM',
      status: 'Scheduled',
      duration: '30 mins',
      type: 'Ongoing',
      method: 'video'
    },{
      sessionLogDate: '02-12-2020',
      time: '10:00 AM - 10:15 AM',
      status: 'Scheduled',
      duration: '30 mins',
      type: 'Ongoing',
      method: 'video'
    },{
      sessionLogDate: '12-11-2020',
      time: '10:00 AM - 10:15 AM',
      status: 'Scheduled',
      duration: '30 mins',
      type: 'Ongoing',
      method: 'video'
    },{
      sessionLogDate: '02-10-2021',
      time: '10:00 AM - 10:15 AM',
      status: 'Scheduled',
      duration: '30 mins',
      type: 'Ongoing',
      method: 'video'
    },{
      sessionLogDate: '09-09-2021',
      time: '10:00 AM - 10:15 AM',
      status: 'Scheduled',
      duration: '30 mins',
      type: 'Ongoing',
      method: 'video'
    },{
      sessionLogDate: '02-10-2020',
      time: '10:00 AM - 10:15 AM',
      status: 'Scheduled',
      duration: '30 mins',
      type: 'Ongoing',
      method: 'video'
    }
  ]