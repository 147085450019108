import React from 'react';
import classes from './EcgLoader.module.css';
//const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }
class EcgLoader extends React.Component {
  // componentDidMount() {
  //   const {
  //     browser,
  //   } = this.props

  //   // if (browser.name === 'ie') {
  //      const pulse = document.querySelector(`polyline.${styles.pulse}`)
  //  // console.log(pulse)
  //      let offset = 800;

  //     const offsetMe = function dashes() {
  //       if (offset < -800) offset = 800
  //      // pulse.style.strokeDashoffset = offset
  //       offset -= 5.3

  //       window.requestAnimationFrame(offsetMe)
  //     }

  //     offsetMe()
  //   // }
  // }

  render() {
    return (
      <div className={classes.ecgLoader}>
        <svg height="85" width="290">
          <polyline
            points="1.2 49 18.2 49 22.2 65.5 25.2 33.5 27.7 49 41.2 49 45.2 83.5 47.7 1 51.2 47 88.2 47 92.7 63 95.7 33 98.2 49 112.2 49 116.7 83.5 119.7 9.5 123.2 46.5 161.2 46.5 163.7 56 167.7 42.2 170.7 49 183.7 49 187.2 65.5 192.2 9 196.2 46.5 232.7 46.5 235.7 52.5 238.2 39 242.7 49 255.7 49 259.2 63.5 261.7 29 266.7 49 287.7 49"
            stroke="rgba(220,220,220,0.3)"
            fill="none"
            strokeWidth="3"
          />

          <polyline
            points="1.2 49 18.2 49 22.2 65.5 25.2 33.5 27.7 49 41.2 49 45.2 83.5 47.7 1 51.2 47 88.2 47 92.7 63 95.7 33 98.2 49 112.2 49 116.7 83.5 119.7 9.5 123.2 46.5 161.2 46.5 163.7 56 167.7 42.2 170.7 49 183.7 49 187.2 65.5 192.2 9 196.2 46.5 232.7 46.5 235.7 52.5 238.2 39 242.7 49 255.7 49 259.2 63.5 261.7 29 266.7 49 287.7 49"
            className={classes.pulse}
            stroke="rgba(45,159,134,0.6)"
            fill="none"
            strokeWidth="2"
          />
        </svg>
        <h3>Loading…</h3>
      </div>
    );
  }
}

export default EcgLoader;
