import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants/app';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
  root: {
   
    minWidth:'50px',
    height: '126px',
    boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1);
border-radius: 8px`,
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: props=> `2px solid ${props.color}`,
    cursor: 'pointer'
  },
  title: {
    fontSize: 14,
    fontFamily: 'Work Sans',
    lineHeight: '16px',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom:'24px',
    color:'#142A39',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between'
  },
  content:{
      fontWeight:500,
      fontSize:'34px',
      lineHeight:'40px',
      color:'#142A39'
  },
});

const DashboardCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory()
  const memberListNavigation = () => {
    history.push(ROUTES.COACH_MEMBER_LIST);
  }
  return (
    <Card className={classes.root} onClick={memberListNavigation}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
              {props.icon && props.iconFetch()}
            {props.title}
          </Typography>
          <Typography className={classes.content} variant="h5" component="h2" align="center">
            {props.content}
          </Typography>
        </CardContent>
      {/* <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions> */}
    </Card>
  );
};

export default DashboardCard;
