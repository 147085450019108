import { connect } from 'react-redux';
import TeamMemberInvite from './TeamMemberInvite';
import { setInitialTeamMemberInviteData, submitForm, handleChange, handleKeyDown } from './module';

const mapActionCreators = {
  setInitialTeamMemberInviteData,
  submitForm,
  handleChange,
  handleKeyDown,
};

const mapStateToProps = (state) => ({
  teamMemberInvite: state.teamMemberInvite,
});

export default connect(mapStateToProps, mapActionCreators)(TeamMemberInvite);
