import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SortIcon from '@material-ui/icons/Sort';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
  root:{
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      opacity: '0 !important'
    },
    '& .MuiTableCell-head': {
      fontSize: '16px', fontFamily: 'Work Sans', fontWeight: 'bold'
    }
  }
})

//info icon and sorting icon implementation in table header
const StatusTooltip = (props) => {  
  const { id, label } = props.headerValue;
  return (
    <>
      {(id === 'status' || id === 'bloodPressure') && <InfoOutlinedIcon onClick={() => props.handleModalOpen(id)}/>}
      {label}
      <span style={{ position: 'absolute', left: '90%', top: 'calc(50% - 20px/2)' }}>
        <SortIcon />
      </span>
    </>
  );
};

export const EnhancedTableHead = (props) => {
  const classes = useStyle()
  const { columnData, order, orderBy, from, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.root}>
      <TableRow>
        {/* when this component is rendered from memberlist screen */}
        {from === 'memberList' ? (
          columnData.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <StatusTooltip headerValue={headCell} handleModalOpen={props.handleModalOpen}/>
              </TableSortLabel>
            </TableCell>
          ))
        ) : 
        // when this compoment is rendered from SESSION LOG(member profile) screen 
        (
          columnData.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id === 'sessionLogDate'  ? 
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label} <SortIcon />
              </TableSortLabel>
              :
              headCell.label
              }
            </TableCell>
          ))
        )}        
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  columnData: PropTypes.array.isRequired,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
