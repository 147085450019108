
/* eslint-disable */
import { Link } from 'react-router-dom';
import _get from 'lodash.get';
import React, { useEffect } from 'react';
import Alert from '../Shared/Alert/Alerts';
import Successalert from '../Shared/Alert/AvailabilityAlert';
import * as actions from './../../Store/actions';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import VerticalTab from './VerticalTab';
import MaterialTabs from './tabs';
import AppointmentNotes from './AppointmentNotes';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import * as Routes from './../../Auth/Routes';
import { useHistory } from 'react-router-dom';
import EcgLoader from '../Shared/EcgLoader/EcgLoader';
import AccessDeniedContent from '../Shared/AccessDeniedContent/AccessDeniedContent';
import * as zone from '../../Utilities/Utilities';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Authorize from '../Authorize';

const useStyles = makeStyles(() => ({
  accessDeniedContent: {
    backgroundColor: '#FFFFFF',
    margin: '50px 0 0 0 ',
    padding: '40px',
  },
}));

const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
const Dashboard = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let history = useHistory();
  const [reload, setreload] = React.useState(false);
  const [appointmentNotesId, setappointmentsNotesId] = React.useState(null);
  const [value, setvalue] = React.useState(0);
  const [appointments, setappointments] = React.useState([]);
  const [statistics, setstatistics] = React.useState([]);
  const [availability, setavailability] = React.useState([]);
  const permissions = useSelector((state) => state.user.profile.permissions);

  const dispatch = useDispatch();
  const clinicianAppointments = useSelector((state) => state.appointments.clinicianAppointments);
  const clinicianAppointmentsData = useSelector(
    (state) => state.appointments.clinicianAppointmentsData,
  );
  const clinicianAvailability = useSelector((state) => state.appointments.clinicianAvailabilities);
  const error = useSelector((state) => state.appointments.error);
  const isLoading = useSelector((state) => state.appointments.loading);
  const patientDetailsLoader = useSelector((state) => state.appointments.detailsloader);
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  const [alert, setalert] = React.useState(null);
  useEffect(() => {
    let queryParams = {
      startDateTime: moment(moment().startOf('day')._d).format(pattern),
      endDateTime: moment(moment().endOf('day')._d).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: zone.getTimezoneName(),
    };
    let appointmentqueryParams = {
      startDateTime: moment(moment().startOf('day')._d).format(pattern),
      endDateTime: moment(moment().endOf('day')._d).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: zone.getTimezoneName(),
      appointmentStatus: '1,2,3,6,7,9',
    };
    dispatch(actions.getAppointments(teamId, profileId, appointmentqueryParams));
    handleChange(null, 0);
    dispatch(actions.getClinicianAvailability(profileId, teamId, queryParams));
  }, [reload]);
  useEffect(() => {
    clinicianAppointments.sort((a, b) => a.start - b.start);
    setappointments(clinicianAppointments);
    setavailability(clinicianAvailability);
    setstatistics(clinicianAppointmentsData);
  }, [clinicianAvailability, clinicianAppointmentsData, clinicianAppointments]);
  const navigate = () => {
    history.push(Routes.appointments);
  };
  const reassignsubmit = (prps) => {
    setalert(null);
    setalert(
      <Successalert
        component="clinicianCalendar"
        title={`The session has been successfully assigned to ${prps.doctorFirstName} ${prps.doctorLastName}`}
      />,
    );
  };
  const handleChange = (event, newValue) => {
    setvalue(newValue);
    let range = '';
    let flag = {};
    switch (newValue) {
      case 0:
        range = 'day';
        flag = true;
        break;
      case 1:
        range = 'week';
        flag = true;
        break;
      case 2:
        range = 'month';
        flag = true;
        break;
    }

    let queryParams = {
      startDate: moment(moment().startOf(range)._d).format(pattern),
      endDate: moment(moment().endOf(range)._d).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: zone.getTimezoneName(),
    };
    dispatch(actions.getCliniciansAppointments(teamId, profileId, queryParams));
  };
  let firstappointment = appointments[0];
  let lastappointment = appointments[Object.keys(appointments).pop()];
  const [errorMessage, seterrorMessage] = React.useState(null);
  useEffect(() => {
    seterrorMessage(null);
    if (error)
      seterrorMessage(
        <Alert
          date={new Date()}
          message="Unable to process this request due to internal server error."
        />,
      );
    else seterrorMessage(null);
  }, [error]);
  let slotInfo = <></>;
  if (availability && availability.availableSlots && availability.availableSlots.length > 0) {
    slotInfo = availability.availableSlots.map((item, index) => (
      <div className="row no-gutters py-2" key={index}>
        <div className="d-flex justify-content-between w-100">
          <div className="text-nowrap">
            <span className="iconset px-2">
              <EventAvailableIcon />{' '}
            </span>{' '}
            {_get(item, 'slotTiming') || '-'}
          </div>
          <div className="text-right">
            <strong>
              {(Number(_get(item, 'availableHours')) < 10
                ? '0' + _get(item, 'availableHours')
                : _get(item, 'availableHours')) +
                ':' +
                (Number(_get(item, 'availableMinutes')) < 10
                  ? '0' + _get(item, 'availableMinutes')
                  : _get(item, 'availableMinutes')) || '-'}{' '}
              Hours
            </strong>
          </div>
        </div>
      </div>
    ));
  }
  const reloadDashboardHandler = () => {
    setreload(!reload);
  };
  const setAppointmentID = (id) => {
    setappointmentsNotesId(id);
  };
  const warningMessage = (message) => {
    seterrorMessage(<Alert date={new Date()} message={message} />);
  };
  let dayString = moment(new Date()).format('dddd');
  // ;
  return (
    <>
      {permissions.isPhysician ? (
        <>
          {isLoading ? (
            <EcgLoader />
          ) : (
            <div className="telekardia">
              <div className={patientDetailsLoader ? 'filtering' : null}>
                <div className={patientDetailsLoader ? 'spinner-border' : null}></div>
                <div className="m-4">{errorMessage}</div>
                <div className="m-4">{alert}</div>
                <div className="right_content right_container">
                  <div className="container-fluid home_scroll">
                    <div className="row flex dotee">
                      <h6 className="col-12 title_h6">
                        {moment().format('MMMM D, YYYY')}
                        <span className="f600">{' ' + dayString}</span>
                      </h6>
                      <div className="col col-md-12 col-lg-8 col-xl-9 order-md-2 order-lg-1 mb-2 pb-2 pr-lg-0">
                        <div className="content d-flex w-100">
                          <VerticalTab
                            setAppointmentID={setAppointmentID}
                            loader={reloadDashboardHandler}
                            appointments={appointments}
                            first={firstappointment}
                            last={lastappointment}
                            dashboardmetrics={handleChange}
                            error={(e) => warningMessage(e)}
                            reassignsubmit={reassignsubmit}
                            {...props}
                          />{' '}
                        </div>
                      </div>
                      <div className="col col-md-12 col-lg-4 col-xl-3 Dashboard_right mb-2 pb-2 order-md-1 order-lg-2">
                        <div className="content">
                          <div className="row m-0">
                            <div className="col-12 col-md-6 col-lg-12 mb-3 p-0">
                              <div className="p-3 boxshadow bg-white height_335 border_rad overflow-auto">
                                <h5 className="caption">{t('Today’s Availability')}</h5>
                                {/*if there are No availability set for today*/}
                                {availability &&
                                availability.availableSlots &&
                                availability.availableSlots.length > 0 ? (
                                  <div className="row no-gutters py-2">
                                    <div className="col-6">
                                      <div className="f16">{t('Available Time')}</div>
                                      <h5 className="heading font_green mb-0">
                                        {(Number(_get(availability, 'totalAvailableHours')) < 10
                                          ? '0' + _get(availability, 'totalAvailableHours')
                                          : _get(availability, 'totalAvailableHours')) +
                                          ':' +
                                          (Number(_get(availability, 'totalAvailableMins')) < 10
                                            ? '0' + _get(availability, 'totalAvailableMins')
                                            : _get(availability, 'totalAvailableMins')) || '-'}{' '}
                                        Hours
                                      </h5>
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-end">
                                      <Authorize>
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary px-4"
                                          onClick={navigate}
                                        >
                                          {t('EDIT')}
                                        </button>
                                      </Authorize>
                                    </div>
                                    <div className="col-12 py-2 pt-4">
                                      <div className="f16 mb-2">
                                        {t('Available slots for today:')}
                                      </div>

                                      {slotInfo}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="py-2 height_set position-relative">
                                    <div className="col-12 p-0">
                                      <p className="f16">{t('No availability set for today')}</p>
                                    </div>
                                    <div className="col-12 p-0 position-absolute fixed-bottom">
                                      <Link to="/telekardia/appointments">
                                        <Authorize>
                                          <button
                                            type="button"
                                            className="btn btn-outline-secondary px-4 w-100"
                                          >
                                            {t('EDIT')}
                                          </button>
                                        </Authorize>
                                      </Link>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/*Appointments*/}
                            <div className="col-12 col-md-6 col-lg-12 mb-3 p-0 pl-md-3 pl-lg-0">
                              <div className="boxshadow bg-white height_335 border_rad overflow-auto">
                                <MaterialTabs
                                  value={value}
                                  handleChange={handleChange}
                                  stats={statistics}
                                  {...props}
                                />
                              </div>
                            </div>
                            {/*Appointment Notes*/}
                            <div className="col-12 p-0">
                              <div className="boxshadow bg-white border_rad overflow-hidden p-3 home_notes">
                                <AppointmentNotes
                                  id={appointmentNotesId}
                                  appointments={appointments}
                                  {...props}
                                ></AppointmentNotes>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Container maxWidth="xl">
          <Grid
            container
            alignItems="center"
            justify="flex-start"
            className={classes.accessDeniedContent}
          >
            <AccessDeniedContent />
          </Grid>
        </Container>
      )}
    </>
  );
};

export default Dashboard;
