import { getToken } from '../../Auth/AuthUtilities';
import { GoAPI } from '../../Axios/axios';
import * as actionTypes from './actionTypes';

const getMemberProfileStart = () => ({
  type: actionTypes.GET_MEMBER_PROFILE_LOADING,
});
const getMemberProfileSuccess = (data) => ({
  type: actionTypes.GET_MEMBER_PROFILE_SUCCESS,
  payload: data,
});

const getMemberProfileFail = (error) => ({
  type: actionTypes.GET_MEMBER_PROFILE_FAIL,
  payload: error,
});
export const getMemberProfileDetails = (participantId) => (dispatch, getState) => {
  dispatch(getMemberProfileStart());
  GoAPI.get(`/i/v1/kcc/adminList/${participantId}`, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let resultData = res.data;
      dispatch(getMemberProfileSuccess(resultData));
    })
    .catch((err) => {
      console.log(err);
      dispatch(getMemberProfileFail(err));
    });
};
