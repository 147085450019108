import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Strophe, $pres } from 'strophe.js/src/strophe';
import { getFullJid } from '../Utilities/Utilities';

const options = {
  mechanisms: [Strophe.SASLMD5],
};

const CHAT_ENDPOINT = `${process.env.REACT_APP_CHAT_API_BASE}bosh`;
const connection = new Strophe.Connection(CHAT_ENDPOINT, options);

export const Context = React.createContext();

const useStropheConnection = () => {
  const [status, setStatus] = useState(false);
  const [recStatus, setRecStatus] = useState(false);
  const [typing, setTyping] = useState(false);
  const [unreadMsgCount, setUnreadMsgCount] = useState({});
  const [allMessages, setAllMessages] = useState({});
  const [currentAppointmentUser, setCurrentAppointmentUser] = useState(null);
  const chatCredential = useSelector((state) => state.user?.chatProfile);

  /*eslint-disable */
  useEffect(() => {
    //trying to connect with chat server
    connectToChatServer(getFullJid(chatCredential.kccChatUserID), chatCredential.kccChatPassword);
    return () => {
      connection.reset();
    };
  }, []);
  /*eslint-enable */

  /**
   * Callback function to notify user connection status.
   * @param {*} status
   */
  const onConnect = function (status) {
    setStatus(status);
    if (status === Strophe.Status.CONNECTED) {
      connection.send($pres());
      connection.addHandler(onMessageRecieved, null, 'message', null, null, null);
      connection.addHandler(onPresence, null, 'presence');
    }
  };

  /**
   * @desc Connecting to chat server
   * @param {*} jid
   * @param {*} password
   */
  const connectToChatServer = (jid, password) => {
    connection.connect(jid, password, onConnect);
  };

  function onPresence(presence) {
    var presence_type = presence.getAttribute('type'); // unavailable, subscribed, etc...
    // var from = presence.getAttribute("from"); // the jabber_id of the contact
    if (!presence_type) {
      presence_type = 'online';
      setRecStatus('Online');
    }
    if (presence_type !== 'error') {
      if (presence_type === 'unavailable') {
        // Mark contact as offline
        setRecStatus('Offline');
      } else {
        var show = presence.getElementsByTagName('show');
        const showText = show.length > 0 ? Strophe.getText(show[0]) : '';
        // this is what gives away, dnd, etc.
        if (showText === 'chat' || showText === '') {
          setRecStatus('Online');
          // Mark contact as online
        } else {
          // etc...
          setRecStatus(showText);
        }
      }
    }
    return true;
  }

  /**
   * Log the message onto the screen
   * @param {*} status
   */

  function log(msg, isOwn = false) {
    setAllMessages((msgs) => {
      const result = { ...msgs };
      const [source, text, timestamp] = msg.split(':');
      const [from] = source.split('@');
      result[from] = {
        source: source,
        text: text,
        time: parseInt(timestamp),
        isOwn,
      };
      return result;
    });
  }
  /**
   * Message Handler
   * trigger when someone send us message.
   * @param {*} msg
   * @returns
   */
  function onMessageRecieved(msg) { //when any incoming message is received from chat server. 
    console.log(msg)
    // const to = msg.getAttribute("to");
    const from = msg.getAttribute('from');
    const isModUser = from.toString().includes('_mod');
    const type = msg.getAttribute('type');
    if (type && !isModUser) {
      const elems = msg.getElementsByTagName('body');
      const composing = msg.getElementsByTagName('composing');
      const paused = msg.getElementsByTagName('paused');
      // const received = msg.getElementsByTagName("received");
      // const recievedId = received.length > 0 && received[0].getAttribute("id");
      if (Object.values(composing).length > 0) setTyping(true);
      if (Object.values(paused).length > 0) setTyping(false);
      if (type === 'chat' && elems.length > 0) {
        var body = elems[0];
        console.log('CHAT: I got a message from ' + from + ': ' + Strophe.getText(body));

        log(from + ':' + Strophe.getText(body) + ':' + Date.now());
        let bareJid = Strophe.getBareJidFromJid(from);
        setUnreadMsgCount((prev) => ({
          ...prev,
          [bareJid]: (prev[bareJid] || 0) + 1,
        }));
        setTyping(false);
      }
    } else if (isModUser) {
      const elems = msg.getElementsByTagName('body');
      console.log(elems);
      elems.length > 0 &&
        console.log(JSON.parse(Strophe.getText(elems[0]).replace(/&quot;/g, '"')));
      const dataFromMod =
        elems.length > 0 && JSON.parse(Strophe.getText(elems[0]).replace(/&quot;/g, '"'));

      console.log(dataFromMod);
      // log(from + ":" + `${body.extraData.targetUserId}` + ':' + Date.now());
      dataFromMod && setCurrentAppointmentUser(dataFromMod);
    }
    // we must return true to keep the handler alive.
    // returning false would remove it after it finishes.
    return true;
  }

  /**
   * Fetch chat history based on user id
   * @param {*} jid - your id
   * @param {*} withJid - to whom you want chat history user id
   * @param {*} messageCount - no. of messages
   * @param {*} callback
   */
  const fetchAllChatHistory = async (jid, withJid, messageCount = 1, callback) => {
    connection.mam.query(Strophe.getBareJidFromJid(jid), {
      with: withJid,
      before: '',
      max: messageCount,
      onMessage: function (message) {
        let messageDetails = message.getElementsByTagName('message')[0];
        let [from] = messageDetails.getAttribute('from').split('@');
        let to = messageDetails.getAttribute('to');
        // let type = messageDetails.getAttribute("type");
        let messageText = message.getElementsByTagName('body')[0]?.textContent;
        let timestamp = messageDetails.getElementsByTagName('stanza-id')[0].getAttribute('id');
        // let msgId = messageDetails.getAttribute("id");
        const result = { ...allMessages };
        const chatUser = from === chatCredential.kccChatUserID ? to : from;
        const isOwn = from === chatCredential.kccChatUserID;
        result[chatUser] = {
          source: chatUser,
          text: messageText,
          time: new Date(Math.round(timestamp / 1000)),
        };

        log(chatUser + ':' + messageText + ':' + timestamp / 1000, isOwn);
        callback();
        return true;
      },
      onComplete: function (response) {},
    });
  };

  return {
    status,
    recStatus,
    typing,
    allMessages,
    connection,
    currentAppointmentUser,
    setCurrentAppointmentUser,
    fetchAllChatHistory,
    log,
    unreadMsgCount,
    setUnreadMsgCount,
  };
};

export const Provider = (props) => {
  const value = useStropheConnection();
  return <Context.Provider value={value} {...props} />;
};
