import { Box, Button, Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addSessionHeartHabitDetails,
  deleteSessionHeartHabitDetails,
  editSessionHeartHabitDetails,
  sessionHeartHabitDetails,
} from '../../../../Store/actions/session';
import HeartHabitCard from './HeartHabitCard';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import moment from 'moment';
import { hearHabitData } from '../data/mockData';
import { colors, fonts, fontSizes } from '../../../../style/styleSettings';
const myStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `${fonts.SESSION}`,
    '& .telekardia h6': {
      fontWeight: '600 !important',
      color: '#000 !important',
    },
    '& .MuiButton-root': {
      width: '100%',
      color: `${colors.SPRUCE}`,
      background: '#FFF',
      fontWeight: '600',
      textAlign: 'center',
      // border: '1px solid #142A39',
      borderRadius: '4px',
    },
  },
  title: {
    fontWeight: '600 !important',
    color: '#000 !important',
    fontSize: `${fontSizes.MEDIUM} !important`,
  },
}));

const HeartHabitComponent = (props) => {
  const { patientDetails } = props;
  const { patient } = patientDetails;
  const dispatch = useDispatch();
  const patientDetailsntwrk = useSelector((state) => state.appointments.patientDetails);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [heartHabit, setheartHabit] = useState([]);
  // const [pfData, setPfData] = useState([]);
  // useEffect(() => {
  //   let offsetInMinutes = new Date().getTimezoneOffset();
  //   if (patient?.patientId !== undefined) {
  //     dispatch(
  //       sessionHeartHabitDetails(
  //         teamId,
  //         clinicianId,
  //         patient?.patientId,
  //         props.appointmentId,
  //         offsetInMinutes,
  //       ),
  //     );
  //   }
  // }, [patient]);
  const heartHealthData = useSelector((state) => state.session.heartHabit);
  // const programFocusData = useSelector((state) => state.session.programFocus);
  useEffect(() => {
    setheartHabit(heartHealthData);
    // setheartHabit(hearHabitData);
  }, [heartHealthData]);

  // useEffect(() => {
  //   setPfData(programFocusData);
  // }, [programFocusData]);

  /**
   * Add card handler
   * Locally updates the state to show a new card and not saving to api
   */
  const addCardHandler = () => {
    const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';

    let newHH = {
      id:null,
      focus: '',
      focusNote: '',
      recurrencePattern: 'RRULE:FREQ=DAILY;UNTIL=20211103T000000;BYDAY=MO,TU,WE,TH,FR',
      startDate: moment().format(pattern),
      endDate: moment().format(pattern),
    };
    setheartHabit((s) => [...s, newHH]);
  };

  /**
   * addCardHandlerSaveToApi
   * @param {*} data
   * @desc saves the newly added card to api
   */
  const addCardHandlerSaveToApi = (data) => {
    let offsetInMinutes = new Date().getTimezoneOffset();
    dispatch(
      addSessionHeartHabitDetails(
        teamId,
        clinicianId,
        patient?.patientId,
        props.appointmentId,
        offsetInMinutes,
        data,
      ),
    );
  };

  /**
   * deleteCardHandler
   * @param {*} id
   * Deletes the new card locally and saves it to the api
   */
  const deleteCardHandler = (id) => {
    // let hhData = [...heartHabit];
    // const deletingIndex = hhData.findIndex((h) => h.id === id);
    // console.log(deletingIndex);
    // hhData.splice(deletingIndex, 1);
    // console.log(hhData);
    // setheartHabit(hhData);
    let offsetInMinutes = new Date().getTimezoneOffset();
    if(id === null){
      setheartHabit(heartHealthData)
    }else{
      dispatch(
        deleteSessionHeartHabitDetails(
          teamId,
          clinicianId,
          patient?.patientId,
          props.appointmentId,
          offsetInMinutes,
          id
        ),
      );
    }    
  };

  /**
   * editCardHandler
   * @param {*} id
   * @param {*} data
   * Edits the card with the given id
   */
  const editCardHandler = (id, data) => {
    let offsetInMinutes = new Date().getTimezoneOffset();
    dispatch(
      editSessionHeartHabitDetails(
        teamId,
        clinicianId,
        patient?.patientId,
        props.appointmentId,
        offsetInMinutes,
        id,
        data,
      ),
    );
  };

  const classes = myStyles();
  return (
    <Box>
      <Grid container className={classes.root} spacing={2}>
        {heartHabit && heartHabit.map((item, index) => {
          return (
            <Grid item xs={6} key={item.id}>
              <HeartHabitCard
                item={item}
                index={index}
                deleteCard={deleteCardHandler}
                editCard={editCardHandler}
                addCardHandlerSaveToApi={addCardHandlerSaveToApi}
              />
            </Grid>
          );
        })}
        <Grid item xs={6}>
          {heartHabit.length < 3 && (
            <Card>
              <CardContent style={{ width: '100%' }}>
                <Typography variant="h6" className={classes.title}>
                  New Heart Habit
                </Typography>
                <Button variant="contained" size="large" onClick={addCardHandler}>
                  <AddCircleOutlineIcon color={`${colors.SPRUCE}`} />
                  <p>ADD HEART HABIT</p>
                </Button>
              </CardContent>
            </Card>
          )}          
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeartHabitComponent;
