/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as actions from '../Store/actions';
import { setResetIdleTime } from '../Auth/AuthUtilities';
import { connect } from 'react-redux';
import ProviderStatus from '../Components/Shared/ProviderStatus';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import OfflinePopup from '../Components/Appointments/Calendar/OfflinePopup';
import SidebarLayout from '../Components/SidebarLayout/index';
import * as Screens from '../CoachScreens';
import ContentHeader from '../Components/ContentHeader';
import AutoCompleteDropdown from '../Components/AutoCompleteDropdown';
import { Paper, Container } from '@material-ui/core';
import { getPageHeaderTitle } from '../Components/chart/Constants/constants';
import { AdminPhysicianRoutes, AdminRoutes, PhysicianRoutes } from './Routes';
import GlobalSearch from '../CoachScreens/Common/GlobalSearch';
import MemberMissedAlert from '../CoachScreens/Common/MemberMissedAlert';
import { Chat } from '../providers';

const useStyles = makeStyles(() => ({
  accessDeniedContent: {
    backgroundColor: '#FFFFFF',
    margin: '50px 0 0 0 ',
    padding: '40px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useNetwork = (onChange) => {
  const [status, setStatus] = useState(navigator.onLine);
  const handleChange = () => {
    if (typeof onChange === 'function') {
      onChange(navigator.onLine);
    }
    setStatus(navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener('online', handleChange);
    window.addEventListener('offline', handleChange);
    return () => {
      window.removeEventListener('online', handleChange);
      window.removeEventListener('offline', handleChange);
    };
  }, []);
  return status;
};

const App = (props) => {
  const [permissions, setPermissions] = React.useState();
  const classes = useStyles();
  const [offlinealertopen, setofflinealertopen] = React.useState(false);
  // const [currentSocketUrl, setCurrentSocketUrl] = useState(null);
  // const SOCKET_URL_ONE = 'wss://echo.websocket.org';
  const [snackbaropen, setsnackbaropen] = React.useState(false);
  const [networkstatus, setnetworkstatus] = useState('online');

  const handleNetworkChange = (online) => {
    online ? setofflinealertopen(false) : setofflinealertopen(true);
    online ? setnetworkstatus('online') : setnetworkstatus('We just offline');
  };

  const closeofflinepopup = () => {
    setofflinealertopen(false);
  };
  const onLine = useNetwork(handleNetworkChange);

  const handlesnackbarClose = (event, reason) => {
    setsnackbaropen(false);
  };

  const pageTitle = useMemo(
    () => getPageHeaderTitle(window.location.pathname, permissions?.isAdmin),
    [window.location.pathname, permissions],
  );

  useEffect(() => {
    const { permissions } = props.me || {};
    setPermissions(permissions);
  }, [props.me]);

  const routes =
    permissions?.isAdmin && permissions?.isPhysician
      ? AdminPhysicianRoutes
      : permissions?.isAdmin
      ? AdminRoutes
      : PhysicianRoutes;

  // ===consuming the chat context to get push notification whether member has missed the session ====// 
  const { currentAppointmentUser: caUser } = useContext(Chat.Context);
  const [autoMissedData, setautoMissedData] = useState({ //state to handle incoming push notifications
    patientId: '',
    isMissed: false
  })

  //====== Showing alert modal when member did not join the waiting room within 6 mins of sesison =====//
  useEffect(() => {
    if (caUser?.currentMode === 'MemberNotEnteredWaitingRoom') {
      setautoMissedData({
        patientId: caUser?.extraData?.memberId,
        isMissed: true
      })
    }
  }, [caUser])
  //=======================//

  return (
    <SidebarLayout>
      <div className="container-fluid p-0">
        <div onClick={setResetIdleTime}>
        {/* <ProviderStatus {...props} /> */}
          {pageTitle && (
            <Container maxWidth="xl">
              <ContentHeader
                showLastRefresh={false}
                showAddPatient={false}
                text={pageTitle.name}
                memberWaiting={false}
                {...props}
              />
              {pageTitle.isSearchEnabled && (
                <Paper style={{ padding: '10px' }}>
                  {/* <AutoCompleteDropdown /> */}
                  <GlobalSearch />
                </Paper>
              )}
              {/* //====== Showing alert modal when member did not join the waiting room within 6 mins of sesison =====// */}
              {!permissions?.isAdmin && autoMissedData.isMissed && <MemberMissedAlert autoMissedData={autoMissedData}/>}
            </Container>
          )}
          <div>
            <Switch>
              {routes.map((route, index) => (
                <Route key={index} {...route} />
              ))}
              <Route component={Screens.NotFoundPage} />
            </Switch>
          </div>
        </div>
        <OfflinePopup open={offlinealertopen} closeofflinepopup={closeofflinepopup} />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbaropen}
          autoHideDuration={3000}
          onClose={handlesnackbarClose}
        >
          <Alert
            onClose={handlesnackbarClose}
            severity={networkstatus === 'online' ? 'success' : 'error'}
          >
            {networkstatus}
          </Alert>
        </Snackbar>
      </div>
    </SidebarLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.user?.profile,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onAddUser: (x) => dispatch(actions.addUser(x)),
    onLogOut: () => dispatch(actions.user_logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
