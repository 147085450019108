import React from 'react';
import './../Assets/styles/style.scss';
const Errorpage = () => {
  return (
    <div>
      <span className="errorpagetext">
        Cannot Access Telekardia(Microfrontend) without Kardia-Pro
      </span>
    </div>
  );
};

export default Errorpage;
