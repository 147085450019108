/* eslint-disable */
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  profile: null,
  team: null,
  chatProfile: {},
  loading: false,
  errorMsg: null,
  // permissions: null,
  // authorizationType: null,
  isAuthenticated: false,
  login: false,
};

const getUserStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    errorMsg: null,
  });
};

const getUserSuccess = (state, action) => {
  // eslint-disable-next-line no-console
  if (action.payload === 'logOut')
    return updateObject(state, {
      profile: null,
      team: null,
      chatProfile: {},
    });
  else
    return updateObject(state, {
      profile: action.payload.profile,
      team: action.payload.teams[0],
      errorMsg: null,
      loading: false,
    });
};

const getUserChatInfoSuccess = (state, action) => {
  // eslint-disable-next-line no-console

  return updateObject(state, {
    chatProfile: action.payload,
  });
};

const setLogin = (state, action) => {
  // eslint-disable-next-line no-console

  return updateObject(state, {
    login: action.payload,
  });
};
const getUserFail = (state, action) => {
  //const newEvent = updateObject(action.eventData, { id: action.id });
  return updateObject(state, {
    loading: false,
    errorMsg: action.error,
  });
};

const setAuthentication = (state, action) => {
  // eslint-disable-next-line no-console
  return updateObject(state, {
    isAuthenticated: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_START:
      return getUserStart(state, action);
    case actionTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case actionTypes.GET_USER_FAIL:
      return getUserFail(state, action);
    case actionTypes.GET_USER_CHAT_CREDS:
      return getUserChatInfoSuccess(state, action);
    case actionTypes.SET_AUTHENTICATION:
      return setAuthentication(state, action);
    case actionTypes.SET_LOGIN:
      return setLogin(state, action);
    default:
      return state;
  }
};

export default reducer;
