/* eslint-disable */

import moment from 'moment';

import {
  fetchMe,
  fetchPhysiciansAndEkgStackCount,
  fetchTeamTriggers,
  fetchPutTeamTriggers,
  fetchPostTeamMemberInvite,
  fetchGetTeamMemberInvites,
  fetchDeleteTeamMemberInvite,
  fetchPostPassword,
  fetchGetTeamMember,
  fetchAppointmentNotification,
  fetchPostAppointmentNotification,
  fetchPutTeamMemberInfo,
  fetchGetTeamMemberReminders,
  fetchPostAcceptTeamMember,
  fetchPutTeamMember,
  fetchPutTeamMemberReminders,
  fetchDeleteTeamMember,
  fetchPostResetPasswordToken,
  fetchPostResetPassword,
} from '../../Utilities/ApiUrls';

import {
  DATASOURCES,
  SORT_TYPES,
  CLEAR_STATE,
  FORCE_BUST_CACHE_AFTER_MS,
} from '../../constants/app';
import { StandardCompare } from '../../Utilities/Utilities';

// ------------------------------------
// Constants
// ------------------------------------
const PREFIX = 'APP.';
export const UPDATE_OUTDATED_BROWSER_FLAG = `${PREFIX}UPDATE_OUTDATED_BROWSER_FLAG`;
export const UDATE_BROWSER_INFO = `${PREFIX}UPDATE_BROWSER_INFO`;
export const UPDATE_CURRENT_TEAM_ID = `${PREFIX}UPDATE_CURRENT_TEAM_ID`;
export const UPDATE_PREVIOUS_LOCATION = `${PREFIX}UPDATE_PREVIOUS_LOCATION`;
export const TOGGLE_NAVIGATION_SIDEBAR = `${PREFIX}TOGGLE_NAVIGATION_SIDEBAR`;
export const OPEN_MODAL = `${PREFIX}OPEN_MODAL`;
export const CLOSE_MODAL = `${PREFIX}CLOSE_MODAL`;
export const API_SERVICE_ERROR = `${PREFIX}API_SERVICE_ERROR`;

export const ME_REQUEST = `${PREFIX}ME_REQUEST`;
export const ME_SUCCESS = `${PREFIX}ME_SUCCESS`;
export const ME_FAILURE = `${PREFIX}ME_FAILURE`;

export const DATASOURCE_REQUEST = `${PREFIX}DATASOURCE_REQUEST`;
export const DATASOURCE_SUCCESS = `${PREFIX}DATASOURCE_SUCCESS`;
export const DATASOURCE_FAILURE = `${PREFIX}DATASOURCE_FAILURE`;
export const DATASOURCE_CLEAR_ERROR = `${PREFIX}DATASOURCE_CLEAR_ERROR`;

// ------------------------------------
// Actions
// ------------------------------------
export const updateOutdatedBrowserFlag = (flag) => ({
  type: UPDATE_OUTDATED_BROWSER_FLAG,
  payload: flag,
});
export const updateBrowserInfo = (browser) => ({
  type: UDATE_BROWSER_INFO,
  payload: browser,
});
export const updateCurrentTeamId = (teamId) => ({
  type: UPDATE_CURRENT_TEAM_ID,
  payload: teamId,
});
export const updatePrevLocation = (location) => ({
  type: UPDATE_PREVIOUS_LOCATION,
  payload: location,
});
export const dataSourceRequest = (name) => ({
  type: DATASOURCE_REQUEST,
  payload: {
    name,
  },
});

export const dataSourceFailure = (name, error) => {
  const errorString = error ? error.toString().replace('Error: ', '') : null;
  return {
    type: DATASOURCE_FAILURE,
    payload: {
      name,
      error: errorString,
    },
  };
};
export const dataSourceSuccess = (name, d) => ({
  type: DATASOURCE_SUCCESS,
  payload: {
    name,
    data: d,
  },
});
export const dataSourceClearError = (name) => ({
  type: DATASOURCE_CLEAR_ERROR,
  payload: {
    name,
  },
});

export const needToBustCache = (date = null) => {
  let delta = 0;
  const now = moment();

  if (moment.isMoment(date)) {
    delta = now.diff(date, 'milliseconds');
  } else if (moment.isDate(date) && moment(date).isValid()) {
    delta = now.diff(moment(date), 'milliseconds');
  } else {
    return false;
  }

  return delta > FORCE_BUST_CACHE_AFTER_MS;
};
// Collocating as this function will be used by any page that
// requests shared data sources
export const getDataSource = (state, name) => {
  const { dataSources } = state.app;

  let dataSource = {
    isFetching: false,
  };

  if (dataSources[name]) {
    dataSource = dataSources[name];
  }

  return dataSource;
};

export const getMe = () => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.ME));

  return dispatch(fetchMe())
    .then((data) => {
      dispatch(dataSourceRequest(DATASOURCES.BILLING_CODES));
      dispatch(dataSourceRequest(DATASOURCES.ICD10_CODES));

      const meDataSource = getDataSource(getState(), DATASOURCES.ME);
      const existingMe = meDataSource.data || {};
      const result = {
        // ...existingMe,
        // backend source of truth so replacing existing with backend data.
        ...data,
      };

      if (existingMe.teams && existingMe.teams.length > 0) {
        result.teams[0] = {
          ...existingMe.teams[0],
          // backend source of truth so replacing existing with backend data.
          ...data.teams[0],
        };
      }

      const billingCodes = {
        byId: {},
      };
      const icd10Codes = {
        byId: {},
      };

      result.teams = result.teams.map((team) => {
        const normalizedTeam = team;

        if (team.billingCodes) {
          normalizedTeam.billingCodes = team.billingCodes.map((billingCode) => {
            billingCodes.byId[billingCode.id] = billingCode;
            return billingCode.id;
          });
        }

        if (team.icd10Codes) {
          normalizedTeam.icd10Codes = team.icd10Codes.map((icd10Code) => {
            icd10Codes.byId[icd10Code.id] = icd10Code;
            return icd10Code.id;
          });
        }

        return normalizedTeam;
      });

      const orderedBillingCodes = Object.keys(billingCodes.byId).map((id) => billingCodes.byId[id]);
      billingCodes.sortedByName = sortBillingCodesByName(orderedBillingCodes).map(
        (billingCode) => billingCode.id,
      );

      const orderedIcd10Codes = Object.keys(icd10Codes.byId).map((id) => icd10Codes.byId[id]);
      icd10Codes.sortedByName = sortIcd10CodesByName(orderedIcd10Codes).map(
        (icd10Code) => icd10Code.id,
      );

      dispatch(dataSourceSuccess(DATASOURCES.BILLING_CODES, billingCodes));
      dispatch(dataSourceSuccess(DATASOURCES.ICD10_CODES, icd10Codes));
      return dispatch(dataSourceSuccess(DATASOURCES.ME, result));
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.ME, err));
    });
};
const sortBillingCodesByName = (billingCodes) => {
  const billingCodesCopy = [...billingCodes];
  const sortedBillingCodes = billingCodesCopy.sort((a, b) =>
    StandardCompare.strings(a.name, b.name, SORT_TYPES.ASC),
  );

  return sortedBillingCodes;
};

export const filterBillingCodesCanBeBillable = (billingCodes) =>
  billingCodes.filter((billingCode) => billingCode.billable);

const sortIcd10CodesByName = (icds) => {
  const icdsCopy = [...icds];
  const sortedIcds = icdsCopy.sort((a, b) =>
    StandardCompare.strings(a.name, b.name, SORT_TYPES.ASC),
  );

  return sortedIcds;
};

export const deleteTeamMemberInvite = (inviteId) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.TEAM_INVITES));

  return dispatch(fetchDeleteTeamMemberInvite(inviteId))
    .then((res) => {
      dispatch(dataSourceRequest(DATASOURCES.ME));
      const meDataSource = getDataSource(getState(), DATASOURCES.ME);
      const meResult = meDataSource.data || {};
      const teamInvitesDataSource = getDataSource(getState(), DATASOURCES.TEAM_INVITES);
      const teamInvitesResult = teamInvitesDataSource.data || {};

      const teamInvites = Object.keys(teamInvitesResult.byId).map(
        (id) => teamInvitesResult.byId[id],
      );
      meResult.teams[0].invites = sortInvitesByEmail(teamInvites).map((invite) => invite.id);

      if (meResult.teams[0].invites.includes(inviteId)) {
        const index = meResult.teams[0].invites.indexOf(inviteId);
        // delete meResult.teams[0].invites[index];
        meResult.teams[0].invites.splice(index,1)
      }
      if (Object.keys(teamInvitesResult.byId).includes(inviteId)) {
        delete teamInvitesResult.byId[inviteId];
      }

      dispatch(dataSourceSuccess(DATASOURCES.ME, meResult));
      dispatch(dataSourceSuccess(DATASOURCES.TEAM_INVITES, teamInvitesResult));
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.TEAM_INVITES, err));
    });
};
export const postResetPassword = (email) => (dispatch, getState) =>
  dispatch(fetchPostResetPassword(email))
    .then((res) => res.status === 200)
    .catch((err) => {
      console.log(err);
    });
export const postResetPasswordToken = (token, password) => (dispatch, getState) =>
  dispatch(fetchPostResetPasswordToken(token, password))
    .then((res) => {
      switch (res.status) {
        case 403:
          throw new Error('Your Reset Password Token Has Expired');
        case 200:
          return true;
        default:
          break;
      }
    })
    .catch((err) => {
      throw new Error(err.toString().replace('Error: ', ''));
    });
export const getTeamMember = (teamId, teamMemberId) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.PHYSICIAN));

  return dispatch(fetchGetTeamMember(teamId, teamMemberId))
    .then((teamMember) => {
      dispatch(dataSourceRequest(DATASOURCES.PHYSICIANS));
      const physiciansResult = {};
      // FIX: api should fix this format issue
      const formattedTeamMember = {
        id: teamMemberId,
        firstName: teamMember.first_name,
        lastName: teamMember.last_name,
        email: teamMember.email,
        country: teamMember.country,
        regions: teamMember.regions,
        canManageAvailability: teamMember.canManageAvailability,
        phone: teamMember.phone,
        permissions: {
          ...teamMember.permissions,
        },
      };

      const physiciansDataSource = getDataSource(getState(), DATASOURCES.PHYSICIANS);
      const existingPhysiciansById =
        (physiciansDataSource.data && physiciansDataSource.data.byId) || {};

      physiciansResult.byId = existingPhysiciansById;
      physiciansResult.byId[teamMemberId] = {
        ...physiciansResult.byId[teamMemberId],
        ...formattedTeamMember,
      };

      const physicians = Object.keys(physiciansResult.byId).map((id) => physiciansResult.byId[id]);
      physiciansResult.sortedByLastName = sortPhysiciansByLastName(physicians).map(
        (physician) => physician.id,
      );

      dispatch(dataSourceSuccess(DATASOURCES.PHYSICIANS, physiciansResult));
      return dispatch(dataSourceSuccess(DATASOURCES.PHYSICIAN, { id: teamMemberId }));
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.PHYSICIAN, err));
    });
};
export const getTeamMemberReminders = (teamId, teamMemberId) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.PHYSICIAN));

  return dispatch(fetchGetTeamMemberReminders(teamId, teamMemberId))
    .then((teamMemberReminder) => {
      dispatch(dataSourceRequest(DATASOURCES.PHYSICIANS));
      const physiciansResult = {};

      // FIX: api should fix this format issue
      const formattedTeamMemberReminder = {
        emailFrequency: teamMemberReminder.emailFrequency,
        personalReminder: teamMemberReminder.personalReminder,
        triageReminder: teamMemberReminder.triageReminder,
        personalEsignReportReminder: teamMemberReminder.personalEsignReportReminder,
        teamEsignReportReminder: teamMemberReminder.teamEsignReportReminder,
        teamExportReportReminder: teamMemberReminder.teamExportReportReminder,
      };
      const physiciansDataSource = getDataSource(getState(), DATASOURCES.PHYSICIANS);
      const existingPhysiciansById =
        (physiciansDataSource.data && physiciansDataSource.data.byId) || {};

      physiciansResult.byId = existingPhysiciansById;
      physiciansResult.byId[teamMemberId] = {
        ...physiciansResult.byId[teamMemberId],
        reminders: {
          ...formattedTeamMemberReminder,
        },
      };

      const physicians = Object.keys(physiciansResult.byId).map((id) => physiciansResult.byId[id]);
      physiciansResult.sortedByLastName = sortPhysiciansByLastName(physicians).map(
        (physician) => physician.id,
      );

      dispatch(dataSourceSuccess(DATASOURCES.PHYSICIANS, physiciansResult));
      return dispatch(dataSourceSuccess(DATASOURCES.PHYSICIAN, { id: teamMemberId }));
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.PHYSICIAN, err));
    });
};
export const putTeamMember = (teamId, teamMemberId, teamMember) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.PHYSICIAN));

  return dispatch(fetchPutTeamMember(teamId, teamMemberId, teamMember))
    .then((teamMemberData) => {
      dispatch(dataSourceRequest(DATASOURCES.PHYSICIANS));
      const physiciansResult = {};

      // FIX: api should fix this format issue
      const formattedTeamMember = {
        id: teamMemberId,
        firstName: teamMemberData.first_name,
        lastName: teamMemberData.last_name,
        email: teamMemberData.email,
        country: teamMemberData.country,
        regions: teamMemberData.regions,
        canManageAvailability: teamMemberData.canManageAvailability,
        phone: teamMemberData.phone,
        permissions: {
          ...teamMemberData.permissions,
        },
      };

      const physiciansDataSource = getDataSource(getState(), DATASOURCES.PHYSICIANS);
      const existingPhysiciansById =
        (physiciansDataSource.data && physiciansDataSource.data.byId) || {};

      physiciansResult.byId = existingPhysiciansById;
      physiciansResult.byId[teamMemberId] = {
        ...physiciansResult.byId[teamMemberId],
        ...formattedTeamMember,
      };

      const physicians = Object.keys(physiciansResult.byId).map((id) => physiciansResult.byId[id]);
      physiciansResult.sortedByLastName = sortPhysiciansByLastName(physicians).map(
        (physician) => physician.id,
      );

      dispatch(dataSourceSuccess(DATASOURCES.PHYSICIANS, physiciansResult));
      return dispatch(dataSourceSuccess(DATASOURCES.PHYSICIAN, { id: teamMemberId }));
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.PHYSICIAN, err));
    });
};
export const putTeamMemberReminders = (teamId, teamMemberId, reminders) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.PHYSICIAN));

  return dispatch(fetchPutTeamMemberReminders(teamId, teamMemberId, reminders))
    .then((teamMemberReminder) => {
      dispatch(dataSourceRequest(DATASOURCES.PHYSICIANS));
      const physiciansResult = {};

      // FIX: api should fix this format issue
      const formattedTeamMemberReminder = {
        emailFrequency: teamMemberReminder.emailFrequency,
        personalReminder: teamMemberReminder.personalReminder,
        triageReminder: teamMemberReminder.triageReminder,
        personalEsignReportReminder: teamMemberReminder.personalEsignReportReminder,
        teamEsignReportReminder: teamMemberReminder.teamEsignReportReminder,
        teamExportReportReminder: teamMemberReminder.teamExportReportReminder,
      };

      const physiciansDataSource = getDataSource(getState(), DATASOURCES.PHYSICIANS);
      const existingPhysiciansById =
        (physiciansDataSource.data && physiciansDataSource.data.byId) || {};

      physiciansResult.byId = existingPhysiciansById;
      physiciansResult.byId[teamMemberId] = {
        ...physiciansResult.byId[teamMemberId],
        reminders: {
          ...formattedTeamMemberReminder,
        },
      };

      const physicians = Object.keys(physiciansResult.byId).map((id) => physiciansResult.byId[id]);
      physiciansResult.sortedByLastName = sortPhysiciansByLastName(physicians).map(
        (physician) => physician.id,
      );

      dispatch(dataSourceSuccess(DATASOURCES.PHYSICIANS, physiciansResult));
      return dispatch(dataSourceSuccess(DATASOURCES.PHYSICIAN, { id: teamMemberId }));
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.PHYSICIAN, err));
    });
};
export const deleteTeamMember = (teamId, teamMemberId) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.PHYSICIAN));

  return dispatch(fetchDeleteTeamMember(teamId, teamMemberId))
    .then((res) => {
      switch (res.status) {
        case 403:
          throw new Error('Cannot remove team member');
        case 200:
        default:
          break;
      }

      return dispatch(clientSideDeleteTeamMember(teamMemberId)).then(() =>
        dispatch(dataSourceSuccess(DATASOURCES.PHYSICIAN, { id: teamMemberId })),
      );
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.PHYSICIAN, err));
    });
};
export const getTeamMemberInvites = (teamId) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.TEAM_INVITES));

  return dispatch(fetchGetTeamMemberInvites(teamId))
    .then((data) => {
      dispatch(dataSourceRequest(DATASOURCES.ME));
      const meDataSource = getDataSource(getState(), DATASOURCES.ME);
      const meResult = meDataSource.data || {};
      const teamInvitesDataSource = getDataSource(getState(), DATASOURCES.TEAM_INVITES);
      const existingTeamInvitesById =
        (teamInvitesDataSource.data && teamInvitesDataSource.data.byId) || {};

      const invites = data.invites || [];
      const teamInvitesResult = {
        byId: existingTeamInvitesById,
      };

      teamInvitesResult.byId = invites.reduce((acc, invite) => {
        acc[invite.id] = invite;
        return acc;
      }, teamInvitesResult.byId);

      meResult.teams[0].invites = sortInvitesByEmail(invites).map((invite) => invite.id);

      dispatch(dataSourceSuccess(DATASOURCES.ME, meResult));
      dispatch(dataSourceSuccess(DATASOURCES.TEAM_INVITES, teamInvitesResult));
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.TEAM_INVITES, err));
    });
};
export const sortInvitesByEmail = (invites) => {
  const invitesCopy = [...invites];
  const sortedInvites = invitesCopy.sort((a, b) =>
    StandardCompare.strings(a.email, b.email, SORT_TYPES.ASC),
  );

  return sortedInvites;
};
export const postAcceptTeamMember = (newTeamMember) => (dispatch, getState) =>
  // request
  dispatch(fetchPostAcceptTeamMember(newTeamMember))
    // success - ignore data since no team member id
    .then((data) => Promise.resolve())
    .catch((err) => console.log('postAcceptTeamMember', err));

const sortPhysiciansByLastName = (physicians) => {
  const physiciansCopy = [...physicians];
  const sortedPhysicians = physiciansCopy.sort((a, b) => {
    // sort by last name
    const lastNameResult = StandardCompare.strings(a.lastName, b.lastName, SORT_TYPES.ASC);
    if (lastNameResult === 0) {
      // if last name the same, sort by first name
      const firstNameResult = StandardCompare.strings(a.firstName, b.firstName, SORT_TYPES.ASC);
      if (firstNameResult === 0) {
        // if first name the same, sort by email
        return StandardCompare.strings(a.email, b.email, SORT_TYPES.ASC);
      } else {
        return firstNameResult;
      }
    } else {
      return lastNameResult;
    }
  });

  return sortedPhysicians;
};
export const fetchMemberAppointmentNotification = (teamId, memeberId) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.MEMBER_NOTIFICATION_SETTINGS));

  return dispatch(fetchAppointmentNotification(teamId, memeberId))
    .then((data) => {
      dispatch(dataSourceSuccess(DATASOURCES.MEMBER_NOTIFICATION_SETTINGS, data));
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.MEMBER_NOTIFICATION_SETTINGS, err));
    });
};

export const postAppointmentNotification = (teamId, memeberId, data) => (dispatch, getState) =>
  dispatch(fetchPostAppointmentNotification(teamId, memeberId, data))
    .then((res) => {
      switch (res.status) {
        case 400:
          throw new Error('Setting Failed');
        default:
          break;
      }
    })
    .catch((err) => {
      console.log(err);
      const errorString = err.toString().replace('Error: ', '');
      throw errorString;
    });
// For editing the info on My Info page in case of Telekardia Enabled
export const putTeamMemberInfo = (teamId, teamMemberId, teamMember) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.MEMBER_INFO));

  const formattedData = {
    id: teamMemberId,
    first_name: teamMember.firstName,
    last_name: teamMember.lastName,
    email: teamMember.email,
    country: teamMember.country,
    regions: teamMember.regions,
    phone: teamMember.phone,
    is_physician: teamMember.permissions.isPhysician,
  };

  return dispatch(fetchPutTeamMemberInfo(teamId, teamMemberId, formattedData))
    .then(() => {
      return dispatch(getMe());
    })
    .then((teamMemberData) => {
      dispatch(dataSourceRequest(DATASOURCES.MEMBER_INFO));
      // FIX: api should fix this format issue
      const formattedTeamMember = {
        id: teamMemberId,
        first_name: teamMemberData.first_name,
        last_name: teamMemberData.last_name,
        email: teamMemberData.email,
        country: teamMemberData.country,
        regions: teamMemberData.regions,
        phone: teamMemberData.phone,
        is_physician: teamMemberData.permissions.isPhysician,
        permissions: {
          ...teamMemberData.permissions,
        },
      };

      return dispatch(dataSourceSuccess(DATASOURCES.MEMBER_INFO, formattedTeamMember));
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.MEMBER_INFO, err));
    });
};

// For getting the info on My Info page in case of Telekardia Enabled
export const getTeamMemberInfo = (teamId, teamMemberId) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.MEMBER_INFO));
  dispatch(dataSourceRequest(DATASOURCES.ME));
  const meDataSource = getDataSource(getState(), DATASOURCES.ME);
  const meResult = meDataSource.data || {};
  if (!meResult.profile.permissions.isAdmin) {
    dispatch(dataSourceRequest(DATASOURCES.MEMBER_INFO));
    const formattedTeamMember = {
      id: teamMemberId,
      firstName: meResult.profile.firstName,
      lastName: meResult.profile.lastName,
      email: meResult.profile.email,
      country: meResult.profile.country,
      regions: meResult.profile.regions,
      phone: meResult.profile.phone,
      permissions: {
        ...meResult.profile.permissions,
      },
    };
    dispatch(dataSourceSuccess(DATASOURCES.MEMBER_INFO, formattedTeamMember));
  } else {
    return dispatch(fetchGetTeamMember(teamId, teamMemberId))
      .then((teamMember) => {
        dispatch(dataSourceRequest(DATASOURCES.MEMBER_INFO));
        // FIX: api should fix this format issue
        const formattedTeamMember = {
          id: teamMemberId,
          firstName: teamMember.first_name,
          lastName: teamMember.last_name,
          email: teamMember.email,
          country: teamMember.country,
          regions: teamMember.regions,
          phone: teamMember.phone,
          permissions: {
            ...teamMember.permissions,
          },
        };

        return dispatch(dataSourceSuccess(DATASOURCES.MEMBER_INFO, formattedTeamMember));
      })
      .catch((err) => {
        dispatch(dataSourceFailure(DATASOURCES.MEMBER_INFO, err));
      });
  }
};
export const postTeamMemberInvite = (teamId, newTeamMember) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.PHYSICIANS));

  return dispatch(fetchPostTeamMemberInvite(teamId, newTeamMember))
    .then((res) => {
      switch (res.status) {
        case 409:
          throw new Error('Email already taken');
        case 200:
        default:
          break;
      }
      const physiciansResult = {};

      const physiciansDataSource = getDataSource(getState(), DATASOURCES.PHYSICIANS);
      const existingPhysiciansById =
        (physiciansDataSource.data && physiciansDataSource.data.byId) || {};

      physiciansResult.byId = existingPhysiciansById;

      const physicians = Object.keys(physiciansResult.byId).map((id) => physiciansResult.byId[id]);
      physiciansResult.sortedByLastName = sortPhysiciansByLastName(physicians).map(
        (physician) => physician.id,
      );

      dispatch(dataSourceSuccess(DATASOURCES.PHYSICIANS, physiciansResult));
    })
    .catch((err) => {
      const errorString = err.toString().replace('Error: ', '');
      dispatch(dataSourceFailure(DATASOURCES.PHYSICIANS, errorString));
      throw errorString;
    });
};
export const getPhysiciansAndEkgStackCount = (teamId) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.PHYSICIANS));
  dispatch(dataSourceRequest(DATASOURCES.EKG_STACK_COUNTS));

  return dispatch(fetchPhysiciansAndEkgStackCount(teamId))
    .then((data) => {
      const { members, unassigned, confirmed } = data;

      const ekgStackCountsResult = {};

      const ekgStackCountsDataSource = getDataSource(getState(), DATASOURCES.EKG_STACK_COUNTS);
      const existingPhysiciansEkgStackCountsById =
        (ekgStackCountsDataSource.data && ekgStackCountsDataSource.data.physicians.byId) || {};

      ekgStackCountsResult.triage = unassigned;
      ekgStackCountsResult.confirmed = confirmed;
      ekgStackCountsResult.physicians = {};
      ekgStackCountsResult.physicians.byId = members.reduce((acc, item) => {
        const mem = item.member;
        acc[mem.id] = item.count;
        return acc;
      }, existingPhysiciansEkgStackCountsById);

      const physiciansResult = {};

      const physiciansDataSource = getDataSource(getState(), DATASOURCES.PHYSICIANS);
      const existingPhysiciansById =
        (physiciansDataSource.data && physiciansDataSource.data.byId) || {};

      physiciansResult.byId = members.reduce((acc, item) => {
        const mem = item.member;
        acc[mem.id] = {
          ...existingPhysiciansById[mem.id],
          ...mem,
        };
        return acc;
      }, existingPhysiciansById);

      const physicians = Object.keys(physiciansResult.byId).map((id) => physiciansResult.byId[id]);
      physiciansResult.sortedByLastName = sortPhysiciansByLastName(physicians).map(
        (physician) => physician.id,
      );

      dispatch(dataSourceSuccess(DATASOURCES.PHYSICIANS, physiciansResult));
      dispatch(dataSourceSuccess(DATASOURCES.EKG_STACK_COUNTS, ekgStackCountsResult));
    })
    .catch((err) => {
      dispatch(dataSourceFailure(DATASOURCES.PHYSICIANS, err));
      dispatch(dataSourceFailure(DATASOURCES.EKG_STACK_COUNTS, err));
    });
};

export const getTeamTriggers = (teamId) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.TEAM_TRIGGERS));

  return dispatch(fetchTeamTriggers(teamId))
    .then((data) => {
      const triggersDataSource = getDataSource(getState(), DATASOURCES.TEAM_TRIGGERS);
      const existingTriggersById = (triggersDataSource.data && triggersDataSource.data.byId) || {};

      const result = {};
      result.byId = Object.keys(data).reduce((acc, itemType, i) => {
        const item = data[itemType];
        item.type = itemType;
        item.sortOrder = i;
        acc[item.id] = item;
        return acc;
      }, existingTriggersById);

      const triggers = Object.keys(result.byId).map((id) => result.byId[id]);
      result.sortedByApi = sortedByTriggersAPI(triggers) // use order of api return
        .map((trigger) => trigger.id);

      result.typeToIdLookup = typeToIdLookup(triggers);

      dispatch(dataSourceSuccess(DATASOURCES.TEAM_TRIGGERS, result));
    })
    .catch((err) => {
      console.log(err);
      dispatch(dataSourceFailure(DATASOURCES.TEAM_TRIGGERS, err));
    });
};
const sortedByTriggersAPI = (triggers) => {
  const triggersCopy = [...triggers];
  const sortedTriggers = triggersCopy.sort((a, b) => (a.sortOrder <= b.sortOrder ? -1 : 1)); // index sort

  return sortedTriggers;
};

export const putTeamTriggers = (teamId, triggers) => (dispatch, getState) => {
  dispatch(dataSourceRequest(DATASOURCES.TEAM_TRIGGERS));

  return dispatch(fetchPutTeamTriggers(teamId, triggers))
    .then((data) => {
      const triggersDataSource = getDataSource(getState(), DATASOURCES.TEAM_TRIGGERS);
      const existingTriggersById = (triggersDataSource.data && triggersDataSource.data.byId) || {};

      const result = {};
      result.byId = Object.keys(data).reduce((acc, itemType, i) => {
        const item = data[itemType];
        item.type = itemType;
        item.sortOrder = i;
        acc[item.id] = item;
        return acc;
      }, existingTriggersById);

      const trigs = Object.keys(result.byId).map((id) => result.byId[id]);
      result.sortedByApi = sortedByTriggersAPI(trigs) // use order of api return
        .map((trigger) => trigger.id);

      result.typeToIdLookup = typeToIdLookup(trigs);

      dispatch(dataSourceSuccess(DATASOURCES.TEAM_TRIGGERS, result));
    })
    .catch((err) => {
      console.log(err);
      dispatch(dataSourceFailure(DATASOURCES.TEAM_TRIGGERS, err));
    });
};
export const denormalizeTriggersBackToAPIStructure = (triggersById) =>
  Object.keys(triggersById).reduce((acc, triggerId) => {
    const trigger = triggersById[triggerId];
    const { frequency, id, value, type } = trigger;

    acc[type] = {
      frequency,
      id,
      value,
    };

    return acc;
  }, {});
export const postPassword = (password) => (dispatch, getState) =>
  dispatch(fetchPostPassword(password))
    .then((res) => {
      switch (res.status) {
        case 400:
          throw new Error('Your current password is incorrect, please try again');
        default:
          break;
      }
    })
    .catch((err) => {
      console.log(err);
      const errorString = err.toString().replace('Error: ', '');
      throw errorString;
    });
const typeToIdLookup = (triggers) =>
  triggers.reduce((acc, trigger) => {
    acc[trigger.type] = trigger.id;
    return acc;
  }, {});
const setDSFetching = (dataSources, payload, isFetching = true) => ({
  ...dataSources,
  [payload.name]: {
    ...dataSources[payload.name],
    error: null,
    isFetching,
  },
});

const setDSData = (dataSources, payload) => ({
  ...dataSources,
  [payload.name]: {
    ...dataSources[payload.name],
    data: payload.data,
    error: null,
    isFetching: false,
  },
});

const setDSError = (dataSources, payload) => ({
  ...dataSources,
  [payload.name]: {
    ...dataSources[payload.name],
    error: payload.error,
    isFetching: false,
  },
});

const clearDSError = (dataSources, payload) => ({
  ...dataSources,
  [payload.name]: {
    ...dataSources[payload.name],
    error: null,
    isFetching: false,
  },
});

export function setApiServiceError(payload) {
  return {
    type: API_SERVICE_ERROR,
    payload,
  };
}

export const toggleNavigationSidebar = () => ({
  type: TOGGLE_NAVIGATION_SIDEBAR,
});

export const collapseNavigationSidebar = () => ({
  type: COLLAPSE_NAVIGATION_SIDEBAR,
});

export const handleToggleNavigationSidebar = () => (dispatch, getState) => {
  dispatch(toggleNavigationSidebar());
};

const initialState = {
  sidebarIsCollapsed: false,
  modalIsOpen: false,
  isOutdatedBrowser: false,
  currentTeamId: '',
  browser: {
    name: '',
    version: '',
  },
  previousLocation: undefined,
  dataSources: {},
  apiServiceError: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [API_SERVICE_ERROR]: (state, action) => ({
    ...state,
    apiServiceError: action.payload,
  }),
  [TOGGLE_NAVIGATION_SIDEBAR]: (state, action) => ({
    ...state,
    sidebarIsCollapsed: !state.sidebarIsCollapsed,
  }),
  [OPEN_MODAL]: (state, action) => ({
    ...state,
    modalIsOpen: true,
  }),
  [CLOSE_MODAL]: (state, action) => ({
    ...state,
    modalIsOpen: false,
  }),
  [UPDATE_OUTDATED_BROWSER_FLAG]: (state, action) => ({
    ...state,
    isOutdatedBrowser: action.payload,
  }),
  [UPDATE_CURRENT_TEAM_ID]: (state, action) => ({
    ...state,
    currentTeamId: action.payload,
  }),
  [UDATE_BROWSER_INFO]: (state, action) => ({
    ...state,
    browser: action.payload,
  }),
  [UPDATE_PREVIOUS_LOCATION]: (state, action) => ({
    ...state,
    previousLocation: action.payload,
  }),
  [DATASOURCE_REQUEST]: (state, action) => ({
    ...state,
    dataSources: setDSFetching(state.dataSources, action.payload),
  }),
  [DATASOURCE_SUCCESS]: (state, action) => ({
    ...state,
    dataSources: setDSData(state.dataSources, action.payload),
  }),
  [DATASOURCE_FAILURE]: (state, action) => ({
    ...state,
    dataSources: setDSError(state.dataSources, action.payload),
  }),
  [DATASOURCE_CLEAR_ERROR]: (state, action) => ({
    ...state,
    dataSources: clearDSError(state.dataSources, action.payload),
  }),
  /* TODO: figure out the best way of maintain current team id */
  [CLEAR_STATE]: (state, action) => ({
    ...initialState,
    isOutdatedBrowser: state.isOutdatedBrowser,
    browser: state.browser,
    currentTeamId: state.currentTeamId,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
