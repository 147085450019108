import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import InputFieldComponent from '../InputFieldComponent';
import { DAYS_MAP, DAYS, HEART_HABIT_FIELDS } from '../data/constants';
import swal from 'sweetalert';
import { colors, fontSizes, fonts, spacing } from '../../../../style/styleSettings';
import moment from 'moment';
import { useSelector } from 'react-redux';

const GreenCheckbox = withStyles({
  root: {
    color: colors.SPRUCE,
    '&$checked': {
      color: colors.SPRUCE,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const myStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `${fonts.SESSION}`,
    '& .telekardia h6': {
      fontWeight: '600 !important',
      color: '#000 !important',
    },
    '& .MuiSelect-outlined': {
      border: '1px solid rgba(20, 42, 57, 0.16)',
      padding: '10px',
    },
    '& .MuiButton-root': {
      border: `1px solid ${colors.SPRUCE}`,
      borderRadius: '4px',
      fontFamily: `${fonts.SESSION}`,
      fontWeight: 600,
      width: '80%',
    },
  },
  // swalNoConfirm: {
  //   boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important',
  //     borderRadius: '4px !important'
  // },
  title: {
    fontWeight: '600 !important',
    color: '#000 !important',
    fontSize: `${fontSizes.MEDIUM} !important`,
  },

  formControl: {
    width: '100%',
    marginBottom: '28px',
  },
  readOnlyListItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: `${fontSizes.BASE}`,
    '& p:first-child': {
      margin: 0,
      marginRight: `${spacing.SMALL}`,
      fontWeight: '600',
    },
  },
  customInputStyle: {
    display: 'flex !important',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    //justifyContent: 'flex-end',
  },

  delButton: {
    color: `${colors.SESSION_BLACK} !important`,
    background: '#FFF',
    marginRight: '24px',
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    border: 'unset !important',
  },
  saveButton: {
    background: `${colors.SPRUCE} !important`,
    color: '#FFF !important',
    border: `1px solid ${colors.SPRUCE} !important`,
  },
  editButton: {
    background: '#fff !important',
    color: `${colors.SESSION_BLACK} !important`,
    border: `1px solid ${colors.SESSION_BLACK} !important`,
  },
}));

const HeartHabitCard = ({ index, item, deleteCard, editCard, addCardHandlerSaveToApi }) => {
  const [edit, setEditing] = useState(false);

  //local states
  const [hhData, sethhData] = useState({
    focus: '',
    focusNote: '',
    recurrencePattern: '',
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
    seriespattern: '',
    rp: {},
  });
  const parseRecurrencePattern = (item) => {
    let weekdayselection = [];

    if (item.recurrencePattern) {
      let recursiveElements = item?.recurrencePattern.split('=');
      let recurrencepattern = recursiveElements[1].split(';');
      let days = recursiveElements[3].split(':')[0].split(',');
      if (recurrencepattern[0] === 'DAILY' && days.length === 7) {
        item.rp = {
          seriespattern: 'Daily',
          selectedworkingDay: ['1', '2', '3', '4', '5'],
          Recurrence: true,
        };
        item.seriespattern = 'Daily';
      } else if (recurrencepattern[0] === 'DAILY' && days.length === 5) {
        item.rp = {
          selectedworkingDay: ['1', '2', '3', '4', '5'],
          Recurrence: true,
        };
        item.seriespattern = 'Daily';
      } else {
        days.map((key) => {
          if (key === 'SU') weekdayselection.push('0');
          else if (key === 'MO') weekdayselection.push('1');
          else if (key === 'TU') weekdayselection.push('2');
          else if (key === 'WE') weekdayselection.push('3');
          else if (key === 'TH') weekdayselection.push('4');
          else if (key === 'FR') weekdayselection.push('5');
          else weekdayselection.push('6');
        });
        item.rp = {
          selectedworkingDay: weekdayselection,
          Recurrence: true,
        };
        item.seriespattern = 'Weekly';
      }
    } else {
      item.seriespattern = 'Daily';
      item.rp = {
        selectedworkingDay: ['1', '2', '3', '4', '5'],
        Recurrence: true,
      };
    }
    return item;
  };
  useEffect(() => {
    let it = parseRecurrencePattern(item);
    sethhData(it);
    //checking if it is a new card
    if (!item.id) {
      setEditing(true);
    }
  }, [item]);
  const hhChangeHandler = (val, type) => {
    // console.log(val, type);
    let tempData = { ...hhData };
    tempData[type] = val;
    if (type === HEART_HABIT_FIELDS.REC_PATTERN) {
      if (val === 'Daily') {
        tempData.rp.selectedworkingDay = ['1', '2', '3', '4', '5'];
        tempData.seriespattern = 'Daily';
      }
    }
    sethhData((t) => tempData);
  };

  const [dateValidation, setdateValidation] = useState(true);
  const submitHandler = () => {
    if (edit) {
      sethhData((h) => {
        h.rp.selectedworkingDay
          .map((s) => parseInt(s))
          .sort()
          .map((s) => s.toString());
        return h;
      });
      console.log(hhData);
      //make api call
      const data = {
        days: hhData.rp.selectedworkingDay,
        endDate: hhData.endDate && moment(hhData.endDate).add(1, 'days').format().split('T')[0],
        focusNote: hhData.focusNote,
        recurrencePattern: hhData.seriespattern == 'Daily' ? 0 : 1,
        startDate:
          hhData.startDate && moment(hhData.startDate).add(1, 'days').format().split('T')[0],
      };
      if (!item.id) {
        data['focus'] = hhData.focus;
        if (moment(data.endDate).isAfter(data.startDate)) {
          addCardHandlerSaveToApi(data);
          setEditing((e) => !e);
        } else {
          setdateValidation(false);
        }
      } else {
        setEditing((e) => !e);
        editCard(item.id, data);
      }
    } else {
      setEditing(true);
    }
  };
  const classes = myStyles();

  const deleteConfirm = (id) => {
    swal({
      closeOnClickOutside: false,
      //icon: 'success',
      // title: 'Are you sure you want to delete this habit?',
      text: 'Are you sure you want to delete this habit?',
      className: 'telekardia',
      buttons: {
        cancel: {
          visible: true,
          text: '',
          className: 'swal_cancel swal_black swal_close',
          closeModal: true,
        },
        noConfirm: {
          text: 'DO NOT DELETE',
          visible: true,
          value: 'no',
          className: 'swal_cancel swal_black',
          closeModal: true,
        },
        confirm: {
          text: 'DELETE',
          visible: true,
          value: 'yes',
          className: 'swal_ok swal_green',
          closeModal: true,
        },
      },
    }).then((value) => {
      if (value === 'yes') {
        deleteCard(id);
      }
    });
  };
  const [programFocus, setprogramFocus] = useState([]);
  const programFocusData = useSelector((state) => state.session.programFocus);
  useEffect(() => {
    setprogramFocus(programFocusData);
  }, [programFocusData]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6" className={classes.title}>
          Heart Habit {index + 1}: {!edit && item.focus}
        </Typography>
        {edit && (
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={hhData.focus && hhData.focus.split('-')[0].trim()}
              onChange={(e) => hhChangeHandler(e.target.value, HEART_HABIT_FIELDS.FOCUS)}
              disabled={item.id}
            >
              {programFocus.length > 0 ? (
                programFocus.map((d) => {
                  return <MenuItem value={d.focus}>{d.focus}</MenuItem>;
                })
              ) : (
                <MenuItem value="">Program focus yet to create</MenuItem>
              )}
            </Select>
          </FormControl>
        )}

        {!edit ? (
          <Box className={classes.readOnlyListItem}>
            <p>{'Habit'}:</p>
            <p>{item.focusNote}</p>
          </Box>
        ) : (
          <FormControl
            variant="outlined"
            className={`${classes.formControl} ${classes.customInputStyle}`}
            style={{ marginBottom: '30px' }}
          >
            <p>I will</p>{' '}
            <InputFieldComponent
              value={hhData.focusNote}
              onChange={(e) => hhChangeHandler(e.target.value, HEART_HABIT_FIELDS.FOCUS_NOTE)}
            />
          </FormControl>
        )}

        {!edit ? (
          <Box className={classes.readOnlyListItem}>
            <p>Frequency:</p>
            <p>{item.recurrencePattern?.split(';')[0].split('=')[1] || ''}</p>
          </Box>
        ) : (
          <>
            <Typography variant="h6" className={classes.title}>
              Frequency
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-label="frequency"
                name="frequency"
                value={hhData.seriespattern}
                onChange={(e) => hhChangeHandler(e.target.value, HEART_HABIT_FIELDS.REC_PATTERN)}
              >
                <FormControlLabel value="Daily" control={<Radio />} label="Daily" />
                <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
              </RadioGroup>
            </FormControl>
          </>
        )}
        {edit && hhData.seriespattern === 'Weekly' && (
          <>
            <Typography variant="h6" className={classes.title}>
              Repeat on
            </Typography>
            <Grid container>
              {DAYS.map((d) => (
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={hhData.rp.selectedworkingDay.indexOf(DAYS_MAP[d]) !== -1}
                        onChange={(e) => {
                          if (e.target.checked) {
                            let tempData = { ...hhData };
                            tempData.rp.selectedworkingDay.push(DAYS_MAP[d]);
                            sethhData(tempData);
                          } else {
                            let index = hhData.rp.selectedworkingDay.indexOf(DAYS_MAP[d]);
                            let tempData = { ...hhData };
                            tempData.rp.selectedworkingDay.splice(index, 1);
                            sethhData(tempData);
                          }
                        }}
                        name={d}
                      />
                    }
                    label={d}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {!edit ? (
          <Box className={classes.readOnlyListItem}>
            <p>Duration: </p>
            <p>{`${item.startDate?.split('T')[0]} - ${item.endDate?.split('T')[0]}`}</p>
          </Box>
        ) : (
          <>
            <Typography variant="h6" className={classes.title}>
              Duration
            </Typography>
            <FormControl>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="date"
                    variant="outlined"
                    label="Start"
                    type="date"
                    defaultValue={hhData.startDate?.split('T')[0]}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => hhChangeHandler(e.target.value, HEART_HABIT_FIELDS.START_DATE)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="date"
                    variant="outlined"
                    label="End"
                    type="date"
                    defaultValue={hhData.endDate?.split('T')[0]}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => hhChangeHandler(e.target.value, HEART_HABIT_FIELDS.END_DATE)}
                  />
                  {!dateValidation && (
                    <p style={{ color: 'red' }}>end date should be greater than start date</p>
                  )}
                </Grid>
              </Grid>
            </FormControl>
          </>
        )}
      </CardContent>
      <CardActions>
        {edit ? (
          <Grid container className={classes.buttonContainer} spacing={2}>
            <Grid item xs={6}>
              <Button
                className={classes.delButton}
                variant="contained"
                size="large"
                onClick={() => deleteConfirm(item.id)}
              >
                DELETE
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.saveButton}
                variant="contained"
                size="large"
                onClick={submitHandler}
              >
                SAVE
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={classes.buttonContainer} spacing={2}>
            <Grid item xs={12}>
              <Button
                className={classes.editButton}
                variant="contained"
                size="large"
                onClick={submitHandler}
              >
                {item.loading ? <CircularProgress fontSize="small" color={'#000'} /> : 'EDIT'}
              </Button>
            </Grid>
          </Grid>
        )}
      </CardActions>
    </Card>
  );
};

export default HeartHabitCard;
