import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { fontSizes, spacing, colors } from '../../../../style/styleSettings'
import { commonStyles } from '../../styles';
import styles from '../../memberProfile.module.css';
import ChipComponent from '../../../../Components/Dashboard/SessionComponents/ChipComponent';

const useStyle = makeStyles({
    chipHolder: {
        marginTop: '16px',
        display: 'flex',
        // justifyContent:'space-between'
      },
      holder: {
        marginTop: '8px',
      },
      holder_single: {
        margin: '0 !important',
        display: 'flex',
        '&>:nth-child(1)': {
          fontWeight: 600,
        },
      },
})

function ViewNotes(props) {
   const classes = useStyle()
    return (
        <Box style={{margin: '10px 0 20px 0' , padding: '0 10px'}}>
        <Typography variant="h6"><b>Program Focus</b></Typography>
        <Typography variant='body1'><b>Get Active, Sleep Improvement, Nutrition</b></Typography>
        <Box style={{marginTop: '10px'}}>
          <Grid container className={styles.tableRowHeading} spacing={2}>
            {props.sessionLogsViewNotes.map((item, index) => (
              <Grid item lg={4} md={4} style={{ marginBottom: 0 }} key={index}>
                <Typography variant="body1"><b>{item.program}</b></Typography>
                <Box className={classes.chipHolder}>
                  <ChipComponent
                    variant="outlined"
                    size="small"
                    label={'Preparation'}
                    color= "primary"
                    style={{
                      // marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                      fontSize: `${fontSizes.SMALL}`,
                    }}
                  />
                  <ChipComponent
                    variant="outlined"
                    size="small"
                    label={'Not met'}
                    color="secondary"
                    style={{
                      marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                      fontSize: `${fontSizes.SMALL}`,
                    }}
                  />
                </Box>
                <Box className={classes.holder}>
                  <Typography variant="body2">Current: ({item.current})</Typography>
                  <Typography variant="body2">
                    Target: ({item.target})
                  </Typography>
                </Box>
                <Box className={classes.holder}>
                  <Grid container>
                    <Grid item xs={12} className={classes.holder_single}>
                      <Typography variant="body2">Habit:</Typography>
                      <Typography variant="body2">{item.habit}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.holder_single}>
                      <Typography variant="body2">Frequency:</Typography>
                      <Typography variant="body2">{item.frequency}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.holder_single}>
                      <Typography variant="body2">Duration:</Typography>
                      <Typography variant="body2">{item.duration}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}            
          </Grid>
        </Box>
      </Box>
    )
}

export default ViewNotes
