import { getToken } from '../../Auth/AuthUtilities';
import { TeleKardiaAPI } from '../../Axios/axios';
import { AUTH_TYPES } from '../../constants/app';
import { fetchApi } from '../../Utilities/ApiUrls';
import { getFullJid } from '../../Utilities/Utilities';
import * as actionTypes from './actionTypes';

export const getChatUsersStart = () => ({
  type: actionTypes.GET_CHAT_USERS_START,
});

export const getChatUsers = (chatData) => ({
  type: actionTypes.GET_CHAT_USERS_SUCCESS,
  payload: { data: chatData },
});

export const setChatLoader = (data) => ({
  type: actionTypes.SET_CHAT_LOADER,
  payload: data,
});

export const uploadFileStart = () => ({
  type: actionTypes.UPLOAD_FILE_START,
});

export const uploadFileSuccess = (data) => ({
  type: actionTypes.UPLOAD_FILE_SUCCESS,
  payload: data,
});

export const uploadFileFail = (error) => ({
  type: actionTypes.UPLOAD_FILE_FAIL,
  payload: error,
});

export const getLastMessageChatUser = (getHistory) => (dispatch, getState) => {
  const chatUserData = getState().chat.data;
  const userChatCreds = getState().user.chatProfile;
  chatUserData.map((d, index) => {
    let loader = `loader${d.chatUserId}`;
    dispatch(setChatLoader({ key: loader, value: true }));
    const callbackFn = () => {
      dispatch(setChatLoader({ key: loader, value: false }));
    };
    getHistory(getFullJid(userChatCreds.kccChatUserID), getFullJid(d.chatUserId), 1, callbackFn);
  });
};
export const getChatUserAction = (profileId) => (dispatch, getState) => {
  dispatch(getChatUsersStart());
  TeleKardiaAPI.get(`/api/v1/chat/userInfo/${profileId}`, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let resultData = res.data.result;
      dispatch(getChatUsers(resultData));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadFileToS3 = (res) => {
  const { uri, data } = res || {};
  console.log('data', data);
  const formData = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      formData.append(key, data[key]);
    }
  }
  return fetchApi({
    url: uri,
    authType: AUTH_TYPES.NONE,
    method: 'POST',
    body: formData,
    header: {
      'Content-Type': 'multipart/form-data;',
    },
  });
};

export const getS3BucketData = (profileId, file) => (dispatch, getState) => {
  const { type } = file;
  dispatch(uploadFileStart());
  TeleKardiaAPI.get(`api/v1/chat/uploadPolicy/${profileId}`, {
    headers: { Authorization: ` Bearer ${getToken()}` },
    params: { mimeType: type },
  })
    .then((res) => {
      let result = res?.data?.result || {};
      const { data } = result || {};
      const decodedData = atob(data);
      const s3BucketData = decodedData ? JSON.parse(decodedData) : {};
      const { bucketName, policy, signature, accessKey, fileName, keyPrefix, mimeType } =
        s3BucketData;
      const dataToSend = {
        key: fileName,
        acl: 'public-read',
        'Content-Type': mimeType,
        AWSAccessKeyId: accessKey,
        policy: policy,
        signature: signature,
        success_action_status: '201',
        'x-amz-meta-uuid': '14365123651274',
        'x-amz-meta-tag': '',
        file: file,
      };

      return { ...result, data: dataToSend };
    })
    .then((res) => dispatch(uploadFileToS3(res)))
    .then((res) => res.json())
    .then((res) => console.log(res))
    .catch((err) => {
      console.log(err);
      dispatch(uploadFileFail(err));
    });
};
