import React, { useEffect, useState } from 'react';
import { FormControl, Grid, Select as MuiSelect, MenuItem } from '@material-ui/core';
import { Box, Typography, Tabs, Tab, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { fontSizes, spacing } from '../../../../style/styleSettings';
import ChipComponent from '../../../../Components/Dashboard/SessionComponents/ChipComponent';
import styles from '../../memberProfile.module.css';
import classnames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { commonStyles, navClasses, navStyles } from '../../styles';
import { common } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';

import { GoAPI } from '../../../../Axios/axios';
import { getToken } from '../../../../Auth/AuthUtilities';
import { useParams } from 'react-router';
import { getMemberProfileDetails } from '../../../../Store/actions/memberProfile';
import moment from 'moment';
import TeamAlert from '../../../../Components/Shared/Alert/TeamAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-root': {
      fontFamily: 'Work Sans',
    },
    '& .MuiSelect-select': {
      '& :focus': {
        backgroundColor: 'white',
      },
    },
    '& .Mui-selected': {
      backgroundColor: '#F4FAF8 !important',
      '&:hover': {
        backgroundColor: 'green !important',
      },
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Work Sans !important',
    },
  },
  divWrapperOverride: {
    paddingBottom: '32px',
  },
  divHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '20px',
      '& .MuiTypography-body2': {
        fontWeight: 600,
        marginLeft: '20px',
      },
    },
  },
  chipHolder: {
    marginTop: '16px',
    display: 'flex',
    // justifyContent:'space-between'
  },
  holder: {
    marginTop: '8px',
  },
  holder_single: {
    margin: '0 !important',
    display: 'flex',
    '&>:nth-child(1)': {
      fontWeight: 600,
    },
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  weekController: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  formControl: {
    minWidth: 120,
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Program = (props) => {
  const { memberId } = useParams();
  const coachId = useSelector((state) => state.user.profile.id);
  const [profile, setProfile] = useState(null);
  const [coachList, setCoachList] = useState([]);
  const [isCoachEditing, setIsCoachEditing] = useState(false);
  const [isRiskEditing, setIsRiskEditing] = useState(false);
  const me = useSelector((state) => state.user.profile);
  const { data, loading, error } = useSelector((state) => state.memberProfile);
  const isAdmin = me.permissions ? me.permissions.isAdmin : false;
  const isCoach = me.permissions ? me.permissions.isPhysician : false;
  console.log(isAdmin, isCoach);
  const { t } = useTranslation();

  //importing styles
  const classes = useStyles();
  const navClasses = navStyles();
  const commonClasses = commonStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchCoachList = async () => {
    GoAPI.get(`/i/v1/kcc/coach`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
    })
      .then((res) => {
        let resultData = res.data;
        console.log(resultData);
        let options = resultData
          .filter((d) => d.coachID !== coachId)
          .map((d) => {
            return {
              value: d.coachID,
              label: `${d.coachLastName}, ${d.coachFirstName}`,
            };
          });
        setCoachList(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchCoachList();
  }, []);
  useEffect(() => {
    if (data) {
      setProfile(data);
    }
  }, [data]);
  const [showAlert, setShowAlert] = useState(false);
  const reassignCoachAsync = (val) => {
    console.log(memberId);
    console.log(val);
    GoAPI.post(
      '/i/v1/kcc/reassignMember',
      { coachID: val, participantID: memberId },
      { headers: { Authorization: `Bearer ${getToken()}` } },
    )
      .then((res) => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 4000);
      })
      .catch((err) => {
        throw err;
      });
  };
  console.log(profile);
  return (
    <div className={classes.root}>
      <Box style={{ marginTop: '10px' }}>
        {showAlert && <TeamAlert title={'Changes successfully saved'} color={'#E7F5E9'} />}
      </Box>
      <Box
        className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}
        style={{ marginTop: 0 }}
      >
        <Typography variant="h5">Member Demographics</Typography>
        <Box className={commonClasses.divContentWrapper}>
          <Grid container className={styles.tableRowHeading}>
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Date of Birth</Typography>
              <Typography variant="body1">
                {' '}
                {data.participantDateOfBirth
                  ? moment(data.participantDateOfBirth).format('MM/DD/YYYY')
                  : ''}
              </Typography>
            </Grid>
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Sex</Typography>
              <Typography style={{ color: 'grey' }}>No data</Typography>
            </Grid>
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Race</Typography>
              <Typography style={{ color: 'grey' }}>No data</Typography>
            </Grid>
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Phone</Typography>
              <Typography variant="body1">{data.ParticipantPhoneNumber}</Typography>
            </Grid>
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Company name</Typography>
              <Typography variant="body1">{data.sponsor}</Typography>
            </Grid>
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Email</Typography>
              <Typography variant="body1">{data.participantEmail}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
        <Box className={classes.divHeaderWrapper}>
          <Typography variant="h5">Program Overview</Typography>
          <Box className={classes.divHeaderWrapper_status}>
            {/* cannot find icon for this, color is subject to change */}
            <div
              style={{ background: '#CC3D3F', width: '20px', height: '20px', borderRadius: '50%' }}
            />
            <Typography variant="body2">Inactive</Typography>
          </Box>
        </Box>
        <Box className={commonClasses.divContentWrapper}>
          <Grid container className={styles.tableRowHeading}>
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Program</Typography>
              <Typography variant="body1">
                {data.programList ? (
                  data.programList.join('/')
                ) : (
                  <span style={{ color: 'grey' }}>No data</span>
                )}
              </Typography>
            </Grid>
            {isCoach && !isAdmin && (
              <Grid item lg={4} md={4}>
                <Typography variant="body2">Current week</Typography>
                <Typography variant="body1">TODO</Typography>
              </Grid>
            )}
            {isAdmin && (
              <Grid item lg={4} md={4}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">Coach</Typography>
                  <EditIcon
                    style={{ marginLeft: '15px', fontSize: 15 }}
                    onClick={() => setIsCoachEditing(true)}
                  />
                </Box>
                {isCoachEditing ? (
                  <FormControl className={classes.formControl} variant="outlined">
                    <MuiSelect
                      id="demo-simple-select2"
                      defaultValue={data.coachId}
                      // value={data.coachId}
                      onChange={(e) => reassignCoachAsync(e.target.value)}
                      placeholder="Select Coach"
                    >
                      {coachList.map((c, index) => (
                        <MenuItem value={c.value}>{c.label}</MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                ) : (
                  <Typography variant="body1">
                    {data.coachFirstName
                      ? `${data.coachLastName}, ${data.coachFirstName}`
                      : 'No Coach assigned'}
                  </Typography>
                )}
              </Grid>
            )}
            <Grid item lg={4} md={4}>
              <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography variant="body2">Risk</Typography>
                <EditIcon
                  style={{ marginLeft: '15px', fontSize: 15 }}
                  onClick={() => setIsRiskEditing(true)}
                />
              </Box>
              {/* @todo 
                1. add icon for risk
                add input select 
                 */}
              {isRiskEditing ? (
                <FormControl variant="outlined" className={classes.formControl}>
                  <MuiSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={row[FIELDS.STAGEOFCHANGE] || ''}
                    // onChange={(e) =>
                    //   tableEditingHandler(row.id, e.target.value, FIELDS.STAGEOFCHANGE)
                    // }
                    placeholder="Select"
                  >
                    <MenuItem value={'Low'}>Low</MenuItem>
                    <MenuItem value={'Medium'}>Medium</MenuItem>
                    <MenuItem value={'High'}>High</MenuItem>
                  </MuiSelect>
                </FormControl>
              ) : (
                <Typography variant="body1" style={{ color: 'grey' }}>
                  No data
                </Typography>
              )}
            </Grid>
            {isAdmin && (
              <Grid item lg={4} md={4}>
                <Typography variant="body2">Current Week</Typography>
                <Typography variant="body1" style={{ color: 'grey' }}>
                  No data
                </Typography>
              </Grid>
            )}
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Enrolled on</Typography>
              <Typography variant="body1" style={{ color: 'grey' }}>
                No data
              </Typography>
            </Grid>
            {isCoach && !isAdmin && (
              <Grid item lg={4} md={4}>
                <Typography variant="body2">Last login</Typography>
                <Typography variant="body1">
                  {moment(data.lastLogin).format('MM/DD/YYYY hh:mm A')}
                </Typography>
              </Grid>
            )}
            <Grid item lg={4} md={4}>
              <Typography variant="body2">Next coaching session</Typography>
              <Typography variant="body1">
                {data.nextSession ? (
                  data.nextSession
                ) : (
                  <span style={{ color: 'gray' }}>No data</span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classnames(commonClasses.divWrapper, classes.divWrapperOverride)}>
        <Typography variant="h5">Program Focus</Typography>
        <Box className={commonClasses.divContentWrapper}>
          <Grid container className={styles.tableRowHeading}>
            <Grid item lg={4} md={4} style={{ marginBottom: 0 }}>
              <Typography variant="body1">Get Active</Typography>
              <Box className={classes.chipHolder}>
                <ChipComponent
                  variant="outlined"
                  size="small"
                  label={'Preparation'}
                  color="primary"
                  style={{
                    // marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                    fontSize: `${fontSizes.SMALL}`,
                  }}
                />
                <ChipComponent
                  variant="outlined"
                  size="small"
                  label={'Not met'}
                  color="primary"
                  style={{
                    marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                    fontSize: `${fontSizes.SMALL}`,
                  }}
                />
              </Box>
              <Box className={classes.holder}>
                <Typography variant="body2">Current: (I exercise 20 per week)</Typography>
                <Typography variant="body2">
                  Target: (I will do 30 mins of exercise per week)
                </Typography>
              </Box>
              <Box className={classes.holder}>
                <Grid container>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Habit:</Typography>
                    <Typography variant="body2">I will play more tennis</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Frequency:</Typography>
                    <Typography variant="body2">Weekly (M, W, F, Sa)</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Duration:</Typography>
                    <Typography variant="body2">10/31/2020 - 12/31/2020</Typography>
                  </Grid>
                  <div className={commonClasses.overlay}>Coming Soon!</div>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} style={{ marginBottom: 0 }}>
              <Typography variant="body1">Get Active</Typography>
              <Box className={classes.chipHolder}>
                <ChipComponent
                  variant="outlined"
                  size="small"
                  label={'Preparation'}
                  color="primary"
                  style={{
                    // marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                    fontSize: `${fontSizes.SMALL}`,
                  }}
                />
                <ChipComponent
                  variant="outlined"
                  size="small"
                  label={'Not met'}
                  color="primary"
                  style={{
                    marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                    fontSize: `${fontSizes.SMALL}`,
                  }}
                />
              </Box>
              <Box className={classes.holder}>
                <Typography variant="body2">Current: (I exercise 20 per week)</Typography>
                <Typography variant="body2">
                  Target: (I will do 30 mins of exercise per week)
                </Typography>
              </Box>
              <Box className={classes.holder}>
                <Grid container>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Habit:</Typography>
                    <Typography variant="body2">I will play more tennis</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Frequency:</Typography>
                    <Typography variant="body2">Weekly (M, W, F, Sa)</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Duration:</Typography>
                    <Typography variant="body2">10/31/2020 - 12/31/2020</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} style={{ marginBottom: 0 }}>
              <Typography variant="body1">Get Active</Typography>
              <Box className={classes.chipHolder}>
                <ChipComponent
                  variant="outlined"
                  size="small"
                  label={'Preparation'}
                  color="primary"
                  style={{
                    // marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                    fontSize: `${fontSizes.SMALL}`,
                  }}
                />
                <ChipComponent
                  variant="outlined"
                  size="small"
                  label={'Not met'}
                  color="primary"
                  style={{
                    marginLeft: `${spacing.SESSION_MARGIN_LARGE}`,
                    fontSize: `${fontSizes.SMALL}`,
                  }}
                />
              </Box>
              <Box className={classes.holder}>
                <Typography variant="body2">Current: (I exercise 20 per week)</Typography>
                <Typography variant="body2">
                  Target: (I will do 30 mins of exercise per week)
                </Typography>
              </Box>
              <Box className={classes.holder}>
                <Grid container>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Habit:</Typography>
                    <Typography variant="body2">I will play more tennis</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Frequency:</Typography>
                    <Typography variant="body2">Weekly (M, W, F, Sa)</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.holder_single}>
                    <Typography variant="body2">Duration:</Typography>
                    <Typography variant="body2">10/31/2020 - 12/31/2020</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={classnames(commonClasses.divWrapper)}>
        <Box className={classes.flexWrapper}>
          <Typography variant="h5">Program Progress</Typography>
          <Tabs
            classes={{
              root: navClasses.customTabRoot,
              indicator: navClasses.customTabIndicator,
            }}
            value={value}
            onChange={handleChange}
            scrollButtons="auto"
            // centered
            // variant="fullWidth"
          >
            <Tab label={t('Weekly')} classes={{ root: classes.tabRoot }} {...a11yProps(0)} />
            <Tab label={t('Overall')} classes={{ root: classes.tabRoot }} {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Box style={{ width: '100%' }}>
            {/* week controller comes here */}
            <Grid container className={classes.weekController}>
              <Grid item lg={1} md={1}>
                <ChevronLeft />
              </Grid>
              <Grid item lg={2} md={2}>
                <Typography variant="h6">Week 1 of 20</Typography>
                <Typography variant="body2">Oct 24-30,2021</Typography>
              </Grid>
              <Grid item lg={1} md={1}>
                <ChevronRight />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography variant="h6">Member Tasks</Typography>
            <Grid container className={styles.tableRowHeading}>
              {Array(6)
                .fill({})
                .map((idx) => (
                  <Grid item lg={4} md={4} key={idx}>
                    <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <CheckCircleIcon style={{ marginTop: '5px' }} />
                      <Box style={{ marginLeft: '18px' }}>
                        <Typography variant="h6">EKG</Typography>
                        <Typography variant="body2">Record once per week</Typography>
                        <Typography variant="body2">1 of 1 times this week</Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
          <Box>
            <Typography variant="h6">Learn</Typography>
            <Grid container className={styles.tableRowHeading}>
              {Array(1)
                .fill({})
                .map((idx) => (
                  <Grid item lg={4} md={4} key={idx}>
                    <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <CheckCircleIcon style={{ marginTop: '5px' }} />
                      <Box style={{ marginLeft: '18px' }}>
                        <Typography variant="h6">EKG</Typography>
                        <Typography variant="body2">Record once per week</Typography>
                        <Typography variant="body2">1 of 1 times this week</Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          Need to show all week results
        </TabPanel>
        <div className={commonClasses.overlay}>Coming Soon!</div>
      </Box>
    </div>
  );
};

export default Program;
