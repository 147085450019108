import React from 'react';
import styles from './chat.module.css';

const ListItem = (props) => {
  const { item, selected, unreadMsgCount, onClick } = props;
  const { name, chatUserId } = item;

  return (
    <div className={`${styles.listRow} ${selected ? styles.selectedRow : ''}`} onClick={onClick}>
      <div className={styles.listRowItem}>
        <div
          style={{
            fontWeight: !!unreadMsgCount[chatUserId] ? 'bold' : 'unset',
            fontSize: '14px',
            fontFamily: 'Work Sans',
          }}
        >
          {name || item.chatUserId}
        </div>
        {!!unreadMsgCount[chatUserId] && (
          <div className={styles.count}>{unreadMsgCount[chatUserId]}</div>
        )}
      </div>
    </div>
  );
};
export default ListItem;
