import { Grid } from '@material-ui/core';
import React from 'react';
import DashboardCard from './Card';
import MailIcon from '@material-ui/icons/Mail';
import EventNoteIcon from '@material-ui/icons/EventNote';

const CardContainer = () => {
  return (
    <Grid container spacing={2} style={{ marginBottom: '24px' }}>
      <Grid item xs={6}>
        <DashboardCard
          title="New messages"
          content="4"
          icon={true}
          iconFetch={() => <MailIcon />}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardCard
          title="New surveys"
          content="2"
          icon={true}
          iconFetch={() => <EventNoteIcon />}
        />
      </Grid>
    </Grid>
  );
};

export default CardContainer;
