/* eslint-disable */
import axios from 'axios';
import { clearToken } from '../Auth/AuthUtilities';
import { ROUTES } from '../Components/chart/Constants';

export const GoAPI = axios.create({
  baseURL: process.env.REACT_APP_GOKARDIA_API_BASE,
});

export const TeleKardiaAPI = axios.create({
  baseURL: process.env.REACT_APP_TELEKARDIA_API_BASE,
});

export const ResponseCallback = (res) => res;

export const RequestCallback = (req) => {
  // console.log(req.headers.Authorization.split(' ')[1])
  if (req.headers.Authorization.split(' ')[1] != 'null') {
    return req;
  } else {
    console.log('cancelling');
    return false;
  }
};

export const ErrorCallback = (err) => {
  if (err.response?.status === 401) {
    clearToken();
    // if (window.location.pathname !== ROUTES.LOGIN) window.location.replace(ROUTES.LOGIN);
    throw new Error('Unauthorized');
  }
  throw err;
};

// Add a response interceptor
GoAPI.interceptors.request.use(RequestCallback, ErrorCallback);
GoAPI.interceptors.response.use(ResponseCallback, ErrorCallback);
TeleKardiaAPI.interceptors.response.use(ResponseCallback, ErrorCallback);
