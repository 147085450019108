/* eslint-disable */
import React from 'react';

import ContentHeader from '../ContentHeader';
import {
  HEADER_HEIGHT,
  BOTTOM_PADDING,
  getEmailFrequencyOptions,
  getEmailFrequencyOptionDisplayText,
  EMAIL_FREQUENCY_TYPES,
  ROUTES,
} from '../../constants/app';
import EcgLoader from '../Shared/EcgLoader';
import styles from './TeamMemberFile.module.css';
import { getName } from '../../Utilities/Utilities';
import { EditMemberForm } from '../Forms';
import { needToBustCache } from '../../Store/reducers/app';
import { checkReferralsOnlyFeatureEnabled, checkTKFeatureEnabled } from '../../configs';
import { Container } from '@material-ui/core';

class TeamMemberFile extends React.Component {
  state = {
    innerHeight: 0,
    innerWidth: 0,
  };

  UNSAFE_componentWillMount() {
    const {
      match,
      dataLastUpdated,
      setInitialTeamMemberData,
      getTeamMemberFileData,
      getAppointmentNotificationsSettings,
    } = this.props;
    setInitialTeamMemberData(match);
    getTeamMemberFileData(needToBustCache(dataLastUpdated));
    getAppointmentNotificationsSettings();

    window.addEventListener('resize', this.handleResize);
    this.setState({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
  };

  render() {
    const {
      dataIsFetching,
      dataLastUpdated,
      getTeamMemberFileData,
      handleOnEditTeamMemberSubmit,
      handleOnTeamMemberDeleteClick,

      selectedTeamMemberId,
      physicians,
      editMemberFormData,
      me,
      notification,
      handleAppointmentNotificationsChange,
    } = this.props;

    const { innerHeight, innerWidth } = this.state;

    const height = innerHeight - BOTTOM_PADDING;
    const width = 1180 - 20 * 2;

    const cleanValues = (value) => {
      console.log('value', value);

      const region = value && value.regions && value.regions[0];
      const smallArray = value && value.regions && [...value.regions];
      let memberArray;

      if (smallArray && smallArray.length > 1) {
        const arrayChange = smallArray.shift();
        memberArray = smallArray.map((item, i) => ({
          country: 'US',
          region: item,
        }));
      }

      // const region = regionRemovefromArray && regionRemovefromArray.shift()
      // console.log('value2', regionRemovefromArray)
      // console.log('value3', region)

      // const memberArray = value && value.region && value.regions.map()
      const newReminders = {
        emailFrequency: {
          label: getEmailFrequencyOptionDisplayText(value.reminders.emailFrequency),
          id: value.reminders.emailFrequency,
        },
        personalReminder: value.reminders.personalReminder,
        triageReminder: value.reminders.triageReminder,
        personalEsignReportReminder: value.reminders.personalEsignReportReminder,
        teamEsignReportReminder: value.reminders.teamEsignReportReminder,
        teamExportReportReminder: value.reminders.teamExportReportReminder,
      };
      const countryTem = {
        region,
        members: memberArray,
        // members: [ {
        //   Country: 'US',
        //   Region: 'NEVEDA1',
        // } ],
      };

      const tempValue = { ...countryTem, ...value };
      const newProfile = {
        ...tempValue,
        reminders: newReminders,
      };
      return newProfile;
    };

    let physician;
    let initialValues;
    if (
      physicians.data &&
      physicians.data.byId[selectedTeamMemberId] &&
      physicians.data.byId[selectedTeamMemberId].reminders &&
      !dataIsFetching
    ) {
      physician = physicians.data.byId[selectedTeamMemberId];
      initialValues = cleanValues(physician);
    }

    const myId = me.data && me.data.profile.id;

    // common array for all selected country and region.
    let allCountryRegionArray = [];

    // getting values from counrty and region field from teh form.
    if (editMemberFormData && editMemberFormData.region) {
      allCountryRegionArray.push({
        country: editMemberFormData.country || '',
        region: editMemberFormData.region,
      });
    }

    // geeting values form the members array and combine to the allCountryRegionArray
    if (editMemberFormData && editMemberFormData.members && editMemberFormData.members.length > 0) {
      allCountryRegionArray = [...allCountryRegionArray, ...editMemberFormData.members];
    }

    return (
      <Container maxWidth="xl" data-page="team-member-file">
        <ContentHeader
          goBackRoute={`${ROUTES.SETTINGS}?tab=2`}
          isLoading={dataIsFetching}
          lastUpdated={dataLastUpdated}
          handleOnRefreshClick={() => getTeamMemberFileData(true)}
          text={physician && getName(physician.firstName, physician.lastName, '')}
          enableBackButton={true}
          showAddPatient={false}
          me={me}
        />
        <div
          className={styles.formWrapper}
          style={{
            height,
          }}
        >
          {!dataIsFetching ? (
            <div className={styles.formTop}>
              <h2 className={styles.formTitle}>Coach Information</h2>
              <EditMemberForm
                onSubmit={handleOnEditTeamMemberSubmit}
                initialValues={initialValues}
                isSubmitting={dataIsFetching}
                handleOnTeamMemberDeleteClick={handleOnTeamMemberDeleteClick}
                editMemberFormData={editMemberFormData}
                allCountryRegionArray={allCountryRegionArray}
                isMe={myId === selectedTeamMemberId}
                showEmailReminder={!checkReferralsOnlyFeatureEnabled(me)}
                showTKFeatureEnabled={checkTKFeatureEnabled(me)}
                isAdminEnabled={me && me.data && me.data.profile.permissions.isAdmin}
                notification={notification}
                handleAppointmentNotificationsChange={handleAppointmentNotificationsChange}
              />
            </div>
          ) : (
            <EcgLoader />
          )}
        </div>
      </Container>
    );
  }
}

export default TeamMemberFile;
