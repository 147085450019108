import React, { useState, useContext, useEffect, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styles from './chat.module.css';
import { Strophe } from 'strophe.js';
import { Chat } from '../../providers';
import ListItem from './ListItem';
import AsyncChat from '../../Components/Chat/AsyncChat';
import EcgLoader from '../../Components/Shared/EcgLoader';
import { Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ChatInboxDetails = (props) => {
  const { t } = useTranslation();
  const { chatUserId } = props.match.params || {};
  const [memberList, setMemberList] = useState([]);
  const [memberName, setMemberName] = useState('');
  const { status, allMessages, unreadMsgCount } = useContext(Chat.Context);
  const { profileId, chat, getChatUserAction, getS3BucketData } = props || {};
  const { data, loading } = chat || {};
  const length = data?.length;

  useEffect(() => {
    if (status === Strophe.Status.CONNECTED && !length) {
      getChatUserAction(profileId);
    }
  }, [status, profileId, length]);

  useEffect(() => {
    if (data.length) {
      setMemberList(data);
    } else {
      setMemberList([]);
    }
    setMemberName(getName(data, chatUserId));
  }, [data, chatUserId]);

  const getName = useCallback((data, chatUserId) => {
    const { name } = data.length && data.find((member) => member.chatUserId === chatUserId);
    return name || chatUserId;
  }, []);

  return (
    <Container maxWidth="xl">
      {loading ? (
        <EcgLoader />
      ) : (
        <>
          <Paper className={styles.tableContainer}>
            <Grid container className={styles.tableRowHeading}>
              <Grid item lg={3} md={4}>
                {t('Member')}
              </Grid>
              <Grid item lg={9} md={8}>
                {memberName}
              </Grid>
            </Grid>
            {memberList.length ? (
              <Grid container>
                <Grid item lg={3} md={4} className={styles.tableBody}>
                  {!!memberList.length
                    ? memberList.map((row, index) => (
                        <ListItem
                          onClick={() => props.history.push(`/chat-inbox/${row.chatUserId}`)}
                          key={index}
                          item={row}
                          selected={row.chatUserId === chatUserId}
                          allMessages={allMessages}
                          unreadMsgCount={unreadMsgCount}
                          chat={chat}
                        />
                      ))
                    : null}
                </Grid>
                <Grid item lg={9} md={8} style={{ borderLeft: '1px solid #e9e9e9' }}>
                  {chatUserId && (
                    <AsyncChat chatUserId={chatUserId} getS3BucketData={getS3BucketData} />
                  )}
                </Grid>
              </Grid>
            ) : (
              <div className={styles.notFound}>
                <Typography variant="h6">{t('No members found')}</Typography>
              </div>
            )}
          </Paper>
        </>
      )}
    </Container>
  );
};
export default ChatInboxDetails;
