import { useState, useContext, useEffect, useCallback } from 'react';
import { Strophe, $pres, $msg } from 'strophe.js';
import { Chat } from '../providers';
import _ from 'lodash';
import moment from 'moment';

const useStrophe = (chatUserId) => {
  const {
    status,
    recStatus,
    typing,
    allMessages,
    connection,
    currentAppointmentUser,
    fetchAllChatHistory,
    log,
    setUnreadMsgCount,
  } = useContext(Chat.Context);
  const [messages, setMessages] = useState({});
  const [chatFirstMessage, setChatFirstMessage] = useState();
  const [fetchingHistory, setFetchingHistory] = useState(false);
  const myMessage = allMessages && allMessages[chatUserId];

  useEffect(() => {
    setChatFirstMessage();
    setMessages({});
  }, [chatUserId]);

  useEffect(() => {
    if (myMessage && !myMessage.isOwn) {
      setMessages((prev) => messageGroupByDay(prev, myMessage));
    }
    setUnreadMsgCount((prev) => ({ ...prev, [chatUserId]: 0 })); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myMessage, chatUserId]);

  const messageGroupByDay = (prevMessages, currentMessage) => {
    let timeStampKey = moment(new Date(currentMessage['time'])).startOf('day');
    return {
      ...prevMessages,
      [timeStampKey]: prevMessages[timeStampKey]
        ? [...prevMessages[timeStampKey], currentMessage]
        : [currentMessage],
    };
  };

  const convertMessageXMLtoJson = (message) => {
    let messageDetails = message.getElementsByTagName('message')[0];
    let from = messageDetails.getAttribute('from');
    let messageText = message.getElementsByTagName('body')[0]?.textContent;
    let msgId = messageDetails.getAttribute('id');
    let timestamp = messageDetails.getElementsByTagName('stanza-id')[0].getAttribute('id');
    return {
      source: from,
      text: messageText.toString(),
      time: timestamp / 1000,
      id: msgId,
      status: 2,
    };
  };

  /**
   * Fetch chat history based on user id
   * @param {*} jid - your id
   * @param {*} withJid - to whom you want chat history user id
   * @param {*} messageCount - no. of messages
   * @param {*} callback
   */
  const getHistory = useCallback(
    (jid, filters, list, callback) => {
      let localMsg = {};
      let localChatFirstMessage = null;
      let isPagination = !!filters?.after;
      setFetchingHistory(true);
      connection.mam.query(Strophe.getBareJidFromJid(jid), {
        max: 50,
        ...filters,
        onMessage: function (message) {
          const messageObj = convertMessageXMLtoJson(message);
          localChatFirstMessage = localChatFirstMessage
            ? localChatFirstMessage
            : messageObj?.id
            ? messageObj
            : null;
          if (!list) {
            localMsg = messageGroupByDay(localMsg, messageObj);
          } else {
            callback(messageObj.text);
            return messageObj.text;
          }
          return true;
        },
        onComplete: function (response) {
          let isPaginationComplete = response
            .getElementsByTagName('fin')[0]
            .getAttribute('complete');
          setMessages((prev) =>
            isPagination ? { ...localMsg, ...prev } : { ...prev, ...localMsg },
          );
          if (isPaginationComplete === 'false' && localChatFirstMessage) {
            setChatFirstMessage(localChatFirstMessage);
          }
          setFetchingHistory(false);
        },
      });
    },
    [connection],
  );

  /**
   * Send message
   * @param {*} msg  - content you want to send
   * @param {*} senderId - sender id
   * @param {*} recieverId - receiver Id
   */
  async function sendMessage(msg, senderId, recieverId) {
    var m = $msg({
      to: recieverId,
      from: senderId,
      type: 'chat',
    })
      .c('body')
      .t(msg);
    // return connection.send(m); //normal send
    const msgId = connection.receipts.sendMessage(m);
    //update dashboard all messages
    log(recieverId + ':' + msg + ':' + Date.now(), true); //this is just for dashboard
    setMessages((prev) =>
      messageGroupByDay(prev, {
        source: senderId,
        text: msg,
        time: Date.now(),
        id: msgId,
        status: 'Sent',
      }),
    );
  }
  //========================Not used now=======================

  const getPrescence = useCallback(
    (jid) => {
      var check = $pres({
        type: 'probe',
        to: jid,
      });
      connection.send(check);
    },
    [connection],
  );

  return {
    status,
    messages,
    chatFirstMessage,
    typing,
    recStatus,
    currentAppointmentUser,
    sendMessage,
    getHistory,
    getPrescence,
    fetchingHistory,
    fetchAllChatHistory,
  };
};

export default useStrophe;
