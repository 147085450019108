/* eslint-disable */
import * as action_types from './actionTypes';
import * as API from '../../Services/API/actions';
import { GoAPI } from '../../Axios/axios';
import {
  clearIntervalAuthenticationChecker,
  clearToken,
  getToken,
  intervalAuthenticationChecker,
  setToken,
} from '../../Auth/AuthUtilities';
import * as Constants from '../../Components/chart/Constants';
// import { getMe } from '../reducers/app'
import { DATASOURCES, getDateTimeFormat } from '../../constants/app';
import moment from 'moment';
import history from '../../Components/History';
import { dataSourceSuccess } from '../reducers/app';
import { ProviderStatus } from '../../Utilities/Constants';

export const user_logout = () => {
  return (dispatch) => {
    window.replace = '/login';
    dispatch(setAuthentication(false));
    clearIntervalAuthenticationChecker();
    dispatch(getUserSuccess('logOut'));
  };
};

export const getUserStart = (data) => {
  return { type: action_types.GET_USER_START };
};
export const getUserSuccess = (data) => {
  return { type: action_types.GET_USER_SUCCESS, payload: data };
};
export const getUserChatCredsSuccess = (data) => {
  return { type: action_types.GET_USER_CHAT_CREDS, payload: data };
};

export const getUserFail = (error) => {
  return { type: action_types.GET_USER_FAIL, error: error };
};

export const addUser = (data) => {
  return (dispatch) => {
    dispatch(getUserSuccess(data));
  };
};
export const setAuthentication = (token) => {
  if (token) {
    setToken(token);
  } else {
    clearToken();
  }
  return { type: action_types.SET_AUTHENTICATION, payload: !!token };
};
const setLogin = () => {
  return { type: action_types.SET_LOGIN, payload: true };
};
export const fetchRenewJWT = () => (dispatch, getState) => {
  return GoAPI.get('/i/v1/jwt', { headers: { Authorization: `Bearer ${getToken()}` } })
    .then((res) => res.data)
    .then((res) => res.jwt)
    .catch((err) => {
      throw err;
    });
};

export const getjwt = (data) => (dispatch) => {
  const { email, password } = data;
  const basicToken = btoa(unescape(encodeURIComponent(`${email}:${password}`)));
  const getConfig = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Basic ${basicToken}`,
    },
  };
  return GoAPI.get('/i/v1/jwt', getConfig)
    .then((res) => res.data)
    .then((res) => res.jwt)
    .catch((err) => {
      throw err;
    });
};

export const authLogin = (data) => (dispatch, getState) => {
  dispatch(getUserStart());
  dispatch(getjwt(data))
    .then((jwt) => dispatch(setAuthentication(jwt)))
    .then(() => dispatch(setLogin()))
    .then(() => dispatch(intervalAuthenticationChecker()))
    .then(() => dispatch(getUser()))
    .then(() => {
      const { previousLocation } = getState().app;
      if (!previousLocation) {
        history.push('/');
      } else {
        history.push(previousLocation);
      }
      return;
    })
    .catch((err) => {
      dispatch(setAuthentication(false));
      if (err.error && err.status === 403) {
        const { lockoutEndAt } = err.error;
        if (lockoutEndAt) {
          const endDate = moment(lockoutEndAt).format(getDateTimeFormat());
          dispatch(
            getUserFail(
              Constants.LOGIN_ERROR_MESSAGES.LOCKED_WITH_TIME.replace('%datetime', endDate),
            ),
          );
        } else {
          dispatch(getUserFail(`${Constants.LOGIN_ERROR_MESSAGES.LOCKED_OUT}`));
        }
      } else {
        dispatch(getUserFail(Constants.LOGIN_ERROR_MESSAGES.BAD_CREDENTIALS));
      }
    });
};

export const getUser = () => async (dispatch, getState) => {
  const token = localStorage.getItem(Constants.LOCAL_STORAGE_AUTH_TOKEN_KEY);
  GoAPI.get('/i/v1/kcc/connection_info', {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
  })
    .then((res) => {
      dispatch(getUserChatCredsSuccess(res.data));
    })
    .catch((error) => {
      console.log(error);
    });
  GoAPI.get('/i/v1/me', { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      dispatch(dataSourceSuccess(DATASOURCES.ME, res.data));
      return res;
    })
    .then((res) => {
      dispatch(getUserSuccess(res.data));
      const clinicianid = res.data.profile.id;
      const teamId = res.data.teams[0].id;
      let body = {
        onDutyStatus: ProviderStatus.Available,
      };
      API.setProviderStatus(teamId, clinicianid, body).subscribe(
        (res) => {
          // console.log(res);
        },
        (error) => {
          //handle error
        },
      );
      return res;
    })
    .catch((error) => {
      dispatch(user_logout());
      dispatch(getUserFail('Something went wrong!'));
    });
};
