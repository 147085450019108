export const USER_LOGOUT = 'USER_LOGOUT';
export const GET_AVAILABILITY_START = 'GET_AVAILABILITY_START';
export const GET_AVAILABILITY_SUCCESS = 'GET_AVAILABILITY_SUCCESS';
export const GET_AVAILABILITY_FAIL = 'GET_AVAILABILITY_FAIL';

export const ADD_AVAILABILITY_START = 'ADD_AVAILABILITY_START';
export const ADD_AVAILABILITY_SUCCESS = 'ADD_AVAILABILITY_SUCCESS';
export const ADD_AVAILABILITY_FAIL = 'ADD_AVAILABILITY_FAIL';

export const UPDATE_AVAILABILITY_START = 'UPDATE_AVAILABILITY_START';
export const UPDATE_AVAILABILITY_SUCCESS = 'UPDATE_AVAILABILITY_SUCCESS';
export const UPDATE_AVAILABILITY_FAIL = 'UPDATE_AVAILABILITY_FAIL';

export const DELETE_AVAILABILITY_START = 'DELETE_AVAILABILITY_START';
export const DELETE_AVAILABILITY_SUCCESS = 'DELETE_AVAILABILITY_SUCCESS';
export const DELETE_AVAILABILITY_FAIL = 'DELETE_AVAILABILITY_FAIL';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_USER_CHAT_CREDS = 'GET_USER_CHAT_CREDS';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const SET_LOGIN='SET_LOGIN'

export const GET_APPOINTMENTS_START = 'GET_APPOINTMENTS_START';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_FAIL = 'GET_APPOINTMENTS_FAIL';

export const DELETE_APPOINTMENT_START = 'DELETE_APPOINTMENT_START';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAIL = 'DELETE_APPOINTMENT_FAIL';

export const GET_PATIENTDETAILS_START = 'GET_PATIENTDETAILS_START';
export const GET_PATIENTDETAILS_SUCCESS = 'GET_PATIENTDETAILS_SUCCESS';
export const GET_PATIENTDETAILS_FAIL = 'GET_PATIENTDETAILS_FAIL';

export const GET_RECORDING_START = 'GET_RECORDING_START';
export const GET_RECORDING_SUCCESS = 'GET_RECORDING_SUCCESS';
export const GET_RECORDING_FAIL = 'GET_RECORDING_FAIL';

export const GET_CHAT_USERS_START = 'GET_CHAT_USERS_START';
export const GET_CHAT_USERS_SUCCESS = 'GET_CHAT_USERS_SUCCESS';
export const GET_CHAT_USERS_FAIL = 'GET_CHAT_USERS_FAIL';
export const SET_CHAT_LOADER = 'SET_CHAT_LOADER';

export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export const GET_RECORDING_SAMPLE_START = 'GET_RECORDING_SAMPLE_START';
export const GET_RECORDING_SAMPLE_SUCCESS = 'GET_RECORDING_SAMPLE_SUCCESS';
export const GET_RECORDING_SAMPLE_FAIL = 'GET_RECORDING_SAMPLE_FAIL';
export const GET_CLINICIANS_APPOINTMENTS_START = 'GET_CLINICIANS_APPOINTMENTS_START';
export const GET_CLINICIANS_APPOINTMENTS_SUCCESS = 'GET_CLINICIANS_APPOINTMENTS_SUCCESS';
export const GET_CLINICIANS_APPOINTMENTS_FAIL = 'GET_CLINICIANS_APPOINTMENTS_FAIL';

export const GET_CLINICIAN_AVAILABILITY_START = ' GET_CLINICIAN_AVAILABILITY_START';
export const GET_CLINICIAN_AVAILABILITY_SUCCESS = ' GET_CLINICIAN_AVAILABILITY_SUCCESS';
export const GET_CLINICIAN_AVAILABILITY_FAIL = ' GET_CLINICIAN_AVAILABILITY_FAIL';

export const RESET_PATIENT_DETAILS_SUCCESS = ' RESET_PATIENT_DETAILS_SUCCESS';

export const TOGGLE_NAVIGATION_SIDEBAR = `TOGGLE_NAVIGATION_SIDEBAR`;

//==== memberlist ====//
export const GET_MEMBERS_LIST = `GET_MEMBERS_LIST`;
export const GET_MEMBERS_LIST_NEXT_SESSION_DATA = `GET_MEMBERS_LIST_NEXT_SESSION_DATA`;
export const GET_SEARCH_MEMBER = `GET_SEARCH_MEMBER`;
export const SET_FILTER_DATA =  `SET_FILTER_DATA`

//========Member profile=======

export const GET_MEMBER_PROFILE_LOADING=`GET_MEMBER_PROFILE_LOADING`
export const GET_MEMBER_PROFILE_SUCCESS=`GET_MEMBER_PROFILE_SUCCESS`
export const GET_MEMBER_PROFILE_FAIL=`GET_MEMBER_PROFILE_FAIL`

//==== Session Screen =====//
export const GET_SESSION_PATIENT_DETAILS = 'GET_SESSION_PATIENT_DETAILS';
export const GET_PREVIOUS_SESSION_DETAILS = 'GET_PREVIOUS_SESSION_DETAILS';
export const ADD_HEART_HABIT = 'ADD_HEART_HABIT';
export const GET_HEART_HABIT = 'GET_HEART_HABIT';
export const EDIT_HEART_HABIT_LOADING = 'EDIT_HEART_HABIT_LOADING';
export const EDIT_HEART_HABIT = 'EDIT_HEART_HABIT_SUCCESS';
export const EDIT_HEART_HABIT_FAIL = 'EDIT_HEART_HABIT_FAIL';
export const DELETE_HEART_HABIT = 'DELETE_HEART_HABIT';
export const GET_PROGRAM_FOCUS = 'GET_PROGRAM_FOCUS';
export const EDIT_PROGRAM_FOCUS = 'EDIT_PROGRAM_FOCUS';
