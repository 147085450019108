import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Surveys from './SurveysComponent';
import PatientDetails from './PatientDetailsComponent';
import ProgramFocus from './ProgramFocusComponent';
import HeartHabit from './HeartHabit';
import PreviousSession from './PreviousSession';
import { useDispatch, useSelector } from 'react-redux';
import { sessionHeartHabitDetails, sessionProgramFocus, previousSessionDetails, sessionPatientDetails} from '../../../Store/actions/session';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiGrid-item': {
      marginBottom: '24px',
    },
  },
}));
const SessionComponents = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { patientDetails } = props;
  const { patient } = patientDetails;
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);

  //==========data-fetching==========

  useEffect(() => {
    let offsetInMinutes = new Date().getTimezoneOffset();
    if (patient?.patientId !== undefined) {
      dispatch(sessionProgramFocus(teamId, clinicianId, patient?.patientId, props.appointmentId)); //api call for program focus section
      dispatch(previousSessionDetails(teamId, clinicianId, patient?.patientId, offsetInMinutes)); // api call for previous session note section
      // dispatch(sessionPatientDetails(teamId, clinicianId, patient?.patientId, offsetInMinutes)); // api call for member detail section
    }
  }, [patient]);

  //Session heart habit fetch
  useEffect(() => {
    let offsetInMinutes = new Date().getTimezoneOffset();
    if (patient?.patientId !== undefined) {
      dispatch(
        sessionHeartHabitDetails(
          teamId,
          clinicianId,
          patient?.patientId,
          props.appointmentId,
          offsetInMinutes,
        ),
      );
    }
  }, [patient]);
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <PatientDetails {...props} />
      </Grid>
      <Grid item xs={12}>
        <PreviousSession />
      </Grid>
      <Grid item xs={12}>
        <Surveys />
      </Grid>
      <Grid item xs={12}>
        <ProgramFocus {...props} />
      </Grid>
      <Grid item xs={12}>
        <HeartHabit {...props} />
      </Grid>
    </Grid>
  );
};

export default SessionComponents;
