import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { fontSizes, spacing } from '../../../../style/styleSettings';
import classnames from 'classnames';
import { commonStyles, navStyles } from '../../styles';

const useStyles = makeStyles((theme) => ({
    
    divWrapperOverride: {
      paddingBottom: '32px',
    },
    divHeaderWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
        '& .MuiTypography-body2': {
          fontWeight: 600,
          marginLeft: '20px',
        },
      },
    },
    chipHolder: {
      marginTop: '16px',
      display: 'flex',
      // justifyContent:'space-between'
    },
    holder: {
      marginTop: '8px',
    },
    holder_single: {
      margin: '0 !important',
      display: 'flex',
      '&>:nth-child(1)': {
        fontWeight: 600,
      },
    },
    flexWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    weekController: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  }));

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box div={3}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

function Metrics() {
   const { t } = useTranslation();
  const classes = useStyles();
  const navClasses = navStyles();
  const commonClasses = commonStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <Box className={classnames(commonClasses.divWrapper)}>
            <Box className={classes.flexWrapper}>
                <Typography variant="h5">Health Heart Data</Typography>
                <Tabs
                    classes={{
                    root: navClasses.customTabRoot,
                    indicator: navClasses.customTabIndicator,
                    }}
                    value={value}
                    onChange={handleChange}
                    scrollButtons="auto"
                    // centered
                    // variant="fullWidth"
                >
                    <Tab label={t('BLOOD PRESSURE')} classes={{ root: classes.tabRoot }} {...a11yProps(0)} />
                    <Tab label={t('EKG')} classes={{ root: classes.tabRoot }} {...a11yProps(1)} />
                    <Tab label={t('WEIGHT')} classes={{ root: classes.tabRoot }} {...a11yProps(2)} />
                </Tabs>
            </Box>

        <TabPanel value={value} index={0}>
          <Box>
            <Typography variant="h6">BLOOD PRESSURE</Typography>
          </Box>          
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <Typography variant="h6">ECG</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <Typography variant="h6">WEIGHT</Typography>
          </Box>
        </TabPanel>
      </Box>
    )
}

export default Metrics
