import React, { useEffect, useState } from 'react';
import Alert from '../Shared/Alert/AvailabilityAlert';
import * as API from './../../Services/API/actions';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const AppointmentNotes = (props) => {
  const { t } = useTranslation();
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  const [, setloaded] = useState(true);
  const [consultationNote, setconsultationNote] = useState('');
  const [lastconsultationNote, setlastconsultationNote] = React.useState('');
  let subscription;

  useEffect(() => {
    if (props.id) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      subscription = API.getAppointmentNotes(teamId, profileId, props.id).subscribe(
        (res) => {
          if (res.data.appointmentNote) {
            setconsultationNote(decodeURI(res.data.appointmentNote));
            setlastconsultationNote(decodeURI(res.data.appointmentNote));
          } else setconsultationNote('');
        },
        // eslint-disable-next-line no-console
        (error) => console.log(error),
      );
    }

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [props.id]);

  const AUTOSAVE_INTERVAL = 1000;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (lastconsultationNote !== consultationNote) {
        //consultation notes save
        API.setAppointmentNotes(teamId, profileId, props.id, decodeURI(consultationNote)).subscribe(
          (res) => {
            setalert(null);
            setloaded(true);
            // setalert(
            //   <Alert
            //     severity="success"
            //     title="Notes Saved Succesfully"
            //     component="notes"
            //   ></Alert>
            // );
          },
          (error) => {
            setalert(null);
            setloaded(false);
            setalert(
              <Alert
                severity="error"
                title="Unable to process this request due to internal server error"
                component="notes"
              ></Alert>,
            );
          },
        );
        setlastconsultationNote(consultationNote);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultationNote]);

  const handleInputChange = (event) => {
    setconsultationNote(event.target.value);
  };
  const [alert, setalert] = useState();

  return (
    <div>
      <h5 className="heading">{t('My Notes')}</h5>
      <div className="row no-gutters w-100">
        {props.appointments && props.appointments.length > 0 ? (
          <div className="col-12">
            <div className="form-group">
              <textarea
                name="apnotes"
                className="form-control border-0 appt_notes"
                rows="10"
                value={consultationNote}
                placeholder="Write your session notes here. They will not be shared with the member."
                onChange={handleInputChange}
              />
            </div>
            {alert}
            <Box p={1} />
            <div className="d-flex align-items-end justify-content-end">
              {/* <button
                                    type="button"
                                    className="btn btn_teal py-2 px-4" onClick={handleClick} disabled={loaded ? false : true}>
                                    {t("SAVE")}
                                </button> */}
            </div>
          </div>
        ) : (
          <div>
            <p className="mb-0">{t('Notes cannot be added when there are no sessions')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppointmentNotes;
