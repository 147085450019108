import React from 'react'
import styles from '../Login/login.module.css'
// import KardiaProLogo from 'static/images/kardia_pro_logo.svg'
import Loading from '../../Components/Loading'
import CheckmarkIcon from '../../Assets/images/icons/success.svg'
import Logo from '../Login/Logo'

const RequestNewPassword = props => {
  const {
    requestNewPassword: {
      formSubmitted,
      error,
      fetching,
      email,
      valid,
    },
    handleSubmit,
    handleChange,
    handleKeyDown,
  } = props

  const submitIsDisabled = formSubmitted && !valid

  return (
    <div data-page="request-new-password" className={styles.requestNewPassword}>
      <div className={styles.loginOuterCont}>
        <div className={styles.loginBox}>
          <section className={styles.header}>
            <div className={styles.logo}>
              <Logo />
            </div>
          </section>
          <section className={styles.content}>
            <div className={styles.contentInner}>
              {formSubmitted && !fetching && valid ? (
                <div className={styles.preambleSuccess}>
                  <CheckmarkIcon />
                  <h4>We sent you an email with instructions on how to reset your password.</h4>
                  <p>You can close this window</p>
                </div>
              ) : (
                <div>
                  <div className={styles.preamble}>
                    <h4>Reset your password</h4>
                    <p>Enter your account email and we will send you a link to reset your password</p>
                  </div>
                  <div className={styles.inputField}>
                    <input
                      className={styles.defaultTextInput}
                      autoComplete="new-username"
                      id="emailTextField"
                      name="username"
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                  {formSubmitted && !valid && <h2 className={styles.errorMsg}>Bad email, try again</h2>}
                  {error && <h2 className={styles.errorMsg}>{error}</h2>}
                  <div className={styles.requestSubmit}>
                    {fetching ?
                      <button className={styles.disabledButton}>
                        <div className={styles.loginLoading}>
                          <Loading />
                        </div>
                      </button>
                      :
                      <button
                        className={styles.signInButton}
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    }
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default RequestNewPassword
