import { Grid } from '@material-ui/core';
import React from 'react';
import DashboardCard from './Card';

const CardBottomContainer = () => {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DashboardCard title="New Members" content="4" color={'#6BC077'} />
        </Grid>
        <Grid item xs={6}>
          <DashboardCard title="Noncomplaint" content="32" color={'#FDC246'} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DashboardCard title="Unscheduled" content="0" color={'#FDC246'} />
        </Grid>
        <Grid item xs={6}>
          <DashboardCard title="Inactive" content="22" color={'#CC3D3F'} />
        </Grid>
      </Grid>
    </>
  );
};

export default CardBottomContainer;
