import { Base64 } from 'js-base64';

// import { AUTH_TYPES, getRegion } from '../../constants'
import { checkAuthentication, getToken } from '../Auth/AuthUtilities';
import { GoAPI } from '../Axios/axios';
import { setApiServiceError } from '../Store/reducers/app';

// eslint-disable-next-line consistent-return

export const AUTH_TYPES = {
  BASIC: 'BASIC',
  NONE: 'NONE',
  BEARER: 'BEARER',
};

export const getApiBaseUrl = () => {
  let region = 'us';
  region = region !== '' ? `${region}-` : '';
  return `${process.env.REACT_APP_API_PROTOCOL}://${region}${process.env.REACT_APP_API_DOMAIN}`;
};

export const fetchApi =
  ({
    url = '',
    method = 'GET',
    body,
    header = {},
    authType = AUTH_TYPES.NONE,
    credentials = {}, // required for basic auth
  }) =>
  (dispatch, getState) => {
    const { browser } = getState().app;

    const headers = new Headers(
      Object.assign(
        {},
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        header,
      ),
    );

    const { BEARER, BASIC, NONE } = AUTH_TYPES;
    let basicToken;
    const { username = '', password = '' } = credentials;

    switch (authType) {
      case BASIC:
        basicToken = Base64.encode(`${username}:${password}`) || '';
        headers.append('Authorization', `Basic ${basicToken}`);
        break;
      case BEARER:
        headers.append('Authorization', `Bearer ${getToken() || ''}`);
        break;
      case NONE:
      default:
        break;
    }

    if (browser && browser.name === 'ie') {
      headers.append('pragma', 'no-cache');
      headers.append('cache-control', 'no-cache, no-store, must-revalidate, max-age=0');
    }

    const init = {
      method,
      headers,
      mode: 'cors',
    };

    if (body) {
      init.body = body;
    }

    return fetch(url, init)
      .then((res) => {
        if (res.status === 401) {
          dispatch(checkAuthentication());
          throw new Error('Unauthorized');
        } else if (res.status === 503) {
          dispatch(
            setApiServiceError({
              status: res.status,
              url,
            }),
          );
        }
        // if (url !== `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/jwt`) {
        //   // TODO: Potentially need to throttle
        //   if (getState().auth.isAuthenticated
        //     && timeRemainingOnTokenMs() > 0
        //     && timeRemainingOnTokenMs() <= NETWORK_RENEW_AUTH_THRESHOLD_MS) {
        //     dispatch(renewAuth()) // Refresh auth every valid response
        //   }
        // }
        return res;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        throw err;
      });
  };

export const fetchMe = () => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/me`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchPostResetPasswordToken = (token, password) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/reset_password/${token}`,
      method: 'POST',
      body: JSON.stringify(password),
    }),
  )
    // .then(res => res.json())
    .catch((err) => {
      throw err;
    });
    export const fetchPostResetPassword = email => (dispatch, getState) => dispatch(fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/reset_password`,
      method: 'POST',
      body: JSON.stringify(email),
    }))
      // .then(res => res.json())
      .catch(err => {
        throw err
      })
export const fetchPhysiciansAndEkgStackCount = (teamId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/members`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchTeamTriggers = (teamId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/triggers`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchPutTeamTriggers = (teamId, triggers) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/triggers`,
      authType: AUTH_TYPES.BEARER,
      method: 'PUT',
      body: JSON.stringify(triggers),
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchPostTeamMemberInvite = (teamId, newTeamMember) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/invites`,
      authType: AUTH_TYPES.BEARER,
      method: 'POST',
      body: JSON.stringify(newTeamMember),
    }),
  )
    // .then(res => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchDeleteTeamMemberInvite = (inviteId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/invites/${inviteId}`,
      authType: AUTH_TYPES.BEARER,
      method: 'DELETE',
    }),
  )
    // .then(res => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchGetTeamMember = (teamId, teamMemberId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/members/${teamMemberId}`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchGetTeamMemberInvites = (teamId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/invites`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchPostPassword = (password) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/password`,
      authType: AUTH_TYPES.BEARER,
      method: 'POST',
      body: JSON.stringify(password),
    }),
  ).catch((err) => {
    throw err;
  });

export const fetchPutTeamMember = (teamId, teamMemberId, teamMember) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/members/${teamMemberId}`,
      authType: AUTH_TYPES.BEARER,
      method: 'PUT',
      body: JSON.stringify(teamMember),
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchPutTeamMemberReminders =
  (teamId, teamMemberId, reminders) => (dispatch, getState) =>
    dispatch(
      fetchApi({
        url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/members/${teamMemberId}/reminders`,
        authType: AUTH_TYPES.BEARER,
        method: 'PUT',
        body: JSON.stringify(reminders),
      }),
    )
      .then((res) => res.json())
      .catch((err) => {
        throw err;
      });
export const fetchDeleteTeamMember = (teamId, teamMemberId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/members/${teamMemberId}`,
      authType: AUTH_TYPES.BEARER,
      method: 'DELETE',
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchPostAppointmentNotification = (teamId, memeberId, data) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_TELEKARDIA_API_BASE}api/v1/teams/${teamId}/member/${memeberId}/settings/notification`,
      authType: AUTH_TYPES.BEARER,
      method: 'POST',
      body: JSON.stringify(data),
    }),
  ).catch((err) => {
    throw err;
  });
export const fetchAppointmentNotification = (teamId, memeberId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_TELEKARDIA_API_BASE}api/v1/teams/${teamId}/member/${memeberId}/settings/notification`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchPutTeamMemberInfo = (teamId, teamMemberId, teamMember) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v2/teams/${teamId}/members/${teamMemberId}`,
      authType: AUTH_TYPES.BEARER,
      method: 'PUT',
      body: JSON.stringify(teamMember),
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchGetTeamMemberReminders = (teamId, teamMemberId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/members/${teamMemberId}/reminders`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const postNotificationTeamSettings = (teamId, userId, data) => (dispatch, getState) => {
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_TELEKARDIA_API_BASE}api/v1/teams/${teamId}/admin/${userId}/notification-settings`,
      authType: AUTH_TYPES.BEARER,
      method: 'POST',
      body: JSON.stringify(data),
    }),
  ).catch((err) => {
    throw err;
  });
};

export const fetchNotificationTeamSettings = (teamId, userId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_TELEKARDIA_API_BASE}api/v1/teams/${teamId}/admin/${userId}/notification-settings`,
      authType: AUTH_TYPES.BEARER,
      method: 'GET',
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const postBusinessHoursTeamSettings =
  (teamId, userId, multiple = false, data, post = true, id = null, del = false) =>
  (dispatch, getState) => {
    const API_URL = `api/v1/teams/${teamId}/admin/${userId}/businessHour`;
    let URL;
    if (!post || del) {
      URL = `${API_URL}/${id}`;
    } else {
      URL = multiple ? `${API_URL}/multiple` : API_URL;
    }

    dispatch(
      fetchApi({
        url: `${process.env.REACT_APP_TELEKARDIA_API_BASE}${URL}`,
        authType: AUTH_TYPES.BEARER,
        method: del ? 'DELETE' : post ? 'POST' : 'PUT',
        body: JSON.stringify(data),
      }),
    ).catch((err) => {
      throw err;
    });
  };

export const fetchBusinessHoursTeamSettings = (teamId, userId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_TELEKARDIA_API_BASE}api/v1/teams/${teamId}/admin/${userId}/businessHour`,
      authType: AUTH_TYPES.BEARER,
      method: 'GET',
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchPostAcceptTeamMember = (newTeamMember) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/members`,
      authType: AUTH_TYPES.NONE,
      method: 'POST',
      body: JSON.stringify(newTeamMember),
    }),
  )
    .then((res) => res.json())
    .then((res) => {
      console.log(res)
      const { firstName, lastName, email} = newTeamMember
      return GoAPI.post('/i/v1/kcc/chatUser', {firstName: firstName, lastName: lastName, email: email}, { headers: { Authorization: `Bearer ${getToken()}` } })
        .then((res) => {
          console.log('chatUser data', res);
        })
        .catch((err) => {
          throw err;
        });
    })
    .catch((err) => {
      throw err;
    });
