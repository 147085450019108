/* eslint-disable */
import React, { useEffect } from 'react';
import '../../Assets/styles/react-web-tabs.scss';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import DashBoardMiddleSection from './DashBoardMiddleSection';
import '../../Assets/styles/dashboard.scss';
import * as actions from '../../Store/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { getAge, getTimezoneName } from './../../Utilities/Utilities';
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const VerticalTab = (props) => {
  const dispatch = useDispatch();
  const [appointmentId_, setappointmentId_] = React.useState('');
  const [reload, setreload] = React.useState(false);
  const patientDetailsntwrk = useSelector((state) => state.appointments.patientDetails);
  const teamId = useSelector((state) => state.user.team.id);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const recordingntwrk = useSelector((state) => state.ekg.recording);
  const clinicianAppointments = useSelector((state) => state.appointments.clinicianAppointments);
  let prevapps = [];
  useEffect(() => {
    let firstId = undefined;
    if (props.appointments.length > 0 && clinicianAppointments === props.appointments) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      prevapps = props.appointments;
      firstId = props.appointments[0].id;
      if (clinicianId && firstId) {
        changeHandler(props.appointments[0]);
        setappointmentId_(firstId);
      }
    }
  }, [props.appointments, reload]);

  const [patientDetails, setPatientDetails] = React.useState({});

  const [recording, setRecording] = React.useState([]);

  useEffect(() => {
    if (patientDetailsntwrk.patient) {
      patientDetailsntwrk.patient['age'] = getAge(patientDetailsntwrk.patient.dob);
      setPatientDetails(patientDetailsntwrk);
      setRecording(recordingntwrk);
    }
  }, [patientDetailsntwrk, recordingntwrk, reload]);
  const [clicked, setClicked] = React.useState(false);
  const changeHandler = (eve) => {
    if (eve.start === props.first['start']) {
      setClicked(true);
    } else {
      setClicked(false);
    }

    props.setAppointmentID(eve.id);
    setappointmentId_(eve.id);
    if (clinicianId && eve.id) {
      dispatch(
        actions.getRecordingSamples(eve.ekgId, teamId, clinicianId, eve.id, {
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        }),
      );
    }
  };
  let maxTime, minTime;
  if (props.first && 'start' in props.first) {
    let first = moment(props.first['start']).format('H');
    minTime = new Date();
    minTime.setHours(first, 0, 0);
  }
  if (props.last && 'end' in props.last) {
    let last = moment(props.last['end']).add(1, 'hours').format('H');
    maxTime = new Date();
    if (last === '0') {
      maxTime.setHours(23, 59, 59);
    } else {
      maxTime.setHours(last, 0, 0);
    }
  }
  const reloadHandler = () => {
    props.loader();
    setreload(!reload);
  };
  //let cDate = new Date();
  const CustomEvent = (event) => {
    let status = 'badge badge-info text-uppercase text-truncate';

    if (event.event.status === 1 || event.event.status === 2) {
      status = 'badge badge-info text-uppercase text-truncate status_purple';
    } else if (event.event.status === 7) {
      status = 'badge badge-info text-uppercase text-truncate status_green';
    } else if (event.event.status === 1 || event.event.status === 6) {
      status = 'badge badge-info text-uppercase text-truncate status_purple';
    }
    return (
      <div className="patient_name d-flex justify-content-between">
        {/* appointment content */}
        <div className="text-truncate f16">
          {event.title['firstName']} {event.title['lastName']}
        </div>
        {/* <span className={status}>{event.event.statusName}</span> */}
      </div>
    );
  };
  return (
    <>
      {/*<h6>{new Date().toDateString()}</h6>*/}
      <div className="d-flex flex-row shade bg_white border_rad overflow-hidden height-100vh w-100">
        <div className="dashboard_calendar width_330 home_left">
          <Calendar
            toolbar={false}
            step={30}
            localizer={localizer}
            min={minTime}
            max={maxTime}
            defaultView={'day'}
            events={props.appointments}
            views={{
              day: true,
            }}
            components={{
              event: CustomEvent,
            }}
            eventPropGetter={(event, start, isSelected) => {
              if (event.start == props.first['start'] && clicked) {
                return { className: 'rbc-selected' };
              } else {
                return { className: '' };
              }
            }}
            style={{ height: 650 }}
            tooltipAccessor={null}
            onSelectEvent={(prps) => changeHandler(prps)}
          />
        </div>
        <div className="home_center">
          <DashBoardMiddleSection
            {...props}
            id={appointmentId_}
            patientDetails={patientDetails}
            recording={recording}
            appointments={props.appointments}
            loader={reloadHandler}
          />
        </div>
      </div>
    </>
  );
};

export default VerticalTab;
