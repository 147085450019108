/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Errorpage from './Components/Errorpage';
import * as actions from './Store/actions';
import { setResetIdleTime, timeRemainingOnTokenMs } from './Auth/AuthUtilities';
import { connect } from 'react-redux';
import Appointments from './Components/Appointments/Appointments';
import Dashboard from './Components/Dashboard/Dashboard';
import AllAppointments from './Components/AllAppointments/AllAppointments';
import ProviderStatus from './Components/Shared/ProviderStatus';
import Headers from './Components/Shared/Header';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AccessDeniedContent from './Components/Shared/AccessDeniedContent/AccessDeniedContent';
import OfflinePopup from './Components/Appointments/Calendar/OfflinePopup';
// import useWebSocket from 'react-use-websocket';

const useStyles = makeStyles(() => ({
  accessDeniedContent: {
    backgroundColor: '#FFFFFF',
    margin: '50px 0 0 0 ',
    padding: '40px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useNetwork = (onChange) => {
  const [status, setStatus] = useState(navigator.onLine);
  const handleChange = () => {
    if (typeof onChange === 'function') {
      onChange(navigator.onLine);
    }
    setStatus(navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener('online', handleChange);
    window.addEventListener('offline', handleChange);
    return () => {
      window.removeEventListener('online', handleChange);
      window.removeEventListener('offline', handleChange);
    };
  }, []);
  return status;
};

const App = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [offlinealertopen, setofflinealertopen] = React.useState(false);
  // const [currentSocketUrl, setCurrentSocketUrl] = useState(null);
  // const [messagehistory, setMessageHistory] = useState([]);
  // const SOCKET_URL_ONE = 'wss://echo.websocket.org';
  const [snackbaropen, setsnackbaropen] = React.useState(false);
  const [networkstatus, setnetworkstatus] = useState('online');
  //   const handleNetworkChange = (online) => {
  //     setsnackbaropen(true);
  //     online ? setnetworkstatus("online") : setnetworkstatus("We just offline")
  // }

  const handleNetworkChange = (online) => {
    online ? setofflinealertopen(false) : setofflinealertopen(true);
    online ? setnetworkstatus('online') : setnetworkstatus('We just offline');
  };

  const closeofflinepopup = () => {
    setofflinealertopen(false);
  };

  // useEffect(() => {
  //   lastMessage && setMessageHistory((prev) => prev.concat(lastMessage.data));
  //   setsnackbaropen(true);
  //   playSound(hooteraudio);
  // }, [lastMessage]);
  const onLine = useNetwork(handleNetworkChange);

  const generateAsyncUrlGetter =
    (url, timeout = 2000) =>
    () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(url);
        }, timeout);
      });
    };
  // const { sendMessage, lastMessage } = useWebSocket(
  //   currentSocketUrl,
  //   {
  //     share: true,
  //     shouldReconnect: () => false,
  //   }
  // );

  // useEffect(() => {
  //   setCurrentSocketUrl(generateAsyncUrlGetter(SOCKET_URL_ONE))
  // }, []);
  // const hooteraudio = new Audio("https://proxy.notificationsounds.com/message-tones/juntos-607/download/file-sounds-1148-juntos.mp3");
  const playSound = (audioFile) => {
    var playPromise = audioFile.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          console.log('come1');
          // Automatic playback started!
          // Show playing UI.
        })
        .catch((error) => {
          console.log('come2', error);
          // Auto-play was prevented
          // Show paused UI.
        });
    }
  };

  // useEffect(() => {
  //   lastMessage && setMessageHistory((prev) => prev.concat(lastMessage.data));
  //   setsnackbaropen(true);
  //   playSound(hooteraudio);
  // }, [lastMessage]);

  const handlesnackbarClose = (event, reason) => {
    setsnackbaropen(false);
  };

  // console.log("messagehistory", messagehistory);

  let content =
    props.userstate.data && props.userstate.data.profile.permissions.isPhysician ? (
      <div className="container-fluid p-0">
        <div onClick={setResetIdleTime}>
          <ProviderStatus {...props} />
          <div className="telekardia">
            <Headers />
          </div>
          <div>
            {/* <Route exact path="/telekardia" render={()=><Dashboard {...props}/>}/> */}
            <Route path="/telekardia/dashboard" component={Dashboard} />
            <Route path="/telekardia/appointments" render={() => <Appointments {...props} />} />
            <Route
              path="/telekardia/all-appointments"
              render={() => <AllAppointments {...props} />}
            />
          </div>
        </div>
        {<OfflinePopup open={offlinealertopen} closeofflinepopup={closeofflinepopup} />}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbaropen}
          autoHideDuration={3000}
          onClose={handlesnackbarClose}
        >
          <Alert
            onClose={handlesnackbarClose}
            severity={networkstatus === 'online' ? 'success' : 'error'}
          >
            {networkstatus}
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <Container maxWidth="xl">
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          className={classes.accessDeniedContent}
        >
          <AccessDeniedContent />
        </Grid>
      </Container>
    );

  function getSupportedLocales() {
    if (!process.env.REACT_APP_I18N_SUPPORTED_LOCALES) {
      return ['en'];
    }
    return process.env.REACT_APP_I18N_SUPPORTED_LOCALES.split(',');
  }

  function isSupportedLocale(locale) {
    return getSupportedLocales().includes(locale);
  }

  function getLocale() {
    const browserLocale = navigator.language;
    if (browserLocale && isSupportedLocale(browserLocale)) {
      return browserLocale;
    }

    // fallback to base locale if we have it
    if (browserLocale && browserLocale.indexOf('-') !== -1) {
      // en-US -> 'en' or 'de-DE' -> 'de'
      const baseLocale = browserLocale.split('-')[0];
      if (isSupportedLocale(baseLocale)) {
        console.log(`Using base locale: ${baseLocale}`);
        return baseLocale;
      }
    }
  }

  useEffect(() => {
    let locale = getLocale();
    i18n.changeLanguage(locale);

    if (
      !props.gstate.user.profile ||
      props.gstate.user.profile.id !== props.userstate.data.profile.id
    ) {
      if (props.userstate) {
        props.onAddUser(props.userstate.data);
      } else {
        return <Errorpage />;
      }
    }
    return () => {
      props.onAddUser('logOut');
    };
  }, [props.userstate.data]);

  if (!(timeRemainingOnTokenMs() > 0)) {
    //redirect to home
    console.log('Token Expired or Invalid - Redirecting to Login');
    //props.onUserLogout();
    window.location.replace('http://' + window.location.host + '/login');
  } else if (props.gstate.user.profile) {
    return (
      <div>
        <BrowserRouter>{content}</BrowserRouter>
      </div>
    );
  } else return <div></div>;
};

const mapStateToProps = (state) => {
  return {
    gstate: state,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onAddUser: (x) => dispatch(actions.addUser(x)),
    onLogOut: () => dispatch(actions.user_logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
