/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import styles from './Forms.module.css';
import { green } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextInput from '../Forms/components/TextInput';
import MaskedInput from '../Forms/components/MaskedInput';
import CheckboxInput from '../Forms/components/CheckboxInput';
import ReactSelectDropdownInput from '../Forms/components/ReactSelectDropdownInput';
import {
  emailMask,
  requiredValidation,
  emailValidation,
  minLen1Validation,
  nameValidation,
  minPhoneNumberValidation, 
  ophn1Prefeix
} from './components/formValidations';
import { getEmailFrequencyOptions, EMAIL_FREQUENCY_TYPES, DATASOURCES } from '../../constants/app';
import Loading from '../Loading';
// import EditIcon from '../../Assets/images/icons/pencil-outline.svg';
import CancelIcon from '../../Assets/images/icons/delete-outline.svg';
import Popover from '../Popovers/components/Popover';
import { getDataSource } from '../../Store/reducers/app';
import { countryList, RegionList } from './constants';
import '../../Assets/styles/bootstrap.scss';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class EditMemberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: {
        email: false,
        firstName: false,
        lastName: false,
        emailFrequency: false,
        phone: false,
      },
      deleteConfirmShow: false,
      isText:
        props.notificationData &&
        props.notificationData.data &&
        props.notificationData.data.result &&
        props.notificationData.data.result.smsEnabled,
      isEmail:
        props.notificationData &&
        props.notificationData.data &&
        props.notificationData.data.result &&
        props.notificationData.data.result.emailEnabled,
      isDisable: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.notificationData &&
      this.props.notificationData.isFetching !== prevProps.notificationData.isFetching
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isText:
          this.props.notificationData &&
          this.props.notificationData.data &&
          this.props.notificationData.data.result &&
          this.props.notificationData.data.result.smsEnabled,
        isEmail:
          this.props.notificationData &&
          this.props.notificationData.data &&
          this.props.notificationData.data.result &&
          this.props.notificationData.data.result.emailEnabled,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.submitSucceeded !== this.props.submitSucceeded &&
      nextProps.submitSucceeded === true
    ) {
      this.setState({
        // disabled: {
        //   email: true,
        //   firstName: true,
        //   lastName: true,
        //   emailFrequency: true,
        //   phone: true,
        // },
        deleteConfirmShow: false,
      });
    }
  }
  toggleDisable = (field) => {
    this.setState((prevState) => ({
      disabled: Object.assign(prevState.disabled, {
        [field]: !prevState.disabled[field],
      }),
    }));
  };
  // popover functions here for now, should move later
  // same as revoke on enrollment form
  toggleConfirmPopover = () => {
    this.setState((prevState) => ({
      deleteConfirmShow: !prevState.deleteConfirmShow,
    }));
  };
  cancelDelete = () => {
    this.setState({
      deleteConfirmShow: false,
    });
  };

  CustomSwitch = withStyles({
    switchBase: {
      // color: '#2D9F86',
      '&$checked': {
        color: '#2D9F86',
      },
      '&$checked + $track': {
        backgroundColor: '#2D9F86',
      },
    },
    checked: {},
    track: {},
  })((field) => <Switch onChange={field.input.onChange} checked={field.input.value} {...field} />);

  handleClick = () => {
    const { isEmail, isText } = this.state;
    const data = {
      isEmail,
      isText,
    };
    this.props.handleAppointmentNotificationsChange(data);
  };

  renderSelectField = withStyles({
    select: {
      height: '10px',
    },
    selectMenu: {
      minHeight: 'unset',
      background: 'transparent !important',
    },
  })(({ input, label, meta: { touched, error }, children, ...custom }) => (
    <FormControl size="small" variant="outlined">
      <InputLabel>{label}</InputLabel>
      <SelectField
        errorText={touched && error}
        className={styles.selectFieldStyle}
        {...input}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        {...custom}
      />
      {error && <span className={styles.error}>{error}</span>}
    </FormControl>
  ));

  renderMembers = (temp) => (
    // <ul>
    <>
      {/* className={
        this.props.editMemberFormData &&
        this.props.editMemberFormData.region === null &&
        this.props.editMemberFormData.country === null
          ? styles.displayNone
          : styles.editDropdown
      } */}

      <div>
        <InputLabel className={styles.labelColorDropdown}>Country</InputLabel>
        <Field
          name="country"
          component={this.renderSelectField}
          validate={
            this.props.editMemberFormData &&
            this.props.editMemberFormData.region === null &&
            this.props.editMemberFormData.country === null
              ? []
              : [requiredValidation]
          }
          disabled={this.props.isMe}
          //  label="Country"
        >
          {countryList.map((item, i) => (
            <MenuItem key={i} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Field>
      </div>
      <div>
        <InputLabel className={styles.labelColorDropdown}>Region</InputLabel>
        <Field
          name="region"
          component={this.renderSelectField}
          validate={
            this.props.editMemberFormData &&
            this.props.editMemberFormData.region === null &&
            this.props.editMemberFormData.country === null
              ? []
              : [requiredValidation]
          }
          disabled={this.props.isMe}
          //  label="Region"
        >
          {Object.values(RegionList).map((item, i) => (
            <MenuItem
              key={i}
              disabled={
                !!this.props.allCountryRegionArray.find(function (r) {
                  return r.region == item.value ? true : false;
                })
              }
              value={item.value}
            >
              {item.name}
            </MenuItem>
          ))}
        </Field>
      </div>
      {/* {!this.props.isMe && (
          <div style={{ marginTop: 27 }} className={this.props.isMe ? styles.disableIconCss : ''}> */}
      {/* <img style={{ marginTop: -6 }} className={temp.fields.length === 0 ? styles.buttonCss : styles.buttonVisible} src={pluseIcon} alt="add button"/>
         <Link style={{ marginLeft: 8 }} href="#" className={temp.fields.length === 0 ? styles.buttonCss : styles.buttonVisible} onClick={() => temp.fields.push({})}>
          Add Location
         </Link> */}

      {/* <Button
              style={{ marginLeft: 8 }}
              className={
                temp.fields.length === 0
                  ? styles.buttonVisibleMyinfo
                  : `${styles.buttonCss} ${styles.button}`
              }
              onClick={() => {
                this.props.dispatch(change('editMemberForm', 'region', null));
                this.props.dispatch(change('editMemberForm', 'country', null));
              }}
            >
              Remove
            </Button>
            <Button
              disabled={this.props.isMe}
              className={
                temp.fields.length === 0
                  ? `${styles.buttonCss} ${styles.button}`
                  : styles.buttonVisibleMyinfo
              }
              onClick={() => temp.fields.push({})}
            >
              <AddCircleOutlineIcon style={{ marginRight: '5px', height: '22px' }} />
              <u>Add Location</u>
            </Button>
          </div>
        )} */}
      {/* {submitFailed && error && <span>{error}</span>} */}
    </>
    //   {temp.fields.map((member, index) => (
    //     <li className={styles.editDropdown} key={index}>
    //       <div>
    //         <InputLabel className={styles.labelColorDropdown}>Country</InputLabel>
    //         <Field
    //           name={`${member}.country`}
    //           component={this.renderSelectField}
    //           validate={[requiredValidation]}
    //           disabled={this.props.isMe}
    //         >
    //           {countryList.map((item, i) => (
    //             <MenuItem key={i} value={item.value}>
    //               {item.name}
    //             </MenuItem>
    //           ))}
    //         </Field>
    //       </div>
    //       <div>
    //         <InputLabel className={styles.labelColorDropdown}>Region</InputLabel>
    //         <Field
    //           name={`${member}.region`}
    //           component={this.renderSelectField}
    //           validate={[requiredValidation]}
    //           disabled={this.props.isMe}
    //           //  label="Region"
    //         >
    //           {Object.values(RegionList).map((item, i) => (
    //             <MenuItem
    //               key={i}
    //               disabled={this.props.allCountryRegionArray.find(function (r) {
    //                 return r.region == item.value ? true : false;
    //               })}
    //               value={item.value}
    //             >
    //               {item.name}
    //             </MenuItem>
    //           ))}
    //         </Field>
    //       </div>
    //       {!this.props.isMe && (
    //         <div style={{ marginTop: 27 }} className={this.props.isMe ? styles.disableIconCss : ''}>
    //           {/* <img style={{ marginTop: -6 }} className={(temp.fields.length > 0) && (temp.fields.length - 1 === index) ? styles.buttonCss : styles.buttonVisible} src={pluseIcon} alt="add button"/>
    //        <Link href="#" style={{ marginLeft: 8 }} className={(temp.fields.length > 0) && (temp.fields.length - 1 === index) ? styles.buttonCss : styles.buttonVisible} onClick={() => temp.fields.push({})}>
    //       Add Location
    //        </Link> */}
    //           <Button
    //             className={
    //               temp.fields.length > 0 && temp.fields.length === index
    //                 ? styles.buttonVisibleMyinfo
    //                 : `${styles.buttonCss} ${styles.button}`
    //             }
    //             onClick={() => temp.fields.remove(index)}
    //           >
    //             Remove
    //           </Button>
    //         </div>
    //       )}
    //     </li>
    //   ))}
    //   {temp.fields.map((member, index) => (
    //     <div style={{ marginTop: 14 }}>
    //       <Button
    //         disabled={this.props.isMe}
    //         className={
    //           temp.fields.length > 0 && temp.fields.length - 1 === index
    //             ? `${styles.buttonCss} ${styles.button}`
    //             : styles.buttonVisibleMyinfo
    //         }
    //         onClick={() => temp.fields.push({})}
    //       >
    //         <AddCircleOutlineIcon style={{ marginRight: '5px', height: '22px' }} />
    //         <u>Add Location</u>
    //       </Button>
    //     </div>
    //   ))}
    // </ul>
  );
  render() {
    const {
      handleSubmit,
      invalid,
      pristine,
      submitting,
      isSubmitting,
      editMemberFormData,
      handleOnTeamMemberDeleteClick,
      isMe,
      showEmailReminder,
      showTKFeatureEnabled,
      allCountryRegionArray,
    } = this.props;
    const { disabled, deleteConfirmShow } = this.state;
    const frequencyOptions = [...getEmailFrequencyOptions()];

    const isNotNeverSelected =
      editMemberFormData &&
      editMemberFormData.reminders &&
      editMemberFormData.reminders.emailFrequency.id !== EMAIL_FREQUENCY_TYPES.NEVER;

    // const getEditClassName = (field) => (disabled[field] ? 'editIcon' : 'editIconActive');

    return (
      <form onSubmit={handleSubmit}>
        <div className={styles.editMemberSection}>
          <div className={`${styles.formSectionRowGrid} ${styles.c4}`}>
            <div className={styles.editLabelGroup} style={{ width: 'unset' }}>
              {/* {isMe ? (
                <div className={styles.noEditIcon} />
              ) : (
                <div
                  className={styles[getEditClassName('firstName')]}
                  onClick={() => this.toggleDisable('firstName')}
                >
                  <EditIcon />
                </div>
              )} */}
              <Field
                component={TextInput}
                label="First Name"
                name="firstName"
                placeholder="Enter First Name"
                required={true}
                disabled={!(!isMe && !disabled.firstName)}
                validate={[minLen1Validation, requiredValidation, nameValidation]}
              />
            </div>
            <div className={styles.editLabelGroup} style={{ width: 'unset' }}>
              {/* {isMe ? (
                <div className={styles.noEditIcon} />
              ) : (
                <div
                  className={styles[getEditClassName('lastName')]}
                  onClick={() => this.toggleDisable('lastName')}
                >
                  <EditIcon />
                </div>
              )} */}
              <Field
                component={TextInput}
                label="Last Name"
                name="lastName"
                placeholder="Enter Last Name"
                required={true}
                disabled={!(!isMe && !disabled.lastName)}
                validate={[minLen1Validation, requiredValidation, nameValidation]}
              />
            </div>
            {showTKFeatureEnabled && (
              <React.Fragment>
                <div className={styles.editLabelGroup} style={{ width: 'unset' }}>
                  {/* {isMe ? (
                    <div className={styles.noEditIcon} />
                  ) : (
                    <div
                      className={styles[getEditClassName('phone')]}
                      onClick={() => this.toggleDisable('phone')}
                    >
                      <EditIcon />
                    </div>
                  )} */}
                  <Field
                    component={TextInput}
                    rightIcon={PhoneIcon}
                    label="Phone"
                    name="phone"
                    type={'number'}
                    placeholder="Enter phone no"
                    required={true}
                    disabled={!(!isMe && !disabled.phone)}
                    validate={[minPhoneNumberValidation, ophn1Prefeix, requiredValidation]}
                  />
                </div>
              </React.Fragment>
            )}
            <div className={styles.editLabelGroup} style={{ width: 'unset' }}>
              {/* {isMe ? (
                <div className={styles.noEditIcon} />
              ) : (
                <div
                  className={styles[getEditClassName('email')]}
                  onClick={() => this.toggleDisable('email')}
                >
                  <EditIcon />
                </div>
              )} */}
              <Field
                component={MaskedInput}
                rightIcon={EmailIcon}
                mask={emailMask}
                placeholder="name@example.com"
                label="Email"
                name="email"
                required={true}
                disabled={!(!isMe && !disabled.email)}
                validate={[emailValidation, minLen1Validation, requiredValidation]}
              />
            </div>
            {/* <InputLabel>Country</InputLabel> */}
            {showTKFeatureEnabled && (
              <React.Fragment>
                {this.renderMembers()}
                {/* <div className={styles.editDropdown}> */}
                {/* <FieldArray name="members" component={this.renderMembers} /> */}
                {/* </div> */}
              </React.Fragment>
            )}
          </div>
        </div>
        <h2 className={styles.formTitle}>Coach Roles</h2>
        <div className={styles.editMemberSection}>
          <div className={styles.formSectionRow}>
            <div className={styles.checkboxWithDesc}>
              <Field
                component={CheckboxInput}
                label="Coach"
                name="permissions.isPhysician"
                disabled={isMe}
              />
              <p style={{ marginBottom: 10 }} className={styles.description}>
                Access to Coach functions (e.g. view member list)
              </p>
              {showTKFeatureEnabled && (
                <React.Fragment>
                  <div className={styles.manageAvail}>
                    <div className={styles.description} style={{ marginTop: 7 }}>
                      Manages Availability
                    </div>
                    <div>
                      <Field
                        component={this.CustomSwitch}
                        label="memberRoleProvider"
                        name="canManageAvailability"
                        disabled={isMe}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className={styles.checkboxWithDesc}>
              <Field
                component={CheckboxInput}
                label="Coach Administrator"
                name="permissions.isAdmin"
                disabled={isMe}
              />
              <p className={styles.description}>
                Can manage team settings (e.g. add/remove coaches)
              </p>
            </div>
            <div className={styles.placeholder} />
          </div>
        </div>
        {/* Start Appointment Notifications  */}
        {showTKFeatureEnabled && (
          <React.Fragment>
            <h2 style={{ marginLeft: 0 }} className={styles.formTitle}>
              {' '}
              Appointment Notifications
            </h2>
            <div className={styles.formSection}>
              <div style={{ justifyContent: 'unset' }} className={styles.formSectionRow}>
                <React.Fragment>
                  <div style={{ width: '10%' }} className={styles.checkboxWithDesc}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={this.state.isText}
                          onChange={(e) => {
                            this.setState({
                              isText: e.target.checked,
                              isDisable: true,
                            });
                          }}
                          name="isText"
                        />
                      }
                      label="Text"
                    />
                  </div>
                  <div className={styles.checkboxWithDesc}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={this.state.isEmail}
                          onChange={(e) => {
                            this.setState({
                              isEmail: e.target.checked,
                              isDisable: true,
                            });
                          }}
                          name="isEmail"
                        />
                      }
                      label="Email"
                    />
                  </div>
                  <div className={styles.placeholder} />
                </React.Fragment>
              </div>
            </div>
          </React.Fragment>
        )}

        {/* {showEmailReminder && (
          <React.Fragment>
            <h2 className={styles.formTitle}>Email Reminder</h2>
            <h3 className={styles.formSubtitle}>
              Send an email reminder when ECGs and reports need attention.
            </h3>
            <div className={styles.editMemberSection}>
              <div className={styles.formSectionRow}>
                <div className={styles.editLabelGroup}>
                 
                  <Field
                    component={ReactSelectDropdownInput}
                    label="Frequency of reminders"
                    name="reminders.emailFrequency"
                    isClearable={false}
                    backspaceRemovesValue={false}
                    options={frequencyOptions}
                    getOptionLabel={(p) => p.label}
                    getOptionValue={(p) => p.id}
                    disabled={disabled.emailFrequency}
                  />
                  {isNotNeverSelected && (
                    <div className={styles.emailRules}>
                      <div className={styles.emailRulesLabelFirst}>ECG Inbox</div>
                      <Field
                        component={CheckboxInput}
                        label="ECGs to review in Personal Inbox"
                        name="reminders.personalReminder"
                        disabled={!(isNotNeverSelected && !disabled.emailFrequency)}
                      />
                      <Field
                        component={CheckboxInput}
                        label="ECGs to review in Practice Inbox"
                        name="reminders.triageReminder"
                        disabled={!(isNotNeverSelected && !disabled.emailFrequency)}
                      />
                      <div className={styles.emailRulesLabel}>Reports</div>
                      <Field
                        component={CheckboxInput}
                        label="Reports to be e-signed by this provider"
                        name="reminders.personalEsignReportReminder"
                        disabled={!(isNotNeverSelected && !disabled.emailFrequency)}
                      />
                      <Field
                        component={CheckboxInput}
                        label="Reports to be e-signed in practice"
                        name="reminders.teamEsignReportReminder"
                        disabled={!(isNotNeverSelected && !disabled.emailFrequency)}
                      />
                      <Field
                        component={CheckboxInput}
                        label="Reports to be exported"
                        name="reminders.teamExportReportReminder"
                        disabled={!(isNotNeverSelected && !disabled.emailFrequency)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        )} */}
        <div className={styles.editMemberSubmitWrapper}>
          <div className={styles.buttonWrapper}>
            {!isMe && (
              <div className="telekardia">
                <button
                  type="button"
                  className={`btn btn-outline-primary text-uppercase ${styles.deleteCoach}`}
                  onClick={() => this.toggleConfirmPopover()}
                >
                  <CancelIcon />
                  Delete Coach
                </button>
                {deleteConfirmShow && (
                  <Popover
                    onOutsidePopoverClick={() => this.cancelDelete()}
                    className={styles.dropover}
                  >
                    <div className={styles.dropoverContent}>
                      <h2>You are about to delete this coach</h2>
                      <p>
                        By deleting this coach, any members currently assigned to them will be
                        assigned to “Unassigned” for their provider.
                      </p>
                    </div>
                    <div className={styles.dropoverControls}>
                      <a style={{ color: '#2D9F86' }} onClick={() => this.cancelDelete()}>
                        Cancel
                      </a>
                      <a style={{ color: '#2D9F86' }} onClick={handleOnTeamMemberDeleteClick}>
                        OK
                      </a>
                    </div>
                  </Popover>
                )}
              </div>
            )}
            {!pristine && (submitting || isSubmitting) ? (
              <button disabled className={styles.submitLoading}>
                <Loading />
              </button>
            ) : (
              <button
                type="submit"
                onClick={() => {
                  if (showTKFeatureEnabled) {
                    this.handleClick();
                  }
                }}
                style={{ height: 40, boxShadow: 'unset' }}
                disabled={this.state.isDisable ? !this.state.isDisable : invalid || pristine}
                className={styles.submit}
              >
                Save Changes
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  notificationData: getDataSource(state, DATASOURCES.MEMBER_NOTIFICATION_SETTINGS),
});

const decoratedComponent = connect(mapStateToProps, null)(EditMemberForm);

export default reduxForm({
  form: 'editMemberForm',
  enableReinitialize: true,
})(decoratedComponent);
