import React from 'react';
import NavigationSidebar from '../NavigationSidebar';
// import AppNotification from 'views/components/AppNotification/AppNotification'
import styles from './SidebarLayout.module.css';

const SidebarLayout = (props) => {
  const { children, sidebarIsCollapsed } = props;

  const contentClassName = sidebarIsCollapsed ? 'contentWithSidebarCollapsed' : 'content';

  return (
    <div className={styles.root}>
      {/* <AppNotification /> */}
      <NavigationSidebar />
      <div className={styles[contentClassName]}>{children}</div>
    </div>
  );
};

export default SidebarLayout;
