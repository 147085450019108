import React, { useContext, useState, useEffect } from 'react';
import { NavLink as NL, useHistory } from 'react-router-dom';

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import EventIcon from '@material-ui/icons/Event';
import EmailIcon from '@material-ui/icons/Email';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import SettingsIcon from '@material-ui/icons/Settings';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import { ROUTES } from '../../constants/app';
import DashboardIcon from '../../Assets/images/poll.svg';

import ConsultationIcon from '../../Assets/images/icons/consultation.svg';
import SchedulerIcon from '../../Assets/images/icons/scheduler.svg';
import FeedbackIcon from '../../Assets/images/icons/feedback.svg';
import SidebarBottomIcon from '../../Assets/images/sidebar-bottom.svg';
import KPLogo from './Logo';
import MenuIcon from '../../Assets/images/icons/menu.svg';
import KPCollapse from '../../Assets/images/icons/back.svg';
import styles from './NavigationSidebar.module.css';
import { useSelector } from 'react-redux';
import { Chat } from '../../providers';
import { useTranslation } from 'react-i18next';
import ViewListIcon from '@material-ui/icons/ViewList';

const TeamProfile = (props) => {
  const { me, sidebarIsCollapsed, handleToggleNavigationSidebar } = props;
  const { isFetching, data } = me;

  let teamName = '';
  let initials = '';
  let fName = '';
  let lName = '';
  let avatar;

  if (!data) {
    teamName = '';
  } else {
    const { name, logoURL } = data.team;
    const { firstName, lastName } = data.profile;
    teamName = name;
    fName = firstName;
    lName = lastName;
    initials = firstName.charAt(0) + lastName.charAt(0);
    const avatarClassName = sidebarIsCollapsed ? 'avatarCollapsed' : 'avatar';
    const initialsClassName = sidebarIsCollapsed ? 'initialsCollapsed' : 'initials';

    if (logoURL) {
      avatar = <img className={styles[avatarClassName]} src={logoURL} />;
    } else {
      avatar = (
        <div className={styles[initialsClassName]}>
          <span>{initials}</span>
        </div>
      );
    }
  }

  const profileClassName = sidebarIsCollapsed ? 'profileCollapsed' : 'profile';
  const mainLogoClassName = sidebarIsCollapsed ? 'mainLogoCollapsed' : 'mainLogo';

  return (
    <React.Fragment>
      <div className={styles[mainLogoClassName]}>
        {sidebarIsCollapsed ? (
          <span
            onClick={() => {
              handleToggleNavigationSidebar();
            }}
          >
            <MenuIcon />
          </span>
        ) : (
          <React.Fragment>
            <KPLogo />{' '}
            <span
              onClick={() => {
                handleToggleNavigationSidebar();
              }}
            >
              <KPCollapse />
            </span>
          </React.Fragment>
        )}
      </div>
      <div className={styles[profileClassName]}>
        {data ? (
          <div>
            {avatar}
            {!sidebarIsCollapsed && (
              <div>
                <h2 className={styles.userName}>
                  {fName} {lName}
                </h2>
                <h2 className={styles.teamName}>
                  {teamName}
                  <br />
                </h2>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.loader}>{/* <Loading /> */}</div>
        )}
      </div>
    </React.Fragment>
  );
};

const renderLinkContent = (props) => {
  const { Icon, text, secondaryBullet = false, color = '#2D9F86' } = props;

  return (
    <div className={styles.leftGroup}>
      {Icon && (
        <div className={styles.navIcon}>
          <Icon style={{ fill: color }} />
        </div>
      )}
      {secondaryBullet && <div className={styles.bullet} />}
      <span className={styles.navText}>{text}</span>
    </div>
  );
};

const NavLink = (props) => {
  const { className, activeClassName, to, href, onClick, target = '', text, unreadCount } = props;

  if (href) {
    return (
      <a href={href} className={styles.navExtLink} target={target}>
        {renderLinkContent(props)}
      </a>
    );
  }
  return (
    <NL
      className={className}
      activeClassName={activeClassName}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
      to={to}
    >
      {renderLinkContent(props)}
      {text === 'Inbox' && unreadCount > 0 && <div className={styles.count}>{unreadCount}</div>}
    </NL>
  );
};

const Navigation = (props) => {
  const { me, updatePrevLocation, handleLogout } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const { unreadMsgCount } = useContext(Chat.Context);
  const [unreadCount, setunreadCount] = useState(0);

  useEffect(() => {
    setunreadCount(
      Object.values(unreadMsgCount).reduce((acc, cur) => {
        return acc + cur;
      }, 0),
    );
  }, [unreadMsgCount]);

  // const checkFeature = name => {
  //   const feature = features.find(feat => feat.feature === name)
  //   return feature ? feature.enabled : false
  // }

  const isAdmin = me.data ? me.data.profile.permissions.isAdmin : false;
  const isPhysician = me.data ? me.data.profile.permissions.isPhysician : false;
  return (
    <React.Fragment>
      <ul className={styles.navList}>
        {isAdmin && (
          <>
            <li data-action="page-navigation" data-label="Dashboard">
              <NavLink
                className={styles.sidebarItem}
                activeClassName={styles.sidebarItemActive}
                to={
                  history.location.pathname === ROUTES.ROOT
                    ? ROUTES.ROOT
                    : ROUTES.TELEKARDIA_DASHBOARD2
                }
                Icon={DashboardIcon}
                text={t('Dashboard')}
              />
            </li>
            <div className={styles.spacing}></div>
          </>
        )}

        {isPhysician && (
          <>
            <li data-action="page-navigation" data-label="Dashboard">
              <NavLink
                className={styles.settings}
                activeClassName={styles.sidebarItemActive}
                to={ROUTES.TELEKARDIA_DASHBOARD}
                Icon={AssignmentTurnedInIcon}
                unreadCount={unreadCount}
                text={t('Dashboard')}
              />
            </li>
            <div className={styles.spacing}></div>
          </>
        )}

        {isPhysician && (
          <>
            <li data-action="page-navigation" data-label="Inbox">
              <NavLink
                className={styles.settings}
                activeClassName={styles.sidebarItemActive}
                to={ROUTES.CHAT_INBOX}
                Icon={EmailIcon}
                unreadCount={unreadCount}
                text={t('Inbox')}
              />
            </li>
            <div className={styles.spacing}></div>
          </>
        )}

        {isPhysician && !isAdmin && (
          <>
            <li data-action="page-navigation" data-label="Member List">
              <NavLink
                className={styles.sidebarItem}
                activeClassName={styles.sidebarItemActive}
                to={ROUTES.COACH_MEMBER_LIST}
                Icon={FormatListBulletedIcon}
                text={t('Member List')}
              />
            </li>
            <div className={styles.spacing}></div>
          </>
        )}

        

        {isPhysician && (
          <>
            <li data-action="page-navigation" data-label="Schedule">
              <NavLink
                className={styles.sidebarItem}
                activeClassName={styles.sidebarItemActive}
                to={ROUTES.COACH_SCHEDULE}
                Icon={EventIcon}
                text={t('Schedule')}
              />
            </li>
            <div className={styles.spacing}></div>
          </>
        )}

        {isAdmin && (
          <>
            <li data-action="page-navigation" data-label="Feedback">
              <NavLink
                className={styles.sidebarItem}
                activeClassName={styles.sidebarItemActive}
                to={ROUTES.ADMIN_FEEDBACK}
                Icon={FeedbackIcon}
                text={t('Feedback')}
              />
            </li>
            <div className={styles.spacing}></div>
            <>
            <li data-action="page-navigation" data-label="Member List">
              <NavLink
                className={styles.sidebarItem}
                activeClassName={styles.sidebarItemActive}
                to={ROUTES.ADMIN_MEMBER_LIST}
                Icon={FormatListBulletedIcon}
                text={t('Member List')}
              />
            </li>
            <div className={styles.spacing}></div>
          </>
            <div className={styles.spacing}></div>
            <li data-action="page-navigation" data-label="Scheduler">
              <NavLink
                className={styles.sidebarItem}
                activeClassName={styles.sidebarItemActive}
                to={ROUTES.ADMIN_CALENDAR}
                Icon={SchedulerIcon}
                text={t('Scheduler')}
              />
            </li>
            <div className={styles.spacing}></div>
          </>
        )}
      </ul>
      {/* {(process.env.NODE_ENV !== 'production' && !sidebarIsCollapsed) && (
        <div className={styles.buildInfo}>
          Build Info: <br />
          V: {getBuildVersion()}<br />
          D: {getBuildDateTime()}
        </div>
      )} */}
      <div className={styles.sidebarFooter}>
        <div
          className={styles.sidebarFooterItem}
          data-action="page-navigation"
          data-label="Coach Resources"
        >
          <NavLink
            className={styles.settings}
            activeClassName={styles.sidebarItemActive}
            to={ROUTES.COACH_RESOURCES}
            Icon={AccessibilityNewIcon}
            text={t('Coach Resources')}
          />
        </div>
        <div
          className={styles.sidebarFooterItem}
          data-action="page-navigation"
          data-label="Settings"
        >
          <NavLink
            className={styles.settings}
            activeClassName={styles.sidebarItemActive}
            to={ROUTES.SETTINGS}
            // onClick={() => settingsHandleOnNavClick(true, false)}
            Icon={SettingsIcon}
            text={t('Settings')}
          />
        </div>
        <button
          onClick={() => {
            updatePrevLocation(null);
            handleLogout();
            // history.push(ROUTES.LOGIN);
          }}
          className={styles.logout}
          data-action="signOut"
        >
          <div className={styles.navIcon}>
            <SignOutIcon style={{ fill: '#2D9F86' }} />
          </div>
          <span className={styles.navText}>{t('Sign Out')}</span>
        </button>
        <div className={styles.navIcon} style={{ position: 'absolute', bottom: 0 }}>
          <SidebarBottomIcon />
        </div>
      </div>
    </React.Fragment>
  );
};

export const NavigationSidebar = (props) => {
  const data = useSelector((state) => state.user);

  const { sidebarIsCollapsed, me, handleToggleNavigationSidebar } = props;

  const sidebarClassName = sidebarIsCollapsed ? 'sidebarCollapsed' : 'sidebar';
  // const sidebarClassName = 'sidebar'
  return (
    <React.Fragment>
      <aside data-page="navigation-sidebar" className={styles[sidebarClassName]}>
        <TeamProfile
          me={{ isFetching: false, data: data }}
          sidebarIsCollapsed={sidebarIsCollapsed}
          handleToggleNavigationSidebar={handleToggleNavigationSidebar}
        />
        <Navigation me={{ isFetching: false, data: data }} {...props} />
      </aside>
    </React.Fragment>
  );
};

export default NavigationSidebar;
