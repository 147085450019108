import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { StatusColumn } from './components/StatusColumn';
import { makeStyles, Tooltip } from '@material-ui/core';
import { EnhancedTableHead } from '../Common/EnhancedTableHead';
import { useTranslation } from 'react-i18next';
import Filter from './components/Filter';
import { useHistory } from 'react-router';
import { ROUTES } from '../../constants/app';
import moment from 'moment';
import SponsorPopover from './components/SponsorPopover';
import { adminHeadCells, headCells } from './data/mockData';
import InfoIconModal from './components/InfoIconModal';
import { useSelector } from 'react-redux';

const componentStyle = makeStyles({
  containerStyle: {
    //marginTop: '70px',
  },

  tableRowStyle: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgb(250, 250, 250)',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fff',
    },
    '&:hover': {
      backgroundColor: '#F0F8F5 !important',
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // console.log(stabilizedThis);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function MemberList(props) {
  const { t } = useTranslation();
  const { getMemberDetails, memberList, memberFilters, count } = props;
  const classes = componentStyle();
  const [isAdminMemberList, setIsAdminMemberList] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('lastName');
  const [selected, setSelected] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableHeadCells, setTableHeadCells] = useState([]);
  const [mL, setMl] = useState([]);
  const coachId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  /**
   * Fetching the member list data based on admin and coach
   * TODO: A way to trigger api calls and storing data on filter update
   */
  useEffect(() => {
    let isAdmin = false;
    /**
     * Bifurcating the member list to accomodate both admin and coach
     */
    if (props.location.pathname.split('/')[1] === 'admin') {
      setIsAdminMemberList(true);
      isAdmin = true;
    }
    getMemberDetails(isAdmin, coachId, teamId, page, rowsPerPage);
  }, [getMemberDetails, coachId]);

  /**
   * Populating head cells based on admin or coach
   */
  useEffect(() => {
    isAdminMemberList ? setTableHeadCells(adminHeadCells) : setTableHeadCells(headCells);
  }, [isAdminMemberList]);

  /**
   * Data decoration for viewing
   */
  useEffect(() => {
    // console.log('setting new member list')
    let temp = [...memberList];
    temp.map((m) => {
      m.lastLogin = new Date(m.lastLogin);
      m.nextSession = new Date(m.nextSession);
      return m;
    });
    setMl(temp);
  }, [memberList]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, value) => {
    setSelected(value);
    //console.log({selected})
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getMemberDetails(isAdminMemberList, coachId, teamId, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getMemberDetails(isAdminMemberList, coachId, 0, parseInt(event.target.value, 10));
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + 0) * rowsPerPage - mL.length) : 0;

  const history = useHistory();

  //NAVIGATION TO MEMBER PROFILE SCREEN ON CLICK OF MEMBER COLUMN DATA
  const memberProfileNavigation = (value, e) => {
    history.push(`${ROUTES.MEMBER_PROFILE}/${value}`);
  };
  //NAVIGATION TO MEMBER PROFILE SCREEN ON CLICK OF MEMBER COLUMN DATA
  const coachProfileNavigation = (value, e) => {
    history.push(`${ROUTES.SETTINGS}/team-members/${value}`);
  };

  //STATUS COLUMN SORING ALGORITHM
  const statusLegend = {
    0: 'Inactive',
    1: 'Noncompliant',
    2: 'Active',
    3: 'On hold',
    4: 'Completed',
    5: 'Terminated',
  };
  const computeLastLogin = (val) => {
    let oneDayBefore = moment().subtract(1, 'days');
    let oneWeekBefore = moment().subtract(7, 'days');
    if (val) {
      switch (true) {
        case moment(val) > oneDayBefore:
          return moment(val).format('hh:mm A');
        case moment(val) < oneWeekBefore:
          return moment(val).format('DD-MM-YYYY');
        default:
          return moment(val).fromNow();
      }
    }
  };

  //**
  // populating modal on hovering of infoicon in table headers
  const [open, setOpen] = React.useState(false);
  const [columnName, setcolumnName] = useState('');
  const handleModalOpen = (id) => {
    setOpen(true);
    setcolumnName(id);
  };
  const handleModalClose = () => {
    setOpen(false);
  };
  // */
  // console.log(mL)
  return (
    <Box
      sx={{ width: '100%', padding: isAdminMemberList ? '24px' : '0px' }}
      className={classes.containerStyle}
    >
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Filter memberFilters={memberFilters} />
        <TableContainer>
          <Table aria-labelledby="Member table">
            <EnhancedTableHead
              from="memberList"
              columnData={tableHeadCells}
              numSelected={selected}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              handleModalOpen={handleModalOpen}
            />
            <TableBody>
              {mL &&
                stableSort(mL, getComparator(order, orderBy))
                  .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    let statusColumnBorderColor, outliner;
                    {
                      // STATUS PILLS COLOR AND STYLING
                      (() => {
                        if (row.status != null) {
                          if (
                            statusLegend[row.status].toUpperCase() === 'ACTIVE' ||
                            statusLegend[row.status].toUpperCase() === 'COMPLETED'
                          ) {
                            statusColumnBorderColor = '#2D9F86';
                            outliner = 'solid';
                          } else if (
                            statusLegend[row.status].toUpperCase() === 'TERMINATED' ||
                            statusLegend[row.status].toUpperCase() === 'PAUSED'
                          ) {
                            statusColumnBorderColor = '#CC3D3F';
                            outliner = 'solid';
                          } else if (
                            statusLegend[row.status].toUpperCase() === 'ONBOARDING' ||
                            statusLegend[row.status].toUpperCase() === 'ONHOLD'
                          ) {
                            statusColumnBorderColor = '#FDC246';
                            outliner = 'solid';
                          } else {
                            statusColumnBorderColor = '#BABFBD';
                            outliner = 'dashed';
                          }
                        }
                      })();
                    }
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, index)}
                        tabIndex={-1}
                        key={row.id}
                        selected={!!selected}
                        className={classes.tableRowStyle}
                      >
                        {isAdminMemberList && (
                          <TableCell
                            style={{ fontSize: '14px', fontFamily: 'Work Sans', cursor: 'pointer' }}
                            onClick={() => coachProfileNavigation(row.coachId)}
                          >
                            <u>{`${row.coachLastName ? row.coachLastName : ''} ${
                              row.coachFirstName ? row.coachFirstName : ''
                            } `}</u>
                          </TableCell>
                        )}
                        {isAdminMemberList && (
                          <TableCell
                            style={{ fontSize: '14px', fontFamily: 'Work Sans', cursor: 'pointer' }}
                            onClick={() => memberProfileNavigation(row.participantId)}
                          >
                            <u>{`${row.participantLastName ? row.participantLastName : ''} ${
                              row.participantFirstName ? row.participantFirstName : ''
                            } `}</u>
                          </TableCell>
                        )}
                        {!isAdminMemberList && (
                          <TableCell
                            style={{ fontSize: '14px', fontFamily: 'Work Sans', cursor: 'pointer' }}
                            onClick={() => memberProfileNavigation(row.participantId)}
                          >
                            <u>{`${row.participantLastName ? row.participantLastName : ''} ${
                              row.participantFirstName ? row.participantFirstName : ''
                            } `}</u>
                          </TableCell>
                        )}
                        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                          {row.nextSession > new Date()
                            ? moment(row.nextSession).format('MM-DD-YYYY')
                            : 'Unscheduled'}
                        </TableCell>
                        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                          {row.bloodPressure}
                        </TableCell>
                        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                          {computeLastLogin(row.lastLogin)}
                        </TableCell>
                        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                          {row.sponsor &&
                            (row.sponsor.length > 6 ? (
                              <SponsorPopover item={row.sponsor} />
                            ) : (
                              row.sponsor
                            ))}
                        </TableCell>
                        <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                          {row.programList ? row.programList.join('/') : ''}
                        </TableCell>
                        <TableCell style={{ fontSize: '10px', fontFamily: 'Work Sans' }}>
                          <StatusColumn
                            data={
                              row.status != null
                                ? statusLegend[parseInt(row.status)].toUpperCase()
                                : ''
                            }
                            outliner={outliner}
                            columnBorderColor={statusColumnBorderColor}
                          />
                        </TableCell>
                        {!isAdminMemberList && (
                          <TableCell style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
                            {row.nextAction}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        {/* Modal on hovering of column's info icon */}
        <InfoIconModal
          open={open}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          columnName={columnName}
        />
      </Paper>
    </Box>
  );
}
