/* eslint-disable */
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import HourglassEmptySharpIcon from '@material-ui/icons/HourglassEmptySharp';
import { Button, Grid, SvgIcon } from '@material-ui/core';
import _get from 'lodash.get';
import EKGPreview from '../chart/EKGPreview';
import '../../Assets/styles/bootstrap.scss';
import { useTranslation } from 'react-i18next';
import Authorize from '../Authorize';
import withStartConsultation from './withStartConsultation';

const useStyles = makeStyles((theme) => ({
  root: {},
  verticalgrid: {
    minHeight: 600,
  },
  cardHeader: {
    fontWeight: '600',
  },
  blackGrid: {
    fontWeight: '600',
    alignItems: 'center',
    minHeight: '58px',
    padding: theme.spacing(1),
    background: '#E2E7E3',
    color: '#000000',
  },
  gridPadding: {
    padding: theme.spacing(1),
  },
  boldFont: {
    fontWeight: '600',
  },
  bolderFont: {
    fontWeight: '700',
  },
  cardlayout: {
    minHeight: 600,
    background: '#F1F1F1',
  },
  columngrid: {
    padding: 10,
    // overflow: "auto",
    background: '#FFFFFF',
  },
  cardlayoutmini: {
    minHeight: 280,
    background: '#F1F1F1',
  },
  bpm: {
    fontWeight: 700,
    color: '#000000',
    fontSize: '14px',
    fontFamily: 'anonymous-pro',
  },
  bpmspan: {
    fontWeight: 600,
    color: '#c0c0c0',
    fontSize: '12px',
    paddingLeft: '4px',
  },
}));
const cssbutton = {
  position: 'relative',
  top: 50,
  left: 97,
  right: 0,
  margin: '0 auto',
  height: '30px',
  width: '86px',
  display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
  justify: 'space-around',
  alignItems: 'center',
  border: '1px solid #EFEFEF',
  background: '#F2F4F8',
  paddingLeft: '5px',
  paddingRight: '5px',
  '--box-shadow': '0px 3px 4px 0px light-grey',
  '--border-bottom-left-radius': '8px',
  '--border-bottom-right-radius': '8px',
};

function DashBoardMiddleSection(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    checkStatus,
    patientDetails,
    recording,
    appointments,
    reassignAppointment,
    alertHandler,
    openHandler,
  } = props || {};

  return (
    <div className="telekardia">
      {appointments && appointments.length > 0 && patientDetails ? (
        <div className="mx-2 mb-4 mt-md-4 mt-lg-4 mt-xl-5 height-100v">
          <div className="row no-gutters mb-md-2 mb-lg-2 mb-3">
            <div className="order-md-2 order-lg-2 order-xl-1 col-lg-12 col-xl-5">
              <h5 className="head">
                {(_get(patientDetails, 'patient.firstName') || '-') +
                  ' ' +
                  (_get(patientDetails, 'patient.lastName') || '-')}
              </h5>
            </div>
            <div className="order-md-1 order-lg-1 order-xl-2 col-lg-12 col-xl-7 mb-md-3 mb-lg-3 text-right">
              <Authorize>
                <button
                  type="button"
                  className="btn m-1 px-3 btn-outline-primary text-uppercase"
                  onClick={reassignAppointment}
                  disabled={
                    'patientAppointment' in patientDetails &&
                    new Date(patientDetails.patientAppointment.scheduledStartTime).getTime() >
                      new Date().getTime() &&
                    (_get(patientDetails, 'patientAppointment.appointmentStatus') === 'Scheduled' ||
                      _get(patientDetails, 'patientAppointment.appointmentStatus') ===
                        'Coach-Review')
                      ? false
                      : true
                  }
                >
                  {t('REASSIGN')}{' '}
                  <span className="d-none d-lg-none d-xl-inline-block">{t('CONSULTATION')}</span>
                </button>
              </Authorize>
              <button type="button" className="btn m-1 btn_teal py-2 px-4" onClick={alertHandler}>
                {checkStatus === 'Completed' || checkStatus === 'Scheduled' || checkStatus === null
                  ? t('START CONSULTATION')
                  : t('CONTINUE CONSULTATION')}
              </button>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 col-xl-3">
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t('Age')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(patientDetails, 'patient.age')
                      ? _get(patientDetails, 'patient.age') + ' years'
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t('Gender')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(patientDetails, 'patient.gender') || '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t('Weight')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(patientDetails, 'patient.weight')
                      ? _get(patientDetails, 'patient.weight') <= 0
                        ? '0'
                        : _get(patientDetails, 'patient.weight') + ' lb'
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t('Height')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {' '}
                    {patientDetails && _get(patientDetails, 'patient.height')
                      ? Math.floor(_get(patientDetails, 'patient.height') / 30.48) +
                        "'" +
                        ' ' +
                        (Math.floor(_get(patientDetails, 'patient.height') / 2.54) -
                          12 * Math.floor(_get(patientDetails, 'patient.height') / 30.48)) +
                        '"'
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t('BMI')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {_get(patientDetails, 'patient.bmi') || '-'}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-9">
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t('Email')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {' '}
                    {_get(patientDetails, 'patient.email') || '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t('Contact')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {' '}
                    {_get(patientDetails, 'patient.phone') || '-'}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <label className="col-form-label subhead p-0 nowrap">{t('Location')}: </label>
                <div className="pl-1">
                  <span className="form-control-plaintext p-0">
                    {' '}
                    {_get(patientDetails, 'patient.location') || '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row no-gutters">
            <div className="col-12 col-xl-4 mb-4">
              <h5 className="heading">{t('Consultation Time')}</h5>
              <div>{_get(patientDetails, 'patientAppointment.rangestring') || '-'}</div>
            </div>
            <div className="col-12 col-xl-4 mb-4">
              <h5 className="heading">{t('Method of Consultation')}</h5>
              <div>{_get(patientDetails, 'patientAppointment.appointmentChannelType') || '-'}</div>
            </div>
            <div className="col-12 col-xl-4 mb-4">
              <h5 className="heading">{t('Type of Consultation')}</h5>
              <div>{_get(patientDetails, 'patientAppointment.appointmentType') || '-'}</div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 mb-4">
              <h5 className="heading">{t('Reason for Consultation')}</h5>
              <div>{_get(patientDetails, 'visitReason') || '-'}</div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12">
              <h5 className="heading">{t('Symptoms')}</h5>
              <div>
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th scope="col">{t('Symptom')}</th>
                      <th scope="col">{t('Severity')} </th>
                      <th scope="col">{t('Duration')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(patientDetails).length > 0 &&
                    patientDetails.symptoms &&
                    patientDetails.symptoms.length > 0 ? (
                      patientDetails.symptoms.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.severity}</td>
                          <td>{item.duration}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>{'-'}</td>
                        <td>{'-'}</td>
                        <td>{'-'}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr />
          <div className="row no-gutters">
            <div className="col-12 mb-4 home_EKGreading">
              <h5 className="heading">EKG Reading</h5>
              {recording && recording.previewData && (
                <Button
                  variant="contained"
                  color="default"
                  style={cssbutton}
                  startIcon={
                    <SvgIcon
                      style={{
                        margin: '5px',
                        width: '25px',
                        height: '25px',
                      }}
                      // color="#ffff"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#d0021b"
                        d="M17,0c-1.9,0-3.7,0.8-5,2.1C10.7,0.8,8.9,0,7,0C3.1,0,0,3.1,0,7c0,6.4,10.9,15.4,11.4,15.8 c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2C13.1,22.4,24,13.4,24,7C24,3.1,20.9,0,17,0z"
                      />
                    </SvgIcon>
                  }
                >
                  <Grid container direction="column">
                    <Grid item className={classes.bpm}>
                      {_get(recording, 'bpm') ? _get(recording, 'bpm') : '-'}
                    </Grid>
                    <Grid item className={classes.bpmspan}>
                      bpm
                    </Grid>
                  </Grid>
                </Button>
              )}
              <div
                className="my-3"
                onClick={recording && recording.previewData ? openHandler : null}
              >
                {recording && recording.previewData ? (
                  <div>
                    <EKGPreview width={650} height={80} previewData={recording.previewData} />
                  </div>
                ) : (
                  <div className="noekgtext">{t('EKG recording not found')}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 col-xl-6 mb-4">
              <h5 className="heading">{t('EKG Tags')}</h5>
              <div>
                {' '}
                {recording && recording.tags && recording.tags.length > 0
                  ? recording.tags.map((item, index) => (
                      <ul className="list-unstyled mb-0" key={index}>
                        <li className="my-2">{item}</li>
                      </ul>
                    ))
                  : '-'}
              </div>
            </div>
            <div className="col-12 col-xl-6 mb-0 mb-xl-4">
              <h5 className="heading">{t('EKG Details')}</h5>
              <div>
                <ul className="list-unstyled mb-0">
                  <li className="my-2">
                    Heart Rate:
                    <span> {_get(recording, 'bpm') ? _get(recording, 'bpm') + ' BPM' : '-'} </span>
                  </li>
                  <li className="my-2">
                    {t('Recorded Date')}:{' '}
                    <span>
                      {' '}
                      {_get(recording, 'recordedAt')
                        ? moment(recording.recordedAt).format('MM-DD-yyyy')
                        : '-'}
                    </span>
                  </li>
                  <li className="my-2">
                    {t('Recorded Time')}:{' '}
                    <span>
                      {_get(recording, 'recordedAt')
                        ? moment(recording.recordedAt).format('LT')
                        : '-'}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="row no-gutters">
            <div className="col-12 col-xl-6 pr-1 mb-4">
              <h5 className="heading">{t('Medical History')}</h5>
              <div>{_get(patientDetails, 'medicalHistory') || '-'}</div>
            </div>
            <div className="col-12 col-xl-6 mb-4">
              <h5 className="heading">{t('Family History')}</h5>
              <div>
                <ul className="list-unstyled mb-0">
                  <li className="my-2">{_get(patientDetails, 'familyMedicalHistory') || '-'}</li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-6 mb-4 pr-1">
              <h5 className="heading">{t('Social History')}</h5>
              <div>
                <ul className="list-unstyled mb-0">
                  {Object.keys(patientDetails).length > 0 && patientDetails.lifeStyleHistoryStr ? (
                    patientDetails.lifeStyleHistoryStr
                  ) : (
                    // patientDetails.lifeStyleHistory &&
                    // patientDetails.lifeStyleHistory.length > 0 ? (
                    //   patientDetails.lifeStyleHistory.map((item, index) => (
                    //     <li className="my-2" key={index}>
                    //       {item.name}
                    //     </li>
                    //   ))
                    <li className="my-2"> - </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-6 mb-4">
              <h5 className="heading">{t('Notes from patient')}</h5>
              <div>
                <ul className="list-unstyled mb-0">
                  <li className="my-2">{_get(patientDetails, 'patientNotes') || '-'}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row no-gutters pr-1">
            <div className="col-12 col-xl-6 mb-4">
              <h5 className="heading">{t('Allergies')}</h5>
              <div>{_get(patientDetails, 'allergyDetails') || '-'}</div>
            </div>
            <div className="col-12 col-xl-6 mb-4">
              <h5 className="heading">{t('Surgeries')}</h5>
              <div>{_get(patientDetails, 'surgeryDetails') || '-'}</div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12">
              <h5 className="heading">{t('Medications')}</h5>
              <div>
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th scope="col">{t('Medication Name')}</th>
                      <th scope="col">{t('Dose')} </th>
                      <th scope="col">{t('Unit')}</th>
                      <th scope="col">{t('Frequency')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(patientDetails).length > 0 &&
                    patientDetails.medicationsArray &&
                    patientDetails.medicationsArray.length > 0 ? (
                      patientDetails.medicationsArray.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.dose}</td>
                          <td>{item.unit}</td>
                          <td>{item.frequency}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>{'-'}</td>
                        <td>{'-'}</td>
                        <td>{'-'}</td>
                        <td>{'-'}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row no-gutters noappt_content">
          <div className="col-12 col-md-8 col-lg-8 col-xl-6 mx-auto text-center">
            <div className="hour_glass bg_green w176 mx-auto">
              <HourglassEmptySharpIcon />
            </div>
            <h5 className="heading my-3">{t('No Consultations')}</h5>
            <p>{t('You do not have any consultations scheduled for today')}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default withStartConsultation(DashBoardMiddleSection);
