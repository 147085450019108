/* eslint-disable */
import React from 'react';
import { Dialog, Grid, Button, SvgIcon, Box } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiTypography from '../../Shared/Typography/MuiTypography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const buttonstyle = {
  color: '#FFFFFF',
  backgroundColor: '#142A39',
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: 'none ',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: 'column',
  },
}))(MuiDialogActions);

export default function PatientLeftCallPopup(props) {
  const { t } = useTranslation();
  return (
    <Dialog
      // fullWidth={true}
      // maxWidth={"xs"}
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth={false}
    >
      <div style={{ width: 343 }}>
        <DialogContent>
          <Grid container justify="center" alignItems="center">
            {props.patientleftcallreason === 'clientDisconnected' ? (
              <SvgIcon viewBox="0 0 128 128" style={{ fontSize: 128 }}>
                <path
                  d="M89.6 32H38.4C34.88 32 32 34.88 32 38.4V96L44.8 83.2H89.6C93.12 83.2 96 80.32 96 76.8V38.4C96 34.88 93.12 32 89.6 32ZM63.8098 76.6798L61.1287 74.2391C56.1057 69.6843 51.9831 65.9423 49.2085 62.3999H52.8C53.3273 62.3999 53.8208 62.1401 54.1192 61.7053L55.4611 59.7499L57.8773 63.3001C58.1936 63.7649 58.7307 64.0296 59.292 63.9973C59.8533 63.9649 60.3564 63.6404 60.6173 63.1423L64.5787 55.5797L67.7874 67.6122C67.9677 68.2885 68.5668 68.7691 69.2661 68.7985C69.9655 68.8279 70.6027 68.3993 70.8392 67.7405L73.6589 59.8856H80.1635C77.5559 64.1999 72.7145 68.5956 66.4909 74.2391L63.8098 76.6798ZM81.6741 56.6856H72.5333C71.8581 56.6856 71.2556 57.1095 71.0274 57.745L69.5589 61.836L66.6126 50.7876C66.4427 50.1504 65.8992 49.6827 65.2438 49.6097C64.5884 49.5367 63.9553 49.8733 63.6493 50.4574L59.0211 59.2932L56.7894 56.014C56.4921 55.5771 55.9982 55.3152 55.4698 55.3142C54.9413 55.3132 54.4465 55.5731 54.1474 56.0089L51.9575 59.1999H47.0604C45.9306 57.1443 45.3194 55.0931 45.3194 52.9197C45.3194 47.2062 49.7941 42.75 55.4892 42.75C58.7065 42.75 61.7944 44.2477 63.8098 46.596C65.8253 44.2477 68.9132 42.75 72.1305 42.75C77.8255 42.75 82.3002 47.2062 82.3002 52.9197C82.3002 54.2092 82.0851 55.4557 81.6741 56.6856Z"
                  fill="#142A39"
                />
              </SvgIcon>
            ) : (
              <SvgIcon viewBox="0 0 64 64" style={{ fontSize: 64 }}>
                <path
                  d="M28.8 22.4H35.2V16H28.8V22.4ZM32 57.6C17.888 57.6 6.4 46.112 6.4 32C6.4 17.888 17.888 6.4 32 6.4C46.112 6.4 57.6 17.888 57.6 32C57.6 46.112 46.112 57.6 32 57.6ZM32 0C27.7977 0 23.6365 0.827705 19.7541 2.43586C15.8717 4.04401 12.3441 6.40111 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C12.3441 57.5989 15.8717 59.956 19.7541 61.5641C23.6365 63.1723 27.7977 64 32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 27.7977 63.1723 23.6365 61.5641 19.7541C59.956 15.8717 57.5989 12.3441 54.6274 9.37258C51.6559 6.40111 48.1283 4.04401 44.2459 2.43586C40.3635 0.827705 36.2023 0 32 0ZM28.8 48H35.2V28.8H28.8V48Z"
                  fill="#142A39"
                />
              </SvgIcon>
            )}
          </Grid>
          <Grid container justify="center" alignItems="center">
            {props.patientleftcallreason === 'clientDisconnected' && (
              <>
                <MuiTypography
                  fontSize="20px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t('The patient has ended the')}
                </MuiTypography>
                <MuiTypography
                  fontSize="20px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t('consultation')}.
                </MuiTypography>
              </>
            )}

            {props.patientleftcallreason === 'networkDisconnected' && (
              <>
                <MuiTypography
                  fontSize="20px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t('Patient Technical Issues')}
                </MuiTypography>
                <Box mt={3} />
                <MuiTypography
                  fontSize="15px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={400}
                  color="#142A39"
                >
                  {t('It looks like your patient may be having')}.
                </MuiTypography>
                <MuiTypography
                  fontSize="15px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={400}
                  color="#142A39"
                >
                  {t('technical issues.')}.
                </MuiTypography>
              </>
            )}

            {props.patientleftcallreason === 'doctor technical issue' && (
              <>
                <MuiTypography
                  fontSize="20px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t('Technical Issues')}
                </MuiTypography>
                <Box mt={3} />
                <MuiTypography
                  fontSize="15px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={400}
                  color="#142A39"
                >
                  {t('It looks like you may be having')}.
                </MuiTypography>
                <MuiTypography
                  fontSize="15px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={400}
                  color="#142A39"
                >
                  {t('technical issues.')}.
                </MuiTypography>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions disableSpacing={true}>
          <>
            {props.patientleftcallreason === 'doctor technical issue' ? (
              <>
                {/* <Button
                size="large"
                fullWidth
                variant="contained"
                onClick={props.closePatientLeftCallPopupProviderside}
                style={buttonstyle}
              >
                {t("Retry")}
              </Button>
              <Box mt={2}/> */}
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  onClick={props.closePatientLeftCallPopupProvidersidedismiss}
                  style={buttonstyle}
                >
                  {t('Okay')}
                </Button>
              </>
            ) : (
              <Button
                size="large"
                fullWidth
                variant="contained"
                onClick={props.closePatientLeftCallPopup}
                style={buttonstyle}
              >
                {t('Okay')}
              </Button>
            )}
          </>
        </DialogActions>
      </div>
    </Dialog>
  );
}
