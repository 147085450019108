import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  memberListData: [],
  searchMemberData: [],
  memberListFilter: null,
};
const profileInitialState = {
  laoding: false,
  data: {},
  error: null,
};
export function memberListReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CHAT_USERS_START:
      return {
        ...state,
        loading: true,
        memberListData: [],
      };
    case actionTypes.GET_MEMBERS_LIST:
      return {
        ...state,
        loading: false,
        memberListData: action.payload.data,
        count: action.payload.count,
      };
    case actionTypes.GET_MEMBERS_LIST_NEXT_SESSION_DATA: {
      return {
        ...state,
        memberListData: action.payload.data,
      };
    }
    case actionTypes.GET_SEARCH_MEMBER:
      return {
        ...state,
        loading: false,
        searchMemberData: action.payload,
      };
    case actionTypes.SET_FILTER_DATA:
      return {
        ...state,
        memberListFilter: action.payload,
      };
    default:
      return state;
  }
}
export function memberProfileReducer(state = profileInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_MEMBER_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_MEMBER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actionTypes.GET_MEMBER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
