import { connect } from 'react-redux';
import AutoCompleteDropdown from './AutoCompleteDropdown';
import {getSearchedMember} from '../../Store/actions'

const mapActionCreators = {
  getSearchedMember
};

const mapStateToProps = (state) => ({
  memberSearchList: state.memberList.searchMemberData
});

export default connect(mapStateToProps, mapActionCreators)(AutoCompleteDropdown);
