import * as actionTypes from '../actions/actionTypes';

const sessionInitialData = {
  patientsData: {},
  previousSessionData: {},
  heartHabit: [],
  programFocus: [],
};

export default function sessionReducer(state = sessionInitialData, action) {
  switch (action.type) {
    case actionTypes.GET_SESSION_PATIENT_DETAILS:
      return {
        ...state,
        patientsData: action.payload,
      };
    case actionTypes.GET_PREVIOUS_SESSION_DETAILS:
      return {
        ...state,
        previousSessionData: action.payload,
      };
    case actionTypes.GET_HEART_HABIT:
      return {
        ...state,
        heartHabit: action.payload,
      };
    case actionTypes.ADD_HEART_HABIT: {
      const { prevData, data } = action.payload;
      let hh = [...data];
      return {
        ...state,
        heartHabit: hh,
      };
    }
    case actionTypes.DELETE_HEART_HABIT: {
      const { prevData, id } = action.payload;
      let hh = [...prevData];
      let idx = hh.findIndex((s) => s.id === id);
      hh.splice(idx, 1);
      return {
        ...state,
        heartHabit: hh,
      };
    }
    case actionTypes.EDIT_HEART_HABIT_LOADING: {
      const { loading, prevData, id } = action.payload;
      let hh = [...prevData];
      let idx = hh.findIndex((s) => s.id === id);
      hh[idx].loading = loading;
      return {
        ...state,
        heartHabit: hh,
      };
    }
    case actionTypes.EDIT_HEART_HABIT: {
      const { prevData, data, id, loading } = action.payload;
      let hh = [...prevData];
      let idx = hh.findIndex((s) => s.id === id);
      hh[idx] = data;
      hh[idx].loading = loading;
      return {
        ...state,
        heartHabit: hh,
      };
    }
    case actionTypes.EDIT_HEART_HABIT_FAIL: {
      const { prevData, id, loading, error } = action.payload;
      let hh = [...prevData];
      let idx = hh.findIndex((s) => s.id === id);
      hh[idx].loading = loading;
      hh[idx].error = error;
      return {
        ...state,
        heartHabit: hh,
      };
    }

    case actionTypes.GET_PROGRAM_FOCUS:
      return {
        ...state,
        programFocus: action.payload,
      };
    case actionTypes.EDIT_PROGRAM_FOCUS: {
      const { prevData, data, id } = action.payload;
      let pf = [...prevData];
      let idx = pf.findIndex((s) => s.id === id);
      pf[idx] = data;
      return {
        ...state,
        programFocus: pf,
      };
    }
    default:
      return state;
  }
}
