/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import '!style-loader!css-loader!./thirdPartyCssOverrides.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './Store/reducers';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import * as actions from './Store/actions';
import AppAdmin from './AppAdmin';
import './i18n';

import AppCoach from './AppCoach/index';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  checkAuthentication,
  clearIntervalAuthenticationChecker,
  idleTimeUpdater,
  intervalAuthenticationChecker,
} from './Auth/AuthUtilities';
import history from './Components/History';

const composeEnhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(idleTimeUpdater, reduxThunk)),
);

if (store.dispatch(checkAuthentication())) {
  store.dispatch(intervalAuthenticationChecker());
}

const handleBeforeUnloadEvent = () => clearIntervalAuthenticationChecker();
window.addEventListener('beforeunload', handleBeforeUnloadEvent);

window.renderTelekardia = (containerId, history, state_, app) => {
  if (app === 'telekardia') {
    ReactDOM.render(
      <div className="">
        <Provider store={store}>
          <App history={history} mode="MICROFRONTEND" userstate={state_} />
        </Provider>
      </div>,
      document.getElementById(containerId),
    );
  } else if (app === 'admin') {
    ReactDOM.render(
      <div className="">
        <Provider store={store}>
          <AppAdmin history={history} mode="MICROFRONTEND" userstate={state_} />
        </Provider>
      </div>,
      document.getElementById(containerId),
    );
  }
  serviceWorker.unregister();
};

window.unmountTelekardia = (containerId) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};
if (module.hot) {
  module.hot.accept();
}
if (!document.getElementById('Telekardia-container')) {
  ReactDOM.render(
    <div className="">
      <Provider store={store}>
        <AppCoach history={history} userstate={{ data: { profile: null, teams: null } }} />
      </Provider>
    </div>,
    document.getElementById('root'),
  );
  serviceWorker.unregister();
}
