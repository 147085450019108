import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './ContentHeader.module.css';
import { ROUTES } from '../../constants/app';
import PatientIcon from '../../Assets/images/icons/add-plus.svg';
import BackIcon from '../../Assets/images/icons/chevron-left.svg';
import PageUpdated from '../PageUpdated/PageUpdated';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { getAge, getTimezoneName } from '../../Utilities/Utilities';
import { Button, makeStyles } from '@material-ui/core';
import ToastButton from './ToastButton';
import * as actions from '../../Store/actions';
import { Chat } from '../../providers';
import moment from 'moment';
import ProviderStatus from '../Shared/ProviderStatus';
const useStyle = makeStyles({
  root: {
    '& .MuiAlert-message': {
      padding: '0 !important',
    },
  },
});
function Alert(props) {
  const classes = useStyle();
  return (
    <MuiAlert
      style={{
        backgroundColor: '#885FA5',
        fontFamily: 'Work Sans',
        fontWeight: 'bold',
        fontSize: '14px',
        width: 'auto',
        visibility: props.visibility,
      }}
      className={classes.root}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

const ContentHeader = (props) => {

  //context to consume push notification on upcoming session and if member has join the waiting room or not.
  const { currentAppointmentUser: caUser } = useContext(Chat.Context); 

  const {
    showAddPatient = true,
    showCTA,
    ctaComponent: CTAComponent,
    text,
    getText,
    hasTabs,
    showLastRefresh = true,
    isLoading,
    lastUpdated,
    handleOnRefreshClick,
    enableBackButton = false,
    className,
    isFullWidth = false,
    me,
    goBackRoute = '',
    appointments,
  } = props;

  const [minLeft, setminLeft] = useState('');
  const [isSessionAlert, setisSessionAlert] = useState(false);
  const [memberWaiting, setmemberWaiting] = useState(false);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [showAlert, setShowAlert] = useState(false);

  //Things needed for opening appointment on click

  const dispatch = useDispatch();

  const [appointmentId_, setappointmentId_] = React.useState('');
  const [patientDetails, setPatientDetails] = React.useState({});
  const [recording, setRecording] = React.useState([]);
  const patientDetailsntwrk = useSelector((state) => state.appointments.patientDetails);
  const recordingntwrk = useSelector((state) => state.ekg.recording);
  const error = useSelector((state) => state.appointments.error);
  const patientDetailsLoader = useSelector((state) => state.appointments.detailsloader);
  const profileId = useSelector((state) => state.user?.profile.id);

  function compare(a, b) {
    if (a.start < b.start) {
      return -1;
    }
    if (a.start > b.start) {
      return 1;
    }
    return 0;
  }
  const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';

  /**
   * Adding this as a fallback if events are not fetched to show
   */
  useEffect(() => {
    if (appointments.length == 0) {
      let queryParams = {
        startDate: moment(moment().startOf('day')._d).format(pattern),
        endDate: moment(moment().endOf('day')._d).format(pattern),
        offsetInMinutes: new Date().getTimezoneOffset(),
        timeZone: getTimezoneName(),
        splitBasedOnDays: true,
      };
      dispatch(actions.getAvailabilities(profileId, teamId, queryParams));
    }
  }, []);
  //api call
  useEffect(() => {
    let app = null;
    app = appointments.filter((t) => t.status === 12 && t.eventType === 'appointment');

    if (app.length > 0) {
      setappointmentId_(app[0].id);
      setmemberWaiting(true);
      setShowAlert(true);
      dispatch(
        actions.getRecordingSamples(app.ekgId, teamId, profileId, app[0].id, {
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        }),
      );
    }
  }, [appointments]);
  
  //============================//
  //===Setting visibility of toast baner false when coach joined the session===//
  const [visibility, setvisibility] = useState('visible');
  const hideToast = () => {
    setvisibility('hidden');
    console.log('visibility - ', visibility);
  };
  //============================//

  //bosh call
  useEffect(() => {
    if (caUser) {
      if (caUser.currentMode === 'MemberEnteredWaitingRoom') {
        setmemberWaiting(true);
        setisSessionAlert(false);
      } else if(caUser.currentMode === 'UpcomingNotification'){
        setmemberWaiting(false);
        setisSessionAlert(true);
      } else if(caUser.currentMode === 'MemberNotEnteredWaitingRoom'){
        setvisibility('hidden')    // Setting visibility of toast baner false when member did not join the waiting room
        setmemberWaiting(false);
        setisSessionAlert(false);
      }

      setappointmentId_(caUser.extraData?.appointmentId);
      setShowAlert(true);
    }
  }, [caUser]);
  //to populate appointment.patientDetails
  useEffect(() => {
    if (appointmentId_) {
      let app = appointments.filter((t) => t.id === appointmentId_);
      dispatch(
        actions.getRecordingSamples(app.ekgId, teamId, profileId, appointmentId_, {
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        }),
      );
    }
  }, [appointmentId_, appointments]);

  useEffect(() => {
    if (patientDetailsntwrk.patient) {
      patientDetailsntwrk.patient['age'] = getAge(patientDetailsntwrk.patient.dob);
      setPatientDetails(patientDetailsntwrk);
      setRecording(recordingntwrk);
    }
  }, [patientDetailsntwrk, recordingntwrk]);

  const latestAppointmentWatcher = useRef(null);
  //session is in 'x' mins
  useEffect(() => {
    if (appointments.length > 0 && isSessionAlert && appointmentId_) {
      const filteredApp = appointments.filter((item) => item.id === appointmentId_);
      // const appId = appointments.filter((item) => item.eventType === 'appointment').sort(compare);
      console.log('SORTED APP', filteredApp);
      setisSessionAlert(true);
      setShowAlert(true);
      //The wrapper watcher
      if (filteredApp.length > 0) {
        latestAppointmentWatcher.current = setInterval(() => {
          let item = filteredApp[0];
          let start = moment(item.start);
          let diffMins = moment(start).diff(new Date(), 'minute');
          // console.log('DIFF MINS', diffMins);
          if (diffMins < 300 && diffMins >= 0) {
            setminLeft(diffMins);
          }
        }, 10000);
      }
    }
    return () => clearInterval(latestAppointmentWatcher.current);
  }, [appointments, isSessionAlert, appointmentId_]);

  const headerClassNames = classNames({
    [styles.header]: !hasTabs,
    [styles.headerWithTabs]: hasTabs,
    [className]: className !== undefined,
  });
  const headerTextcn = enableBackButton ? styles.headerTextWithBack : styles.headerText;
  const widthController = isFullWidth
    ? styles.contentHeaderFullWidth
    : styles.contentHeaderLeftGroup;


  return (
    <header className={headerClassNames} style={{ marginBottom: '20px' }}>
      <div className={widthController}>
        {enableBackButton && (
          <Link to={goBackRoute} className={styles.back} data-action="content-header-back">
            <BackIcon />
          </Link>
        )}
        {text && !getText && (
          <h1 style={{ fontSize: '34px', fontFamily: 'Work Sans' }} className={headerTextcn}>
            {text}
            <span className={styles.headerLine} />
          </h1>
        )}
        {!text && getText && getText()}
      </div>
      <div className={styles.contentHeaderRightGroup}>
        {showCTA && CTAComponent}
        {showAddPatient && (
          <div data-action="add-patient">
            <Link to={ROUTES.PATIENTS_ADD_NEW_PATIENT} className={styles.addPatientBtn}>
              <PatientIcon />
              Add Patient
            </Link>
          </div>
        )}
        {showLastRefresh && (
          <PageUpdated
            isLoading={isLoading}
            lastUpdated={lastUpdated}
            handleOnClick={handleOnRefreshClick}
            hasTabs={hasTabs}
            me={me}
          />
        )}
        {/* {!showLastRefresh &&
        <div>
        <ProviderStatus {...props} teamId={teamId} profileId={profileId} />
      </div>} */}
        {showAlert && (
          <Alert severity="info" visibility={visibility}>
            <span>
              {isSessionAlert
                ? minLeft >= 1
                  ? `SESSION STARTS IN ${minLeft} ${minLeft > 1 ? 'MINUTES' : 'MINUTE'}`
                  : 'SESSION WILL START SOON'
                : memberWaiting
                ? 'MEMBER IN WAITING ROOM'
                : 'SESSION IN PROGRESS'}
            </span>
            <ToastButton
              {...{
                id: appointmentId_,
                patientDetails: patientDetails,
                recording: recording,
                appointments: appointments,
              }}
              patientDetailsLoader={patientDetailsLoader}
              reviewSession={isSessionAlert}
              memberInWaitingRoom={memberWaiting}
              cancelMemberWaiting={() => setmemberWaiting(false)}
              closeAlert={() => setShowAlert(false)}
              hideToast={hideToast}
            />
          </Alert>
        )}
      </div>
    </header>
  );
};

export default ContentHeader;
