import MemberProfile from './MemberProfile';
import { connect } from 'react-redux';
import { tableHead, sessionLogsViewNotes,sessionLogsTableData } from './components/SessionLog/data';

const mapActionCreators = {};

const mapStateToProps = (state) => ({
    tableHead: tableHead,
    sessionLogsViewNotes: sessionLogsViewNotes,
    sessionLogsTableData: sessionLogsTableData
});

export default connect(mapStateToProps, mapActionCreators)(MemberProfile);
